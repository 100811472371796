define("ember-svg-jar/inlined/message-campaign-drip-campaign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M807.01 284.538h-1.523V631.53c0 26.949-21.772 48.722-48.722 48.722h-588.47v1.523c0 26.949 21.772 48.722 48.722 48.722h590.144c26.949 0 48.722-21.772 48.722-48.722V333.26c-.152-26.949-21.925-48.722-48.875-48.722zm91.354 86.785h-1.523v346.992c0 26.949-21.772 48.722-48.722 48.722h-588.47v1.523c0 26.949 21.772 48.722 48.722 48.722h590.144c26.949 0 48.722-21.772 48.722-48.722V420.045c-.152-26.949-21.925-48.722-48.875-48.722zm-488.438-86.482c-72.627 2.589-132.919 63.338-130.027 140.38 2.589 71.865 62.12 131.093 137.791 129.875 74.453-1.523 135.05-63.338 132.463-140.228-2.589-73.235-64.101-132.767-140.228-130.027zm106.122 79.935c6.09 9.592 3.197 22.229-6.547 28.319l-94.551 59.532-92.419-58.771c-9.592-6.09-12.485-18.728-6.395-28.319s18.728-12.485 28.319-6.395l70.495 44.763 72.778-45.678c9.592-5.938 22.381-3.046 28.319 6.547zm162.153-82.675l-56.03 39.587c-3.654 2.589-7.765 3.806-11.876 3.806-6.395 0-12.79-3.046-16.748-8.679-6.547-9.287-4.415-22.077 4.872-28.625l55.878-39.587 66.992-48.264H113.788c-3.654 0-7.309.608-10.658 1.523l64.861 46.743 55.725 39.435c9.287 6.547 11.419 19.337 4.872 28.625-3.959 5.633-10.354 8.679-16.748 8.679-4.112 0-8.221-1.218-11.876-3.806l-55.878-39.587-66.536-47.809c0 .76-.152 1.523-.152 2.283v369.221c0 19.946 16.139 36.236 36.236 36.236h613.439c19.946 0 36.236-16.139 36.236-36.236V236.577c0-4.872-.914-9.592-2.741-13.856L678.197 282.1zM414.189 588.898c-93.028-.305-168.7-76.128-168.09-170.07.608-92.571 75.823-167.787 169.31-167.787 93.028 0 168.547 75.823 168.547 169.004 0 93.486-75.671 169.004-169.765 168.852z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 1000 1024"
    }
  };
  _exports.default = _default;
});