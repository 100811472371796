define("ember-svg-jar/inlined/ecommerce-tab-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M21.58.21H2.42A2.39 2.39 0 000 2.61V17a2.39 2.39 0 002.4 2.4h19.18A2.39 2.39 0 0024 17V2.61a2.39 2.39 0 00-2.42-2.4zM13.34 16v1a.91.91 0 01-.9.9h-1a.82.82 0 01-.82-.82V16a4 4 0 01-3.06-2.27.87.87 0 01.82-1.18h.24a.89.89 0 01.82.61c.3.76 1.07 1.29 2.55 1.29 2 0 2.44-1 2.44-1.62 0-.84-.45-1.63-2.71-2.17C9.21 10.05 7.48 9 7.48 6.93c0-1.74 1.41-2.88 3.16-3.26v-1a.91.91 0 01.91-.91h.95a.85.85 0 01.85.84v1.1A3.6 3.6 0 0116 6a.86.86 0 01-.82 1.15h-.27a.83.83 0 01-.78-.63c-.23-.77-.87-1.27-2.15-1.27-1.52 0-2.44.69-2.44 1.66s.66 1.41 2.71 1.94 4.25 1.41 4.25 4c0 1.82-1.39 2.83-3.16 3.15z\" fill=\"#5ebade\"/>",
    "attrs": {
      "aria-hidden": "true",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 19.59"
    }
  };
  _exports.default = _default;
});