define("boulevard/components/boulevard/form-material/title", ["exports", "@ember-decorators/component", "@ember/component"], function (_exports, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  /* eslint-disable ember/no-classic-components, ember/require-tagless-components */
  let Title = (_dec = (0, _component.tagName)('h1'), _dec2 = (0, _component.classNames)('form-material__title'), _dec(_class = _dec2(_class = class Title extends _component2.default {}) || _class) || _class);
  _exports.default = Title;
});