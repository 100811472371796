define("ember-svg-jar/inlined/promotion-pickem-knockout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M261.714 513.714q-42.286-92.571-42.286-212H73.142v54.857q0 44.571 54 92.571t134.571 64.571zm616-157.142v-54.857H731.428q0 119.429-42.286 212 80.571-16.571 134.571-64.571t54-92.571zm73.143-73.143v73.143q0 40.571-23.714 81.714t-64 74.286-98.857 55.714-123.143 25.429q-24 30.857-54.286 54.286-21.714 19.429-30 41.429t-8.286 51.143q0 30.857 17.429 52t55.714 21.143q42.857 0 76.286 26t33.429 65.429v36.571q0 8-5.143 13.143t-13.143 5.143H237.714q-8 0-13.143-5.143t-5.143-13.143v-36.571q0-39.429 33.429-65.429t76.286-26q38.286 0 55.714-21.143t17.429-52q0-29.143-8.286-51.143t-30-41.429q-30.286-23.429-54.286-54.286-64.571-2.857-123.143-25.429t-98.857-55.714-64-74.286T0 356.572v-73.143q0-22.857 16-38.857t38.857-16h164.571v-54.857q0-37.714 26.857-64.571t64.571-26.857h329.143q37.714 0 64.571 26.857t26.857 64.571v54.857h164.571q22.857 0 38.857 16t16 38.857z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 951 1024"
    }
  };
  _exports.default = _default;
});