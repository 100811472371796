define("boulevard/components/boulevard/form-material/message", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="form-material__message{{if @status (concat " form-material__message--" @status)}}">
    {{yield}}
  </div>
  
  */
  {
    "id": "1NfIgXkE",
    "block": "[[[10,0],[15,0,[29,[\"form-material__message\",[52,[30,1],[28,[37,1],[\" form-material__message--\",[30,1]],null]]]]],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@status\",\"&default\"],false,[\"if\",\"concat\",\"yield\"]]",
    "moduleName": "boulevard/components/boulevard/form-material/message.hbs",
    "isStrictMode": false
  });
  /* eslint-disable ember/no-classic-components */
  let Message = (_dec = (0, _component2.tagName)(''), _dec(_class = class Message extends _component.default {}) || _class);
  _exports.default = Message;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Message);
});