define("boulevard/components/boulevard/sortable-column", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-invalid-interactive }}
  <div
    ...attributes
    role={{if @sortBy "button"}}
    class={{concat
      "blvd-sortable-column"
      (if this.isActive " blvd-sortable-column--active")
      (if @sortBy " blvd-sortable-column--actionable")
    }}
    {{on "click" this._sortBy}}
  >
    {{! template-lint-enable no-invalid-interactive }}
    <span class="blvd-sortable-column__name">{{@columnName}}</span>
    {{#if this.isActive}}
      <i class="blvd-sortable-column__icon material-icons">{{if (eq @sortDirection "asc") "arrow_upward" "arrow_downward"}}</i>
    {{/if}}
  </div>
  
  */
  {
    "id": "iXPJBXsP",
    "block": "[[[11,0],[17,1],[16,\"role\",[52,[30,2],\"button\"]],[16,0,[28,[37,1],[\"blvd-sortable-column\",[52,[30,0,[\"isActive\"]],\" blvd-sortable-column--active\"],[52,[30,2],\" blvd-sortable-column--actionable\"]],null]],[4,[38,2],[\"click\",[30,0,[\"_sortBy\"]]],null],[12],[1,\"\\n\"],[1,\"  \"],[10,1],[14,0,\"blvd-sortable-column__name\"],[12],[1,[30,3]],[13],[1,\"\\n\"],[41,[30,0,[\"isActive\"]],[[[1,\"    \"],[10,\"i\"],[14,0,\"blvd-sortable-column__icon material-icons\"],[12],[1,[52,[28,[37,3],[[30,4],\"asc\"],null],\"arrow_upward\",\"arrow_downward\"]],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"@sortBy\",\"@columnName\",\"@sortDirection\"],false,[\"if\",\"concat\",\"on\",\"eq\"]]",
    "moduleName": "boulevard/components/boulevard/sortable-column.hbs",
    "isStrictMode": false
  });
  /* eslint-disable ember/no-classic-components, ember/no-computed-properties-in-native-classes */
  let SortableColumn = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _object.computed)('columnProperty', 'sortProperty'), _dec(_class = (_class2 = class SortableColumn extends _component.default {
    constructor() {
      super(...arguments);
      //region Arguments
      _defineProperty(this, "columnName", '');
      _defineProperty(this, "columnProperty", '');
      _defineProperty(this, "sortProperty", '');
      _defineProperty(this, "sortDirection", '');
    }
    sortBy() {}
    //endregion

    get isActive() {
      return this.columnProperty === this.sortProperty;
    }
    _sortBy() {
      if (this.sortBy) {
        this.sortBy(this.columnProperty);
      }
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "isActive", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "isActive"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_sortBy", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_sortBy"), _class2.prototype)), _class2)) || _class);
  _exports.default = SortableColumn;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SortableColumn);
});