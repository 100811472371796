define("ember-svg-jar/inlined/events-creation-swoop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M866.9 102.6V0c-32.1.5-63.3 1.4-93.3 2.6-160.9 6.5-316.4 22-477.6 27.7-97.4 3.5-198.8-1-296-11.9V400h866.9V102.6z\" fill=\"#e1eaf3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 867 400",
      "aria-hidden": "true"
    }
  };
  _exports.default = _default;
});