define("ember-svg-jar/inlined/Articles_Two_Column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.c2ad3ffe-b043-4bce-b83a-ff95efa84b14{stroke:#fff;stroke-miterlimit:10;stroke-width:.19px;opacity:.8}</style></defs><title>Two Column</title><rect class=\"c2ad3ffe-b043-4bce-b83a-ff95efa84b14\" x=\".09\" y=\".09\" width=\"56.09\" height=\"31.05\" rx=\"1.74\" ry=\"1.74\"/><rect class=\"9daf7847-6145-461e-ac9f-785095d0ad49\" x=\".09\" y=\"34.54\" width=\"56.09\" height=\"4.75\" rx=\"2.38\" ry=\"2.38\"/><rect class=\"325f2851-b14d-428e-92b4-26985c3bd9b0\" x=\".09\" y=\"42.35\" width=\"40.8\" height=\"4.75\" rx=\"2.38\" ry=\"2.38\"/><rect class=\"c2ad3ffe-b043-4bce-b83a-ff95efa84b14\" x=\"62.43\" y=\".09\" width=\"56.09\" height=\"31.05\" rx=\"1.74\" ry=\"1.74\"/><rect class=\"9daf7847-6145-461e-ac9f-785095d0ad49\" x=\"62.43\" y=\"34.54\" width=\"56.09\" height=\"4.75\" rx=\"2.38\" ry=\"2.38\"/><rect class=\"325f2851-b14d-428e-92b4-26985c3bd9b0\" x=\"62.43\" y=\"42.35\" width=\"40.8\" height=\"4.75\" rx=\"2.38\" ry=\"2.38\"/><rect class=\"c2ad3ffe-b043-4bce-b83a-ff95efa84b14\" x=\".09\" y=\"59.68\" width=\"56.09\" height=\"31.05\" rx=\"1.74\" ry=\"1.74\"/><rect class=\"9daf7847-6145-461e-ac9f-785095d0ad49\" x=\".09\" y=\"94.13\" width=\"56.09\" height=\"4.75\" rx=\"2.38\" ry=\"2.38\"/><rect class=\"325f2851-b14d-428e-92b4-26985c3bd9b0\" x=\".09\" y=\"101.94\" width=\"40.8\" height=\"4.75\" rx=\"2.38\" ry=\"2.38\"/><rect class=\"c2ad3ffe-b043-4bce-b83a-ff95efa84b14\" x=\"62.43\" y=\"59.68\" width=\"56.09\" height=\"31.05\" rx=\"1.74\" ry=\"1.74\"/><rect class=\"9daf7847-6145-461e-ac9f-785095d0ad49\" x=\"62.43\" y=\"94.13\" width=\"56.09\" height=\"4.75\" rx=\"2.38\" ry=\"2.38\"/><rect class=\"325f2851-b14d-428e-92b4-26985c3bd9b0\" x=\"62.43\" y=\"101.94\" width=\"40.8\" height=\"4.75\" rx=\"2.38\" ry=\"2.38\"/>",
    "attrs": {
      "id": "495df272-a56e-4f84-8504-5f329d395026",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 118.61 106.73"
    }
  };
  _exports.default = _default;
});