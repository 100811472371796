define("ember-svg-jar/inlined/promotion-ballot-voters-choice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M783.872 130.231h-547.84c-13.312 0-24.32 10.752-24.32 24.32v736.256c0 13.312 10.752 24.32 24.32 24.32h547.84c13.312 0 24.32-10.752 24.32-24.32V154.551c0-13.312-11.008-24.32-24.32-24.32zm-285.44 89.6c99.328 0 179.968 80.64 179.968 179.968s-80.64 179.968-179.968 179.968-179.968-80.64-179.968-179.968c0-99.584 80.64-179.968 179.968-179.968zM599.04 820.407H338.176c-15.872 0-28.928-13.056-28.928-28.928s13.056-28.928 28.928-28.928H599.04c15.872 0 28.928 13.056 28.928 28.928s-13.056 28.928-28.928 28.928zm110.848-140.544c0 15.872-13.056 28.928-28.928 28.928H338.176c-15.872 0-28.928-13.056-28.928-28.928 0-15.872 13.056-28.928 28.928-28.928H680.96c16.128 0 28.928 13.056 28.928 28.928zm-81.152-333.824l-25.856-26.112-144.896 142.08-62.464-69.12-27.392 24.832 88.064 97.536z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 1024 1024"
    }
  };
  _exports.default = _default;
});