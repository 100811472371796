define("ember-svg-jar/inlined/nominationAndVotingBallot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Nomination_and_Voting_Ballot2-01</title><path d=\"M9.088 24.64c1.024.256 2.048.576 3.072.832a.141.141 0 00.192-.192c-.256-1.024-.576-2.048-.832-3.072-.064-.128-.192-.192-.32-.064a75.01 75.01 0 00-2.24 2.304c-.064 0 0 .128.128.192zM8.32 23.68l2.304-2.304a.39.39 0 000-.512c-2.304-2.24-4.544-4.48-6.784-6.72l-.384-.384c-.448-.448-1.024-.384-1.408 0-.448.448-.896.896-1.344 1.408-.384.448-.384.96 0 1.408l.896.896c2.112 2.112 4.224 4.16 6.272 6.272.064.064.32.064.448-.064z\"/><path d=\"M16.832 3.392H4.8a.875.875 0 00-.896.896v7.616c2.24 2.24 4.48 4.416 6.72 6.656l1.664 2.176c.064.064.256.448.256.512.384 1.536.704 2.816 1.088 4.224.192.768-.512 1.536-1.28 1.344-1.408-.32-2.624-.576-4.096-.96-.064 0-.576-.32-.64-.384l-2.24-1.664L3.968 22.4v4.8c0 .512.384.896.896.896H16.96a.875.875 0 00.896-.896v-1.216h-.576a.875.875 0 01-.896-.896V6.72c0-.512.384-.896.896-.896h.576V4.288c-.128-.512-.512-.896-1.024-.896zm-3.008 9.024H6.848c-.576 0-1.024-.448-1.024-1.024s.448-1.024 1.024-1.024h6.976a1.01 1.01 0 011.024 1.024c0 .512-.448 1.024-1.024 1.024zm0-4.736H6.848c-.576 0-1.024-.448-1.024-1.024s.448-1.024 1.024-1.024h6.976c.576 0 1.024.448 1.024 1.024S14.4 7.68 13.824 7.68z\"/><path d=\"M30.976 7.68H18.56a.71.71 0 00-.704.704v15.04c0 .384.32.704.704.704h12.416a.71.71 0 00.704-.704V8.384a.71.71 0 00-.704-.704zm-10.048 4.416l2.304 2.56 5.376-5.248.96.96-6.4 6.272-3.264-3.584 1.024-.96zm7.808 9.664H20.8c-.576 0-1.024-.448-1.024-1.024s.448-1.024 1.024-1.024h7.872a1.01 1.01 0 011.024 1.024c.064.576-.448 1.024-.96 1.024z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});