define("ember-svg-jar/inlined/message-campaign-single-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M557.312 542.391c-13.312 11.264-29.696 16.64-46.08 16.64-16.128 0-32.256-5.376-45.568-16.384L145.408 279.479c-2.816 7.936-4.608 16.384-4.608 25.344v413.184c0 40.96 33.024 73.984 73.984 73.984h593.408c40.96 0 73.984-33.024 73.984-73.984V304.823c0-10.496-2.048-20.224-6.144-29.184l-318.72 266.752zm-53.504-46.08c4.352 3.584 10.752 3.584 14.848 0l312.832-261.632c-7.424-2.304-15.104-3.84-23.296-3.84H214.784c-9.728 0-18.944 2.048-27.648 5.376l316.672 260.096z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 1024 1024"
    }
  };
  _exports.default = _default;
});