define("ember-svg-jar/inlined/ecommerce-modal-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.cls-1{fill:#43a047}</style></defs><path class=\"cls-1\" d=\"M25 .57H7.62a2.16 2.16 0 00-2.17 2.17V8a1.28 1.28 0 01.9 1.22v.94h.88a1.3 1.3 0 011.3 1.29v1.65a1.3 1.3 0 01-1.3 1.3h-.88v.93a1.29 1.29 0 01-.78 1.19A2.15 2.15 0 007.62 18H25a2.17 2.17 0 002.18-2.18V2.74A2.16 2.16 0 0025 .57zm-7.9 14.36v1a.82.82 0 01-.82.82h-.9a.75.75 0 01-.74-.75v-1a3.64 3.64 0 01-2.78-2.06.78.78 0 01.74-1.07h.22a.83.83 0 01.75.55c.26.69 1 1.17 2.31 1.17 1.81 0 2.22-.9 2.22-1.46 0-.77-.41-1.49-2.47-2-2.28-.55-3.85-1.49-3.85-3.38 0-1.59 1.28-2.62 2.87-3v-1a.83.83 0 01.83-.83h.86a.78.78 0 01.77.77v1a3.31 3.31 0 012.43 2.09.79.79 0 01-.75 1h-.24a.75.75 0 01-.71-.57c-.21-.7-.79-1.16-2-1.16-1.38 0-2.21.63-2.21 1.52s.6 1.28 2.46 1.76S20 9.69 20 12c0 1.71-1.29 2.63-2.9 2.93z\"/><path class=\"cls-1\" d=\"M5.45 11.42h-.4V9.19H3.42v2.23H1.24v1.68h2.18v2.23h1.63V13.1h2.18v-1.68H5.45z\"/>",
    "attrs": {
      "aria-hidden": "true",
      "id": "Layer_1",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 28 18.72"
    }
  };
  _exports.default = _default;
});