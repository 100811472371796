define("boulevard/components/boulevard/form-material/description", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="form-material__description">
    {{yield}}
  </div>
  
  */
  {
    "id": "ltrnJRMB",
    "block": "[[[10,0],[14,0,\"form-material__description\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "boulevard/components/boulevard/form-material/description.hbs",
    "isStrictMode": false
  });
  /* eslint-disable ember/no-classic-components */
  let Description = (_dec = (0, _component2.tagName)(''), _dec(_class = class Description extends _component.default {}) || _class);
  _exports.default = Description;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Description);
});