define("ember-svg-jar/inlined/check_icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#FFF\" d=\"M114.2 15.2l-5.5 5.8-64.9 69-24.2-23.7-5.7-5.6L2.7 72.1l5.7 5.6 30 29.4 5.8 5.7 5.6-5.9L120.3 32l5.5-5.8-11.6-11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "-26 -26 180 180"
    }
  };
  _exports.default = _default;
});