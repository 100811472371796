define("ember-svg-jar/inlined/videoContest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>ss-video-voting</title><path d=\"M24.139 13.611V7.883c0-.896-.736-1.632-1.632-1.632H2.891c-.896 0-1.632.736-1.632 1.632v16.341c0 .896.736 1.632 1.632 1.632h19.605c.896 0 1.632-.736 1.632-1.632v-5.717l6.539 6.539V7.073l-6.528 6.539zm-13.718 8.832l-5.547-5.355 1.664-1.728 3.819 3.691 8.832-9.088 1.717 1.675-10.485 10.805z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});