define("ember-svg-jar/inlined/create-events-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M39.778 15v3H28.222v-3h-2.889v3H23.89c-1.603 0-2.875 1.35-2.875 3L21 42c0 1.65 1.286 3 2.889 3H44.11C45.7 45 47 43.65 47 42V21c0-1.65-1.3-3-2.889-3h-1.444v-3h-2.89zm4.333 27H23.89V25.5H44.11V42z\"/><path d=\"M19 40v3.75h-4V40h-3.75v-4H15v-3.75h4V36h3.75v4H19z\"/>",
    "attrs": {
      "aria-hidden": "true",
      "viewBox": "0 0 62 62",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});