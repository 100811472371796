define("ember-svg-jar/inlined/Articles_Images_Right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.d1433454-0b6d-427d-a935-b9b31a6d9fa5{stroke:#fff;stroke-miterlimit:10;stroke-width:.23px;opacity:.8}</style></defs><title>Right Aligned</title><rect class=\"d1433454-0b6d-427d-a935-b9b31a6d9fa5\" x=\"61.94\" y=\".11\" width=\"56.09\" height=\"45.51\" rx=\"1.74\" ry=\"1.74\"/><rect class=\"21aefef4-5c1e-4705-8388-7cd7be1a7d76\" x=\".05\" y=\"4.51\" width=\"57.2\" height=\"8.39\" rx=\"4.2\" ry=\"4.2\"/><rect class=\"9aed344f-6c6f-462d-8f1f-c7b97a9b300d\" x=\".61\" y=\"17.53\" width=\"56.09\" height=\"4.75\" rx=\"2.38\" ry=\"2.38\"/><rect class=\"701ebff8-b68a-4a16-9100-b2f2b691ee33\" x=\".61\" y=\"33.15\" width=\"40.8\" height=\"4.75\" rx=\"2.38\" ry=\"2.38\"/><rect class=\"9aed344f-6c6f-462d-8f1f-c7b97a9b300d\" x=\".61\" y=\"25.34\" width=\"56.09\" height=\"4.75\" rx=\"2.38\" ry=\"2.38\"/><rect class=\"d1433454-0b6d-427d-a935-b9b31a6d9fa5\" x=\"62.12\" y=\"56.75\" width=\"56.09\" height=\"45.51\" rx=\"1.74\" ry=\"1.74\"/><rect class=\"21aefef4-5c1e-4705-8388-7cd7be1a7d76\" x=\".23\" y=\"61.15\" width=\"57.2\" height=\"8.39\" rx=\"4.2\" ry=\"4.2\"/><rect class=\"9aed344f-6c6f-462d-8f1f-c7b97a9b300d\" x=\".79\" y=\"74.17\" width=\"56.09\" height=\"4.75\" rx=\"2.38\" ry=\"2.38\"/><rect class=\"701ebff8-b68a-4a16-9100-b2f2b691ee33\" x=\".79\" y=\"89.78\" width=\"40.8\" height=\"4.75\" rx=\"2.38\" ry=\"2.38\"/><rect class=\"9aed344f-6c6f-462d-8f1f-c7b97a9b300d\" x=\".79\" y=\"81.98\" width=\"56.09\" height=\"4.75\" rx=\"2.38\" ry=\"2.38\"/>",
    "attrs": {
      "id": "fce8852a-4c61-4cac-93a8-f3ebba334c21",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 118.32 102.37"
    }
  };
  _exports.default = _default;
});