define("ember-svg-jar/inlined/sign-ups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Layer 2\"><path d=\"M19.18 1.63a.28.28 0 01-.29.29.27.27 0 01-.28-.29v-.7a.27.27 0 01.28-.29.27.27 0 01.29.29zm0 1.68a.28.28 0 01-.29.29.27.27 0 01-.28-.29v-.7a.27.27 0 01.28-.29.28.28 0 01.29.29zm0 1.68a.27.27 0 01-.29.29.27.27 0 01-.28-.28v-.7a.27.27 0 01.28-.3.27.27 0 01.29.29zm0 1.69a.28.28 0 01-.29.29.27.27 0 01-.28-.29V6a.27.27 0 01.28-.29.27.27 0 01.29.29zm0 1.68a.28.28 0 01-.29.29.27.27 0 01-.28-.29v-.7a.27.27 0 01.28-.29.28.28 0 01.29.29zm0 1.68a.27.27 0 01-.29.29.27.27 0 01-.28-.29v-.69a.27.27 0 01.28-.3.28.28 0 01.29.3zm0 1.66a.28.28 0 01-.29.29.27.27 0 01-.28-.29V11a.27.27 0 01.28-.29.28.28 0 01.29.29zM12.28 9a.28.28 0 00-.2.29v.35a.35.35 0 01-.69 0v-.29a.31.31 0 00-.26-.3 2.36 2.36 0 01-1-.37l.25-.7.06.06a2.27 2.27 0 001.2.38c.57 0 1-.32 1-.79s-.26-.69-1-1-1.46-.7-1.46-1.57a1.46 1.46 0 011.09-1.42.3.3 0 00.2-.29V3a.35.35 0 01.34-.35.34.34 0 01.35.35v.26a.31.31 0 00.25.29 2.26 2.26 0 01.83.29h.06l-.26.7a1.94 1.94 0 00-1.06-.29c-.63 0-.86.37-.86.69s.23.61 1 .93 1.37.7 1.37 1.66A1.54 1.54 0 0112.28 9zm10.9-5.87a2.82 2.82 0 01-2.8-2.84.27.27 0 00-.29-.29H3.38a.28.28 0 00-.29.29 2.82 2.82 0 01-2.8 2.84.27.27 0 00-.29.29v5.81a.27.27 0 00.29.29 2.83 2.83 0 012.8 2.84.27.27 0 00.29.29h16.74a.27.27 0 00.29-.29 2.83 2.83 0 012.8-2.84.27.27 0 00.29-.29V3.42a.34.34 0 00-.32-.29z\" fill=\"#43a047\" data-name=\"Layer 1\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 23.5 12.65"
    }
  };
  _exports.default = _default;
});