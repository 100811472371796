define("ember-svg-jar/inlined/One_Column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>One Column</title><rect class=\"3b700b0a-45d4-461d-b1d7-27164a771412\" x=\".25\" y=\".25\" width=\"115.67\" height=\"107\" rx=\"1.74\" ry=\"1.74\" stroke=\"#fff\" stroke-miterlimit=\"10\" stroke-width=\".5\" opacity=\".9\"/>",
    "attrs": {
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 116.17 107.5"
    }
  };
  _exports.default = _default;
});