define("ember-svg-jar/inlined/message-campaign-invite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M21 7.7V1.6c0-.7-.6-1.3-1.3-1.3H4.3C3.6.3 3 .9 3 1.6v6.1L.4 9.9v11.5c0 1.3 1 2.3 2.3 2.3h18.7c1.3 0 2.3-1 2.3-2.3V9.9L21 7.7zM7.7 3.9c0-.5.4-.9.8-.9H9v-.8h.9V3h4.3v-.8h.8V3h.4c.5 0 .9.4.9.9v6.9c0 .5-.4.9-.9.9H8.5c-.5 0-.9-.4-.9-.9V3.9zm5.5 14.3c-.7.6-1.7.6-2.4 0l-9.2-7.5L3 9.5v.6l8.6 7c.2.2.6.2.8 0l8.6-7v-.6l1.4 1.2-9.2 7.5z\"/><path d=\"M8.5 5.2v5.6h6.9V5.2H8.5zM14 7.6l-1 .7c-.1.1-.1.2-.1.3l.4 1.1c.1.2-.2.4-.3.2l-1-.7c-.1-.1-.2-.1-.3 0l-.9.7c-.2.1-.4 0-.3-.2l.4-1.1c0-.1 0-.2-.1-.3l-1-.7c-.2-.1-.1-.4.1-.4h1.2c.1 0 .2-.1.2-.2l.4-1c.1-.2.4-.2.4 0l.4 1.1c0 .1.1.2.2.2h1.2c.1-.1.2.2.1.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});