define("ember-svg-jar/inlined/poll_big", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1 1.665h136v122H1v-122z\" fill=\"#F1F3F3\"/><path d=\"M1 1.665h136v122H1v-122z\" stroke=\"#BFC6CE\" stroke-width=\"2\"/><path fill=\"#6B7786\" d=\"M22 12.665h16v3H22zM22 17.665h40v3H22z\"/><path d=\"M22.5 24.665a.5.5 0 01.5-.5h41a.5.5 0 01.5.5v33a.5.5 0 01-.5.5H23a.5.5 0 01-.5-.5v-33z\" fill=\"#E0E3E6\"/><path d=\"M22.5 24.665a.5.5 0 01.5-.5h41a.5.5 0 01.5.5v33a.5.5 0 01-.5.5H23a.5.5 0 01-.5-.5v-33z\" stroke=\"#E0E3E6\"/><path d=\"M52.5 48.165v-14c0-1.1-.9-2-2-2h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-12.5-5.5l2.5 3.01 3.5-4.51 4.5 6h-14l3.5-4.5z\" fill=\"#6B7786\"/><path d=\"M73.5 24.665a.5.5 0 01.5-.5h41a.5.5 0 01.5.5v33a.5.5 0 01-.5.5H74a.5.5 0 01-.5-.5v-33z\" fill=\"#E0E3E6\"/><path d=\"M73.5 24.665a.5.5 0 01.5-.5h41a.5.5 0 01.5.5v33a.5.5 0 01-.5.5H74a.5.5 0 01-.5-.5v-33z\" stroke=\"#E0E3E6\"/><path d=\"M103.5 48.165v-14c0-1.1-.9-2-2-2h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-12.5-5.5l2.5 3.01 3.5-4.51 4.5 6h-14l3.5-4.5zM22 66.665h16v3H22zM22 71.665h40v3H22z\" fill=\"#6B7786\"/><rect x=\"22.5\" y=\"78.165\" width=\"42\" height=\"34\" rx=\".5\" fill=\"#E0E3E6\"/><rect x=\"22.5\" y=\"78.165\" width=\"42\" height=\"34\" rx=\".5\" stroke=\"#E0E3E6\"/><path d=\"M52.5 102.165v-14c0-1.1-.9-2-2-2h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-12.5-5.5l2.5 3.01 3.5-4.51 4.5 6h-14l3.5-4.5z\" fill=\"#6B7786\"/><rect x=\"73.5\" y=\"78.165\" width=\"42\" height=\"34\" rx=\".5\" fill=\"#E0E3E6\"/><rect x=\"73.5\" y=\"78.165\" width=\"42\" height=\"34\" rx=\".5\" stroke=\"#E0E3E6\"/><path d=\"M103.5 102.165v-14c0-1.1-.9-2-2-2h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-12.5-5.5l2.5 3.01 3.5-4.51 4.5 6h-14l3.5-4.5z\" fill=\"#6B7786\"/>",
    "attrs": {
      "width": "138",
      "height": "125",
      "viewBox": "0 0 138 125",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});