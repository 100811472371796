define("ember-svg-jar/inlined/create-promotions-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M372.7 193.7c0-.8-.1-1.7-.3-2.5-1.5-6.2-6.7-10.3-13.3-10.3h-39.9v-13.2c-.1-12.6-9.8-22.4-22.4-22.5h-80.2c-3.4 0-6.8.6-9.8 2.3-8.2 4.5-12.6 11.3-12.5 20.8V181h-39.9c-7.8 0-13.6 5.8-13.6 13.6v17.5c0 1.6.1 3.1.3 4.7 1 7.7 4.4 14.4 8.8 20.6 7.2 9.9 16.6 17.2 27.5 22.6 12 5.9 24.7 9.3 38.1 10.1.5 0 1.1.4 1.4.8 3.6 4.5 7.7 8.5 12.1 12.1 2.5 2 4.6 4.3 6.2 7.1 3.7 6.9 4.5 14.2 2.8 21.8-.7 3.3-2.4 6.1-5 8.3-3 2.6-6.7 3.5-10.6 3.6-7.2 0-13.7 1.7-19.5 6-5.7 4.3-8.9 9.9-8.7 17.2v7.4c0 3.6 1.6 5.2 5.2 5.2H314c3.3 0 5-1.7 5-5v-8.1c0-4.3-1.1-8.2-3.7-11.7-4-5.2-9.3-8.5-15.7-9.9-3.5-.8-7.1-.8-10.7-1.2-6.7-.6-11.7-4.8-13.6-11.3-1-3.6-1-7.3-.7-10.9.7-7.4 3.7-13.6 9.5-18.4 4.5-3.7 8.6-7.7 12.3-12.2.3-.4.9-.7 1.5-.7 3.7-.4 7.4-.6 11.1-1.3 13-2.2 25.1-6.9 36.1-14.1 9.6-6.3 17.3-14.3 22.7-24.4 2.9-5.4 4.6-11.1 4.7-17.2.3-6.7.2-13.1.2-19.5zm-168.3 56.9c-3.2-.9-6.4-1.7-9.6-2.7-8.4-2.8-16-6.9-22.6-12.8-4.5-3.9-8.6-8.3-11.2-13.7-1.2-2.3-2.1-4.8-2.2-7.4-.1-5 0-10 0-15.1h35.6c.1 17.9 2.9 35.2 10.3 51.5-.2 0-.3.1-.3.2zm150.3-36.8c-.1 3.8-1.7 7.1-3.7 10.3-4.2 6.5-9.8 11.6-16.2 15.9-7.8 5.2-16.4 8.5-25.5 10.4h-.5c7.4-16.5 10.2-33.7 10.3-51.6h35.6l.1.1c0 5 .1 10-.1 14.9z\"/><path d=\"M159.5 272.8h-17.8v25.8h-24.6v17.1h24.6v27.9h17.8v-27.9h24.6v-17.1h-24.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 500 500"
    }
  };
  _exports.default = _default;
});