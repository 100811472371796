define("ember-svg-jar/inlined/ss-twitter-x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>ss-twitter-x</title><path d=\"M24.325 3h4.411L19.1 14.013 30.436 29H21.56l-6.952-9.089L6.653 29H2.24l10.307-11.78L1.672 3h9.101l6.284 8.308zm-1.548 23.36h2.444L9.445 5.501H6.822z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});