define("@ss/ui/components/ui/icon-button", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
    type="button"
    class="icon-button {{if @modifier (concat 'icon-button--' @modifier)}} {{if @size (concat 'icon-button--' @size)}}"
    ...attributes
  >
    {{#if @isLoading}}
      <Ui::Spinner @size={{20}} test-icon-button-spinner />
    {{else if (has-block)}}
      {{yield}}
    {{else if @svg}}
      {{svg-jar @svg class="svg-icon"}}
    {{else}}
      <Ui::Icon @name={{@icon}} @style={{@iconStyle}} />
    {{/if}}
  
    {{#if @tooltip}}
      <Ui::Tooltip @text={{@tooltip}} @side={{@tooltipSide}} @dynamic={{@tooltipDynamic}} />
    {{/if}}
  </button>
  */
  {
    "id": "J70fa12f",
    "block": "[[[11,\"button\"],[24,4,\"button\"],[16,0,[29,[\"icon-button \",[52,[30,1],[28,[37,1],[\"icon-button--\",[30,1]],null]],\" \",[52,[30,2],[28,[37,1],[\"icon-button--\",[30,2]],null]]]]],[17,3],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"    \"],[8,[39,2],[[24,\"test-icon-button-spinner\",\"\"]],[[\"@size\"],[20]],null],[1,\"\\n\"]],[]],[[[41,[48,[30,11]],[[[1,\"    \"],[18,11,null],[1,\"\\n\"]],[]],[[[41,[30,5],[[[1,\"    \"],[1,[28,[35,5],[[30,5]],[[\"class\"],[\"svg-icon\"]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,6],null,[[\"@name\",\"@style\"],[[30,6],[30,7]]],null],[1,\"\\n  \"]],[]]]],[]]]],[]]],[1,\"\\n\"],[41,[30,8],[[[1,\"    \"],[8,[39,7],null,[[\"@text\",\"@side\",\"@dynamic\"],[[30,8],[30,9],[30,10]]],null],[1,\"\\n\"]],[]],null],[13]],[\"@modifier\",\"@size\",\"&attrs\",\"@isLoading\",\"@svg\",\"@icon\",\"@iconStyle\",\"@tooltip\",\"@tooltipSide\",\"@tooltipDynamic\",\"&default\"],false,[\"if\",\"concat\",\"ui/spinner\",\"has-block\",\"yield\",\"svg-jar\",\"ui/icon\",\"ui/tooltip\"]]",
    "moduleName": "@ss/ui/components/ui/icon-button.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});