define("ember-svg-jar/inlined/promotion-sweepstakes-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M554.393 445.315c53.141-4.776 106.877-14.926 160.018-12.539 47.17 2.388 96.727 11.344 139.718 29.854 47.17 20.3 65.678 65.678 59.112 118.221-7.762 60.306-40.602 89.562-103.893 96.727-6.569 32.243-5.374 66.276-20.3 91.951-12.539 22.092-40.004 39.408-64.485 49.558-54.931 21.495-122.403-6.569-150.466-69.86-24.481-55.528-33.436-118.221-48.96-177.931-2.985-10.748-2.985-22.092-5.972-44.781-31.048 169.571 51.945 380.342 165.988 429.302-10.748 19.107-20.898 37.019-32.243 56.722-55.528-16.122-86.576-54.931-109.863-100.31-41.199-79.412-51.945-165.988-54.931-253.163-1.194-38.215 0-76.426-5.972-115.236-4.178 50.156-7.165 100.908-13.733 151.062-7.165 51.35-28.66 96.727-75.828 124.193-43.586 25.674-87.772 25.076-129.568-2.985-39.408-26.273-56.126-63.291-45.975-100.31-35.824-20.898-75.232-33.436-99.116-60.306-54.931-62.693-27.467-150.466 56.722-181.513 45.975-17.315 97.921-19.704 146.882-26.869 21.495-2.985 44.184-2.388 75.232-3.583-61.5-10.15-113.445-15.525-164.198-28.062-54.931-13.733-103.893-41.199-123.596-97.921-31.645-90.758 41.199-179.125 120.611-156.436 11.941-29.258 17.315-63.291 36.423-85.979 54.334-63.291 157.032-47.767 191.663 30.451 17.315 39.408 22.092 85.383 25.674 128.97 4.776 56.126 1.791 112.251 2.388 168.377 0 5.972 1.791 11.344 2.388 17.315h12.539c0-55.528-.598-111.655 0-167.184.598-35.228 0-71.053 5.374-105.684C516.774 76.91 569.317 29.145 629.622 29.741c65.082.598 104.488 41.199 115.834 112.251 31.645 8.957 64.485 10.748 88.369 26.869 71.65 47.767 73.441 149.27-3.583 191.663-60.306 33.436-132.553 45.378-200.022 65.082-25.076 7.165-51.945 8.359-77.621 11.941 1.194 2.388 1.194 4.776 1.791 7.762z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 1024 1024"
    }
  };
  _exports.default = _default;
});