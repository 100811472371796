define("boulevard/components/boulevard/snackbar", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "@ember-decorators/object", "@ember/object", "@ember/object/computed", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _object, _object2, _computed, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.message}}
    {{this.message.text}}
    {{#if this.message.actionTitle}}
      <span role="button" class="blvd-snackbar__action" {{on "click" this.actionClicked}}>{{this.message.actionTitle}}</span>
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "SqUAywUb",
    "block": "[[[41,[30,0,[\"message\"]],[[[1,\"  \"],[1,[30,0,[\"message\",\"text\"]]],[1,\"\\n\"],[41,[30,0,[\"message\",\"actionTitle\"]],[[[1,\"    \"],[11,1],[24,\"role\",\"button\"],[24,0,\"blvd-snackbar__action\"],[4,[38,1],[\"click\",[30,0,[\"actionClicked\"]]],null],[12],[1,[30,0,[\"message\",\"actionTitle\"]]],[13],[1,\"\\n\"]],[]],null]],[]],null]],[],false,[\"if\",\"on\"]]",
    "moduleName": "boulevard/components/boulevard/snackbar.hbs",
    "isStrictMode": false
  });
  /* eslint-disable ember/no-observers, ember/no-classic-components, ember/no-computed-properties-in-native-classes, ember/require-tagless-components */
  let Snackbar = (_dec = (0, _component2.classNameBindings)(':blvd-snackbar', 'showMessage:blvd-snackbar--visible', 'isError:blvd-snackbar--error', 'message.disableClickToHide:blvd-snackbar--not-clickable', 'additionalClasses'), _dec2 = (0, _computed.equal)('message.type', 'error'), _dec3 = (0, _object2.computed)('message'), _dec4 = (0, _object.observes)('message'), _dec(_class = (_class2 = class Snackbar extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "isError", _descriptor, this);
    }
    get showMessage() {
      return !(0, _utils.isEmpty)(this.message);
    }
    hideSnackbar() {
      if (!this.message.disableClickToHide) {
        this.hide();
      }
    }
    click() {
      if (!this.message.disableClickToHide) {
        this.hide();
      }
    }
    messageChanged() {
      const {
        message
      } = this;
      const hide = () => {
        if (message === this.message) {
          this.hideSnackbar(false);
        }
      };
      if (!this.disableClicks) {
        if ((0, _utils.isEmpty)(this.message)) {
          document.body.removeEventListener('click', hide);
        } else {
          setTimeout(() => document.body.addEventListener('click', hide), 0);
        }
      }
    }
    actionClicked() {
      this.hide(true);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "isError", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "showMessage", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "showMessage"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "messageChanged", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "messageChanged"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "actionClicked", [_object2.action], Object.getOwnPropertyDescriptor(_class2.prototype, "actionClicked"), _class2.prototype)), _class2)) || _class);
  _exports.default = Snackbar;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Snackbar);
});