define("ember-svg-jar/inlined/promotion-ballot-bracket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style/></defs><title>bracket-icon</title><path class=\"cls-2\" d=\"M27.58 28.39v-4.11H20.9v-2.74h-1.5v2.74h-6.68v4.11H8.39v4.66h1.5v-3.16h6.56v3.16h1.5v-4.66h-3.73v-2.61h11.86v2.61h-4.03v4.66h1.5v-3.16h6.56v3.16h1.5v-4.66h-4.03zM15.48 7.53v-.92a1.59 1.59 0 01.9-1.5 1.45 1.45 0 01.71-.11h5.81a1.6 1.6 0 011.62 1.62v1h2.89a1 1 0 011 .74.63.63 0 010 .19v1.3a2.76 2.76 0 01-.35 1.25 4.78 4.78 0 01-1.64 1.76 6.54 6.54 0 01-2.61 1c-.27 0-.54.06-.81.1a.14.14 0 00-.1 0 6.16 6.16 0 01-.89.88 1.9 1.9 0 00-.69 1.34 1.92 1.92 0 00.05.79 1.07 1.07 0 001 .81 6.9 6.9 0 01.78.09 1.92 1.92 0 011.13.71 1.37 1.37 0 01.27.85v.59a.32.32 0 01-.36.36h-8.3c-.27 0-.38-.12-.38-.38v-.53a1.43 1.43 0 01.63-1.24 2.31 2.31 0 011.41-.44 1.14 1.14 0 00.77-.26 1.07 1.07 0 00.36-.59 2.23 2.23 0 00-.21-1.58 1.69 1.69 0 00-.44-.52 5.57 5.57 0 01-.88-.87.17.17 0 00-.15.03 7.16 7.16 0 01-2.75-.73 5.28 5.28 0 01-2-1.64 3.16 3.16 0 01-.64-1.49 1.87 1.87 0 010-.34V8.51a1 1 0 011-1h2.89zm8.29 5a5.35 5.35 0 001.85-.75 4.17 4.17 0 001.17-1.16 1.42 1.42 0 00.27-.74V8.81h-2.54a8.89 8.89 0 01-.75 3.75zM12.9 8.82v1.1a1.29 1.29 0 00.15.54 3.52 3.52 0 00.81 1 4.84 4.84 0 001.64.92c.23.08.47.14.7.2a9 9 0 01-.74-3.73z\"/>",
    "attrs": {
      "id": "Layer_1",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 40 40"
    }
  };
  _exports.default = _default;
});