define("ember-svg-jar/inlined/personalityQuiz", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>ss-quiz-personality</title><path d=\"M18.88 32.246H4.372a.197.197 0 00.052-.104c1.064-2.18 1.324-4.438.856-6.8-.13-.701-.311-1.35-.701-1.972a22.771 22.771 0 01-3.114-8.123c-.104-.545-.156-1.116-.234-1.687v-1.998c.026-.104.052-.208.052-.311.078-1.22.337-2.414.701-3.582.415-1.324.934-2.595 1.895-3.633C5.384 2.375 7.305 1.415 9.433.87c.623-.156 1.246-.26 1.895-.389h1.817c.234.052.467.078.701.13 2.595.363 5.009 1.194 7.241 2.569 1.817 1.116 3.244 2.569 3.867 4.646.571 1.817 1.012 3.659 1.505 5.502a1.32 1.32 0 01-.208 1.116c-.311.441-.285.882.026 1.298.285.415.571.805.934 1.168 1.064 1.064 2.128 2.128 2.907 3.426v.571c-.311.701-.441.779-1.22.701-.156-.026-.311-.026-.467-.052-.986-.104-1.765.363-2.05 1.324-.208.675-.389 1.35-.571 2.024-.234.96-.415 1.946-.882 2.829-.363.675-.856 1.168-1.661 1.246-.337.052-.675.078-1.012.104-.597.052-1.194.078-1.765.182-1.22.234-1.817 1.142-1.635 2.362 0 .259 0 .441.026.623zm-3.711-12.639v-.753c-.052-1.09.363-1.972 1.194-2.673.208-.182.415-.363.597-.545.934-.882 1.921-1.687 2.751-2.647 1.427-1.609 1.609-3.478.675-5.424-.623-1.324-1.661-2.284-2.985-2.881-2.388-1.09-4.879-1.168-7.319-.234-1.946.727-3.27 2.102-3.919 4.101-.156.467-.208.934-.285 1.427.104.026.156.052.182.052 1.09.13 2.206.286 3.296.415.208.026.286-.052.311-.234a5.036 5.036 0 01.831-1.869c1.064-1.375 3.218-1.817 4.853-1.012 1.817.882 2.18 3.14.623 4.438a65.623 65.623 0 00-2.517 2.18c-.96.856-1.739 1.869-1.895 3.192-.104.753-.078 1.531-.104 2.284 0 .052.13.182.182.182 1.194.026 2.336 0 3.53 0zm-3.66 1.376v3.841c0 .234.182.182.311.182h3.4c.234 0 .337-.052.337-.311-.026-1.142 0-2.284 0-3.426 0-.234-.052-.311-.311-.311h-3.062a5.423 5.423 0 01-.675.026z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "31",
      "height": "32",
      "viewBox": "0 0 31 32"
    }
  };
  _exports.default = _default;
});