define("ember-svg-jar/inlined/promotions-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M305.9 280.6c0-13.3.1-26.1 0-38.8-.1-29 13.2-50 38.4-63.7 9.3-5 19.5-6.9 30-6.9 82 0 163.9-.1 245.9 0 38.7.1 68.6 30.2 68.7 68.8.1 13.3 0 26.7 0 40.5H811.3c20 0 36.2 12.6 40.7 31.5.6 2.5 1 5.2 1 7.8.1 19.6.2 39.2 0 58.8-.3 18.8-5.7 36.4-14.5 52.9-16.5 31.1-40.2 55.7-69.5 74.8-34 22.2-71 36.7-110.9 43.4-11.2 1.9-22.6 2.5-33.9 3.9-1.6.2-3.5 1-4.5 2.2-11.2 13.9-23.9 26.2-37.7 37.5-17.8 14.5-27.2 33.5-29.2 56.3-1 11.3-1.1 22.5 2.1 33.5 5.6 19.9 20.9 32.6 41.6 34.5 11 1 22.2 1.2 32.9 3.5 19.5 4.2 35.8 14.4 48.1 30.4 8.1 10.5 11.4 22.7 11.4 35.8v25c0 10.2-5.1 15.3-15.4 15.3H321.9c-11.2 0-16.1-4.9-16.1-16 0-7.5.1-15 0-22.6-.4-22.3 9.1-39.4 26.7-52.6 17.8-13.3 38-18.3 59.9-18.4 11.8-.1 23.1-2.9 32.4-10.9 7.8-6.7 13-15.3 15.3-25.3 5.2-23.3 2.7-45.7-8.7-66.9-4.6-8.6-11.3-15.8-18.9-21.9-13.7-11.1-26.2-23.4-37.2-37.1-1-1.2-2.9-2.4-4.4-2.5-41-2.3-79.9-12.7-116.7-30.9-33.6-16.6-62.3-39-84.4-69.4-13.7-19-23.9-39.5-27.1-63-.6-4.7-1-9.5-1-14.3-.1-17.9-.1-35.8 0-53.7 0-23.9 17.8-41.7 41.7-41.7h118c1.4.2 2.7.2 4.5.2zm351.3 213c1-.1 1.3-.1 1.7-.1 28-5.9 54.4-15.8 78.2-31.9 19.5-13.2 36.7-28.8 49.5-48.8 6.2-9.6 10.8-20 11.3-31.5.6-15.1.3-30.3.4-45.4 0-.2-.3-.3-.3-.4H688.9c-.3 54.7-8.9 107.6-31.7 158.1zM196.8 335.3c0 15.7-.2 31.1.1 46.4.2 8.1 3.1 15.6 6.6 22.8 8.2 16.7 20.7 29.9 34.4 42 20.4 17.9 43.7 30.7 69.4 39.2 9.7 3.2 19.6 5.6 29.4 8.4.2-.2.4-.5.6-.7-22.7-50.2-31.2-103.3-31.5-158-36.5-.1-72.7-.1-109-.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 1000 1000"
    }
  };
  _exports.default = _default;
});