define("ember-svg-jar/inlined/codewordSweeps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>ss-codeword-sweeps</title><path d=\"M16.063 32.389c0 .032 0 .016 0 0zM28.612 17.118c0-1.06-.554-2.01-1.551-2.263-1.044-.269-1.123-.839-1.092-1.693a41.76 41.76 0 00-.032-3.165c-.032-.728-.111-1.472-.301-2.184C24.323 2.955 18.99-.194 14.005.914c-4.32.965-7.39 4.067-7.928 8.197-.206 1.582-.142 3.212-.174 4.826 0 .443-.142.617-.57.76-1.313.443-1.978 1.392-1.978 2.817-.016 3.909-.016 7.801 0 11.71.016 1.82 1.123 2.975 2.959 2.991 3.244.032 6.488.174 9.716.174 0-.016 0-.032 0 0 1.424 0 2.848.016 4.273 0 1.851-.032 3.687-.079 5.523-.158 1.567-.063 2.738-1.139 2.753-2.611.063-4.162.063-8.339.032-12.501zM9.829 9.444c.285-3.07 3.671-5.428 6.884-4.953 1.725.253 3.212.981 4.336 2.358.696.854 1.139 1.836 1.155 2.959.016 1.551 0 3.102 0 4.747H9.814c-.016-1.693-.142-3.418.016-5.111zm12.564 15.048c-.111.934-.633 1.377-1.614 1.503-.095.506-.079 1.029-.316 1.424-.19.332-.617.617-.997.76-.855.332-1.899-.095-2.326-1.076-.38-.87-.522-1.836-.76-2.753-.047-.174-.047-.348-.095-.696-.475 2.627.807 5.887 2.563 6.646-.158.285-.332.57-.506.87-.87-.253-1.345-.855-1.709-1.551-.633-1.234-.807-2.563-.854-3.924-.016-.585 0-1.187-.095-1.772-.063.775-.111 1.567-.222 2.342-.111.791-.443 1.503-1.171 1.931-.665.396-1.361.38-2.01-.047-.617-.411-.87-.981-.712-1.551-.554-.316-1.171-.522-1.535-.934-.854-.981-.427-2.326.87-2.817.712-.269 1.519-.301 2.279-.411.332-.047.68-.032 1.171-.063-.949-.158-1.756-.237-2.548-.443-.854-.206-1.614-.649-1.915-1.519-.491-1.392.649-2.769 1.867-2.405.19-.459.269-.981.57-1.329.839-.981 2.421-.744 2.959.475.269.601.348 1.313.396 1.994.063.87.032 1.741.047 2.611 0 .095.032.174.032.269h.19c0-.87-.016-1.725 0-2.595.016-.554 0-1.108.079-1.63.174-1.092.981-1.836 1.915-1.82.997.016 1.614.633 1.788 1.741.491.142.997.174 1.377.411 1.108.744 1.139 2.31-.047 2.959-.934.506-2.041.712-3.086 1.013-.38.111-.807.127-1.203.19 0 .047.016.079.016.127.823-.079 1.662-.222 2.469-.19.728.032 1.503.174 2.168.459.775.285 1.06.981.965 1.804z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});