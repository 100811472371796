define("ember-power-select/components/power-select-multiple/input", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "@ember/runloop", "@ember/debug", "@ember/utils", "@ember/template"], function (_exports, _component, _templateFactory, _component2, _object, _service, _runloop, _debug, _utils, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- template-lint-disable require-aria-activedescendant-tabindex --}}
  <input
    type="search"
    class="ember-power-select-trigger-multiple-input"
    aria-activedescendant={{if @select.isOpen @ariaActiveDescendant}}
    aria-haspopup="listbox"
    aria-expanded={{if @select.isOpen "true" "false"}}
    autocomplete="off"
    autocorrect="off"
    autocapitalize="off"
    spellcheck={{false}}
    id="ember-power-select-trigger-multiple-input-{{@select.uniqueId}}"
    aria-labelledby={{@ariaLabelledBy}}
    aria-label={{@ariaLabel}}
    value={{@select.searchText}}
    aria-controls={{if @select.isOpen @listboxId}}
    style={{this.triggerMultipleInputStyle}}
    placeholder={{this.maybePlaceholder}}
    disabled={{@select.disabled}}
    tabindex={{@tabindex}}
    form="power-select-fake-form"
    {{on "focus" @onFocus}}
    {{on "blur" @onBlur}}
    {{on "input" this.handleInput}}
    {{on "keydown" this.handleKeydown}}
    {{did-insert this.storeInputStyles}}
    ...attributes
  />
  */
  {
    "id": "e+r3gA53",
    "block": "[[[11,\"input\"],[24,4,\"search\"],[24,0,\"ember-power-select-trigger-multiple-input\"],[16,\"aria-activedescendant\",[52,[30,1,[\"isOpen\"]],[30,2]]],[24,\"aria-haspopup\",\"listbox\"],[16,\"aria-expanded\",[52,[30,1,[\"isOpen\"]],\"true\",\"false\"]],[24,\"autocomplete\",\"off\"],[24,\"autocorrect\",\"off\"],[24,\"autocapitalize\",\"off\"],[16,\"spellcheck\",false],[16,1,[29,[\"ember-power-select-trigger-multiple-input-\",[30,1,[\"uniqueId\"]]]]],[16,\"aria-labelledby\",[30,3]],[16,\"aria-label\",[30,4]],[16,2,[30,1,[\"searchText\"]]],[16,\"aria-controls\",[52,[30,1,[\"isOpen\"]],[30,5]]],[16,5,[30,0,[\"triggerMultipleInputStyle\"]]],[16,\"placeholder\",[30,0,[\"maybePlaceholder\"]]],[16,\"disabled\",[30,1,[\"disabled\"]]],[16,\"tabindex\",[30,6]],[24,\"form\",\"power-select-fake-form\"],[17,7],[4,[38,1],[\"focus\",[30,8]],null],[4,[38,1],[\"blur\",[30,9]],null],[4,[38,1],[\"input\",[30,0,[\"handleInput\"]]],null],[4,[38,1],[\"keydown\",[30,0,[\"handleKeydown\"]]],null],[4,[38,2],[[30,0,[\"storeInputStyles\"]]],null],[12],[13]],[\"@select\",\"@ariaActiveDescendant\",\"@ariaLabelledBy\",\"@ariaLabel\",\"@listboxId\",\"@tabindex\",\"&attrs\",\"@onFocus\",\"@onBlur\"],false,[\"if\",\"on\",\"did-insert\"]]",
    "moduleName": "ember-power-select/components/power-select-multiple/input.hbs",
    "isStrictMode": false
  });
  const ua = window && window.navigator ? window.navigator.userAgent : '';
  const isIE = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
  let PowerSelectMultipleInput = (_class = class PowerSelectMultipleInput extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "inputFont", void 0);
      _initializerDefineProperty(this, "textMeasurer", _descriptor, this);
    }
    // Properties
    get triggerMultipleInputStyle() {
      (0, _runloop.scheduleOnce)('actions', null, this.args.select.actions.reposition);
      if (!this.args.select.selected || (0, _object.get)(this.args.select.selected, 'length') === 0) {
        return (0, _template.htmlSafe)('width: 100%;');
      } else {
        let textWidth = 0;
        if (this.inputFont) {
          textWidth = this.textMeasurer.width(this.args.select.searchText, this.inputFont);
        }
        return (0, _template.htmlSafe)(`width: ${textWidth + 25}px`);
      }
    }
    get maybePlaceholder() {
      if (isIE || !this.args.isDefaultPlaceholder) {
        return undefined;
      }
      return !this.args.select.selected || (0, _object.get)(this.args.select.selected, 'length') === 0 ? this.args.placeholder || '' : '';
    }

    // Actions
    storeInputStyles(input) {
      let {
        fontStyle,
        fontVariant,
        fontWeight,
        fontSize,
        lineHeight,
        fontFamily
      } = window.getComputedStyle(input);
      this.inputFont = `${fontStyle} ${fontVariant} ${fontWeight} ${fontSize}/${lineHeight} ${fontFamily}`;
    }
    handleInput(e) {
      if (this.args.onInput && this.args.onInput(e) === false) {
        return;
      }
      this.args.select.actions.open(e);
    }
    handleKeydown(e) {
      if (e.target === null) return;
      if (this.args.onKeydown && this.args.onKeydown(e) === false) {
        e.stopPropagation();
        return false;
      }
      if (e.keyCode === 8) {
        e.stopPropagation();
        if ((0, _utils.isBlank)(e.target.value)) {
          let lastSelection = this.args.select.selected && this.args.select.selected[this.args.select.selected.length - 1];
          if (lastSelection) {
            this.args.select.actions.select(this.args.buildSelection(lastSelection, this.args.select), e);
            if (typeof lastSelection === 'string') {
              this.args.select.actions.search(lastSelection);
            } else {
              (false && !(this.args.searchField) && (0, _debug.assert)('`<PowerSelectMultiple>` requires a `@searchField` when the options are not strings to remove options using backspace', this.args.searchField));
              this.args.select.actions.search((0, _object.get)(lastSelection, this.args.searchField));
            }
            this.args.select.actions.open(e);
          }
        }
      } else if (e.keyCode >= 48 && e.keyCode <= 90 || e.keyCode === 32) {
        // Keys 0-9, a-z or SPACE
        e.stopPropagation();
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "textMeasurer", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "storeInputStyles", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "storeInputStyles"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleKeydown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeydown"), _class.prototype)), _class);
  _exports.default = PowerSelectMultipleInput;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PowerSelectMultipleInput);
});