define("ember-svg-jar/inlined/create-email-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M265.4 242.8l113.2-94.7c-1.8-.5-3.7-.7-5.6-.7H152.6c-2.5 0-5 .4-7.3 1.2L260 242.8c1.5 1.3 3.8 1.3 5.4 0z\"/><path d=\"M394.6 162.8l-115.4 96.5c-4.8 4-10.7 6-16.6 6-5.8 0-11.6-2-16.4-5.9l-115.9-95.2c-.5 2-.8 4-.8 6.2V242h7.2c10.6 0 19.2 8.6 19.2 19.2v9.8h8.5c10.6 0 19.2 8.6 19.2 19.2v19.3c0 10.6-8.6 19.2-19.2 19.2h-8.5v12.1c0 2.9-.7 5.7-1.8 8.2H373c12.7 0 23.1-10.3 23.1-23.1V170.5c-.1-2.7-.6-5.3-1.5-7.7z\"/><path d=\"M164.4 290.3h-27.7v-29h-20v29H89v19.3h27.7v31.3h20v-31.3h27.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 500 500"
    }
  };
  _exports.default = _default;
});