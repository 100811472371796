define("ember-svg-jar/inlined/create-audience-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M171.7 290.5h-27.8v-29h-20v29H96.1v19.4h27.8v31.4h20v-31.4h27.8zM297.2 226.7c27.8 3.4 50.8-20 47.3-47.8-2.4-18.8-17.8-34.1-36.6-36.4-27.8-3.4-51 19.9-47.5 47.8 2.5 18.8 17.9 34.2 36.8 36.4zM184 226.7c27.8 3.4 50.8-20 47.3-47.8-2.4-18.8-17.8-34.1-36.6-36.4-27.8-3.4-51 19.9-47.5 47.8 2.5 18.8 17.9 34.2 36.8 36.4z\"/><path d=\"M189.3 255.4c-7.5 0-16.7.9-26.5 2.6.2 1.2.3 2.4.3 3.6v9.8h8.5c10.6 0 19.2 8.6 19.2 19.2v19.3c0 10.6-8.6 19.2-19.2 19.2h-8.5v11.2h125.2v-35.4c.1-33-66-49.5-99-49.5zM302.5 255.4c-4.1 0-8.8.3-13.7.7 16.4 11.9 27.9 27.9 27.9 48.8v35.4h84.9v-35.4c0-33-66.1-49.5-99.1-49.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 500 500"
    }
  };
  _exports.default = _default;
});