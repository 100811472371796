define("@ss/ui/components/ui/steps", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flex flex-row items-stretch h-20 px-1 justify-center border-b border-t border-solid border-gray-200">
    {{#each @steps key="label" as |step|}}
      {{#let (eq step.key @current) as |isCurrent|}}
        <button
          type="button"
          class="group flex flex-col items-center justify-center flex-1 flex-shrink-0 max-w-[8rem] bg-transparent cursor-pointer transition-colors disabled:cursor-not-allowed
            {{if step.disabled 'text-gray-500' 'text-theme hover:bg-theme-faded'}}"
          disabled={{step.disabled}}
          {{on "click" (fn @onChange step.key)}}
          test-step={{step.key}}
        >
          <div
            class="flex items-center justify-center w-8 h-8 rounded-full border border-solid transition-colors
              {{if step.disabled 'border-gray-500' 'border-theme'}}
              {{if isCurrent 'text-white bg-theme'}}"
          >
            <Ui::Icon @name={{step.icon}} @style="outlined" class="text-lg" />
          </div>
  
          <div class="w-full mt-1 px-1 text-sm text-center font-medium truncate">
            {{step.label}}
          </div>
        </button>
  
        <div role="presentation" class="pointer-events-none relative w-0 self-center mb-6 last:hidden">
          <div class="h-px w-10 bg-gray-200 -translate-x-1/2"></div>
        </div>
      {{/let}}
    {{/each}}
  </div>
  
  */
  {
    "id": "+lkPysD8",
    "block": "[[[10,0],[14,0,\"flex flex-row items-stretch h-20 px-1 justify-center border-b border-t border-solid border-gray-200\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],\"label\",[[[44,[[28,[37,3],[[30,2,[\"key\"]],[30,3]],null]],[[[1,\"      \"],[11,\"button\"],[16,0,[29,[\"group flex flex-col items-center justify-center flex-1 flex-shrink-0 max-w-[8rem] bg-transparent cursor-pointer transition-colors disabled:cursor-not-allowed\\n          \",[52,[30,2,[\"disabled\"]],\"text-gray-500\",\"text-theme hover:bg-theme-faded\"]]]],[16,\"disabled\",[30,2,[\"disabled\"]]],[16,\"test-step\",[30,2,[\"key\"]]],[24,4,\"button\"],[4,[38,5],[\"click\",[28,[37,6],[[30,5],[30,2,[\"key\"]]],null]],null],[12],[1,\"\\n        \"],[10,0],[15,0,[29,[\"flex items-center justify-center w-8 h-8 rounded-full border border-solid transition-colors\\n            \",[52,[30,2,[\"disabled\"]],\"border-gray-500\",\"border-theme\"],\"\\n            \",[52,[30,4],\"text-white bg-theme\"]]]],[12],[1,\"\\n          \"],[8,[39,7],[[24,0,\"text-lg\"]],[[\"@name\",\"@style\"],[[30,2,[\"icon\"]],\"outlined\"]],null],[1,\"\\n        \"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"w-full mt-1 px-1 text-sm text-center font-medium truncate\"],[12],[1,\"\\n          \"],[1,[30,2,[\"label\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,\"role\",\"presentation\"],[14,0,\"pointer-events-none relative w-0 self-center mb-6 last:hidden\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"h-px w-10 bg-gray-200 -translate-x-1/2\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[4]]]],[2]],null],[13],[1,\"\\n\"]],[\"@steps\",\"step\",\"@current\",\"isCurrent\",\"@onChange\"],false,[\"each\",\"-track-array\",\"let\",\"eq\",\"if\",\"on\",\"fn\",\"ui/icon\"]]",
    "moduleName": "@ss/ui/components/ui/steps.hbs",
    "isStrictMode": false
  });
  // eslint-disable-next-line ember/no-empty-glimmer-component-classes
  class UiStepsComponent extends _component2.default {}
  _exports.default = UiStepsComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiStepsComponent);
});