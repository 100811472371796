define("ember-svg-jar/inlined/promotion-video-voting-standard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M772.437 444.684V261.388c0-28.672-23.552-52.224-52.224-52.224H92.501c-28.672 0-52.224 23.552-52.224 52.224v522.923c0 28.672 23.552 52.224 52.224 52.224h627.371c28.672 0 52.224-23.552 52.224-52.224V601.356l209.237 209.237V235.446L772.437 444.683zM333.483 727.308L155.99 555.959l53.248-55.296 122.197 118.101 282.624-290.816 54.955 53.589-335.531 345.771z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 1024 1024"
    }
  };
  _exports.default = _default;
});