define("ember-svg-jar/inlined/qlik-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath clipPathUnits=\"userSpaceOnUse\" id=\"a\"><path d=\"M0 0h612v792H0z\"/></clipPath></defs><g clip-path=\"url(#a)\" transform=\"matrix(2.35513 0 0 -2.35513 -1224.543 80.074)\"><path d=\"M555.77 24.48c-.265.44.09.809.09.809l3.125 3.227c.356.369-.157.369-.157.369h-2.917c-.513 0-.865-.373-.865-.373l-2.898-3.07c-.352-.372-.352.14-.352.14v6.71c0 .514-.512.514-.512.514h-2.213c-.493 0-.493-.493-.493-.493V18.272c0-.513.513-.513.513-.513h2.19c.494 0 .494.493.494.493v2.468c0 .513.374.864.374.864l1.033.97 2.594-4.355c.262-.44.775-.44.775-.44h2.768c.512 0 .247.439.247.439zm-9.494-6.208v10.12c0 .514-.513.514-.513.514h-2.214c-.512 0-.512-.513-.512-.513V18.272c0-.513.512-.513.512-.513h2.214c.513 0 .513.513.513.513m-.084 12.598v1.449s0 .494-.493.494h-2.17s-.492 0-.492-.494v-1.45s0-.492.493-.492h2.169s.493 0 .493.493m-5.543-12.598v14.02c0 .514-.513.514-.513.514h-2.257c-.513 0-.513-.513-.513-.513V18.272c0-.513.513-.513.513-.513h2.257c.513 0 .513.513.513.513m-5.075.603s.287.33-.043.619l-1.811 1.574a7.531 7.531 0 11-6.242-3.316 7.49 7.49 0 014.12 1.23l1.988-1.725s.33-.288.618.043zm-3.748 6.407a4.348 4.348 0 10-8.695 0 4.348 4.348 0 008.695 0\" fill=\"#54565b\"/><path d=\"M580.881 18.719l-2.418 2.029a8.643 8.643 0 11-2.336-2.458l2.58-2.164s.382-.321.704.062l1.532 1.826s.322.383-.062.705m-4.417 6.638a5.31 5.31 0 10-10.62.001 5.31 5.31 0 0010.62-.001\" fill=\"#009c4d\"/><path d=\"M567.75 25.358a3.404 3.404 0 106.808 0 3.404 3.404 0 00-6.808 0M579.423 31.45c0 .52.425.94.94.94a.943.943 0 00.942-.94.943.943 0 00-.941-.941.946.946 0 00-.941.94m.94.816c-.444 0-.79-.362-.79-.815 0-.455.34-.815.79-.815s.79.36.79.815c0 .453-.34.815-.79.815m-.215-1.386h-.126v1.147h.415c.199 0 .352-.1.352-.317 0-.181-.098-.302-.272-.32l.323-.51h-.156l-.305.51h-.231zm0 .62h.259c.15 0 .256.034.256.21 0 .149-.113.206-.256.206h-.26z\" fill=\"#54565b\"/></g>",
    "attrs": {
      "width": "546.157",
      "height": "160.222",
      "viewBox": "0 0 144.504 42.392",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});