define("@ss/ui/components/ui/icon", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <i
    class="{{if (eq @style 'outlined') 'material-icons-outlined' 'material-icons'}} leading-none"
    aria-hidden="true"
    ...attributes
  >
    {{@name}}
  </i>
  
  */
  {
    "id": "I+U3mixA",
    "block": "[[[11,\"i\"],[16,0,[29,[[52,[28,[37,1],[[30,1],\"outlined\"],null],\"material-icons-outlined\",\"material-icons\"],\" leading-none\"]]],[24,\"aria-hidden\",\"true\"],[17,2],[12],[1,\"\\n  \"],[1,[30,3]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@style\",\"&attrs\",\"@name\"],false,[\"if\",\"eq\"]]",
    "moduleName": "@ss/ui/components/ui/icon.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});