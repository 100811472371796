define("ember-svg-jar/inlined/create-legal-default-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>create-legal-default</title><path d=\"M8.76 17.84H5.54v-3.36H3.22v3.36H0v2.25h3.22v3.63h2.32v-3.63h3.22v-2.25z\"/><path d=\"M16.42 0H7.05a2.67 2.67 0 00-2.66 2.68v10.8h.81a1.32 1.32 0 011.33 1.33v2h1.89a1.32 1.32 0 011.33 1.33v1.59a1.33 1.33 0 01-1.33 1.33H6.53v2.3a1.22 1.22 0 01-.16.6 2.53 2.53 0 00.67.1h14.73a2.69 2.69 0 002.68-2.68V8zm-.76 15.33h-7v-1.5h7zm4.49-3H8.68v-1.5h11.47zm0-3H8.68v-1.5h11.47z\"/>",
    "attrs": {
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24.45 24.09"
    }
  };
  _exports.default = _default;
});