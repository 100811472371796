define("ember-svg-jar/inlined/poll_small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1 1.494h136v41H1v-41z\" fill=\"#F1F3F3\"/><path d=\"M1 1.494h136v41H1v-41z\" stroke=\"#BFC6CE\" stroke-width=\"2\"/><path fill=\"#6B7786\" d=\"M37 12.494h64v3H37z\"/><circle cx=\"39.5\" cy=\"20.994\" r=\"2.5\" fill=\"#6B7786\"/><path fill=\"#6B7786\" d=\"M45 19.494h29v3H45z\"/><circle cx=\"39.5\" cy=\"28.994\" r=\"2.5\" fill=\"#6B7786\"/><path fill=\"#6B7786\" d=\"M45 27.494h29v3H45z\"/>",
    "attrs": {
      "width": "138",
      "height": "44",
      "viewBox": "0 0 138 44",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});