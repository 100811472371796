define("ember-svg-jar/inlined/mediation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.75 4.886a2.501 2.501 0 10-1.5 0v2.15a7.019 7.019 0 00-5.563 3.985c-.75-.042-1.385.177-1.906.656C.261 12.157 0 12.764 0 13.5c0 .694.243 1.285.73 1.77.485.487 1.076.73 1.77.73s1.285-.243 1.77-.73A2.41 2.41 0 005 13.5c0-.36-.08-.707-.24-1.041a2.653 2.653 0 00-.677-.876 5.314 5.314 0 011.709-2.041 5.437 5.437 0 012.458-.98v2.563a2.475 2.475 0 00-1.269.908c-.32.436-.481.925-.481 1.467 0 .694.243 1.285.73 1.77.485.487 1.076.73 1.77.73a2.41 2.41 0 001.77-.73c.487-.485.73-1.076.73-1.77 0-.538-.16-1.027-.48-1.466a2.427 2.427 0 00-1.27-.907V8.562a5.5 5.5 0 012.457.982 5.291 5.291 0 011.71 2.04c-.292.25-.518.541-.677.875-.16.334-.24.68-.24 1.041 0 .694.243 1.285.73 1.77.485.487 1.076.73 1.77.73s1.285-.243 1.77-.73c.487-.485.73-1.076.73-1.77 0-.75-.26-1.365-.781-1.844-.521-.479-1.157-.69-1.907-.635a6.923 6.923 0 00-2.26-2.74 6.752 6.752 0 00-3.302-1.24V4.887z\" fill=\"#6B7786\"/>",
    "attrs": {
      "width": "18",
      "height": "16",
      "viewBox": "0 0 18 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});