define("ember-svg-jar/inlined/Left_Sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Left Sidebar</title><g class=\"5a1b7fa8-e0be-488b-99e1-685edf600987\" opacity=\".9\"><rect class=\"24ea8247-da5d-429a-991a-dfaf82ce09e6\" x=\"494.82\" y=\"330.21\" width=\"74.91\" height=\"107\" rx=\"1.74\" ry=\"1.74\" transform=\"rotate(180 305.25 218.755)\" stroke-width=\".6\" stroke=\"#fff\" stroke-miterlimit=\"10\" opacity=\".9\"/><rect class=\"35a15102-193c-4d09-bb89-202814f0f52c\" x=\"454.25\" y=\"330.21\" width=\"35.16\" height=\"107\" rx=\"1.74\" ry=\"1.74\" transform=\"rotate(180 244.81 218.755)\" stroke-width=\".41\" stroke=\"#fff\" stroke-miterlimit=\"10\" opacity=\".9\"/></g>",
    "attrs": {
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 115.98 107.6"
    }
  };
  _exports.default = _default;
});