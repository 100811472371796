define("ember-svg-jar/inlined/camera", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>camera</title><path d=\"M17.143 12q2.125 0 3.634 1.509t1.509 3.634-1.509 3.634-3.634 1.509-3.634-1.509T12 17.143t1.509-3.634T17.143 12zm12.571-7.429q1.893 0 3.232 1.339t1.339 3.232v16q0 1.893-1.339 3.232t-3.232 1.339H4.571q-1.893 0-3.232-1.339T0 25.142v-16Q0 7.249 1.339 5.91t3.232-1.339h4l.911-2.429q.339-.875 1.241-1.509t1.848-.634h9.143q.946 0 1.848.634t1.241 1.509l.911 2.429h4zM17.143 25.143q3.304 0 5.652-2.348t2.348-5.652-2.348-5.652-5.652-2.348-5.652 2.348-2.348 5.652 2.348 5.652 5.652 2.348z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "34",
      "height": "32",
      "viewBox": "0 0 34 32"
    }
  };
  _exports.default = _default;
});