define("ember-svg-jar/inlined/Articles_Images_Left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.aa96de52-ba86-44eb-ac5d-02ce7313814e{stroke:#fff;stroke-miterlimit:10;stroke-width:.07px;opacity:.4}</style></defs><title>Left Aligned</title><rect class=\"30762189-67d7-444b-ac81-8cfe0b537a5a\" x=\"59.99\" y=\"4.51\" width=\"57.2\" height=\"8.39\" rx=\"4.2\" ry=\"4.2\"/><rect class=\"6590b352-79d8-4c45-b1a2-a5504f20db03\" x=\".11\" y=\".11\" width=\"56.09\" height=\"45.51\" rx=\"1.74\" ry=\"1.74\"/><rect class=\"aa96de52-ba86-44eb-ac5d-02ce7313814e\" x=\"60.55\" y=\"17.53\" width=\"56.09\" height=\"4.75\" rx=\"2.38\" ry=\"2.38\"/><rect class=\"6a836b56-3421-4845-b1d3-a512ede21482\" x=\"60.55\" y=\"33.15\" width=\"40.8\" height=\"4.75\" rx=\"2.38\" ry=\"2.38\"/><rect class=\"aa96de52-ba86-44eb-ac5d-02ce7313814e\" x=\"60.55\" y=\"25.34\" width=\"56.09\" height=\"4.75\" rx=\"2.38\" ry=\"2.38\"/><rect class=\"30762189-67d7-444b-ac81-8cfe0b537a5a\" x=\"59.99\" y=\"61.15\" width=\"57.2\" height=\"8.39\" rx=\"4.2\" ry=\"4.2\"/><rect class=\"6590b352-79d8-4c45-b1a2-a5504f20db03\" x=\".11\" y=\"56.75\" width=\"56.09\" height=\"45.51\" rx=\"1.74\" ry=\"1.74\"/><rect class=\"aa96de52-ba86-44eb-ac5d-02ce7313814e\" x=\"60.55\" y=\"74.17\" width=\"56.09\" height=\"4.75\" rx=\"2.38\" ry=\"2.38\"/><rect class=\"6a836b56-3421-4845-b1d3-a512ede21482\" x=\"60.55\" y=\"89.78\" width=\"40.8\" height=\"4.75\" rx=\"2.38\" ry=\"2.38\"/><rect class=\"aa96de52-ba86-44eb-ac5d-02ce7313814e\" x=\"60.55\" y=\"81.98\" width=\"56.09\" height=\"4.75\" rx=\"2.38\" ry=\"2.38\"/>",
    "attrs": {
      "id": "53da946b-b6d1-4acd-b45c-c278e4f63999",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 117.24 102.37"
    }
  };
  _exports.default = _default;
});