define("ember-svg-jar/inlined/Two_Column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.a487ac52-00c5-4b96-bb80-260542e381af{opacity:.9;stroke:#fff;stroke-miterlimit:10;stroke-width:.5px}</style></defs><title>Two Column</title><g opacity=\".9\"><rect class=\"a487ac52-00c5-4b96-bb80-260542e381af\" x=\".25\" y=\".25\" width=\"52.64\" height=\"107\" rx=\"1.74\" ry=\"1.74\"/><rect class=\"a487ac52-00c5-4b96-bb80-260542e381af\" x=\"61.92\" y=\".25\" width=\"52.64\" height=\"107\" rx=\"1.74\" ry=\"1.74\"/></g>",
    "attrs": {
      "id": "4e8c873d-044e-4b9a-9064-54f412582272",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 114.81 107.5"
    }
  };
  _exports.default = _default;
});