define("ember-svg-jar/inlined/interactive-content-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M749.1 175.5H250.9c-53.3 0-96.4 43.2-96.4 96.4v363.7c0 53.3 43.2 96.4 96.4 96.4h353.9l120 120.8c19.1 19.2 51.9 5.7 51.9-21.4v-104c39.7-12 68.7-48.3 68.7-91.9V272c.1-53.3-43.1-96.5-96.3-96.5zm14.6 312.7c11.9 0 21.6 9.7 21.6 21.6 0 11.9-9.7 21.6-21.6 21.6H593.6c-11.9 0-21.6-9.7-21.6-21.6 0-11.9 9.7-21.6 21.6-21.6h170.1zm-533-33.2l37-33.4 104 115.1 241.6-236.8 34.9 35.6-2.7 2.7-276 270.4L230.7 455zm533 170.9H501.2c-11.9 0-21.6-9.7-21.6-21.6 0-11.9 9.7-21.6 21.6-21.6h262.5c11.9 0 21.6 9.7 21.6 21.6 0 12-9.7 21.6-21.6 21.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 1000 1000"
    }
  };
  _exports.default = _default;
});