define("ember-svg-jar/inlined/instagram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>instagram</title><path d=\"M24.321 25.464V13.893H21.91q.357 1.125.357 2.339 0 2.25-1.143 4.152t-3.107 3.009-4.286 1.107q-3.518 0-6.018-2.42t-2.5-5.848q0-1.214.357-2.339H3.052v11.571q0 .464.313.777t.777.313h19.089q.446 0 .768-.313t.321-.777zm-5.071-9.518q0-2.214-1.616-3.777t-3.902-1.563q-2.268 0-3.884 1.563t-1.616 3.777 1.616 3.777 3.884 1.563q2.286 0 3.902-1.563t1.616-3.777zm5.071-6.428V6.572q0-.5-.357-.866t-.875-.366h-3.107q-.518 0-.875.366t-.357.866v2.946q0 .518.357.875t.875.357h3.107q.518 0 .875-.357t.357-.875zm3.108-3.714v20.393q0 1.446-1.036 2.482t-2.482 1.036H3.518q-1.446 0-2.482-1.036T0 26.197V5.804q0-1.446 1.036-2.482t2.482-1.036h20.393q1.446 0 2.482 1.036t1.036 2.482z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "27",
      "height": "32",
      "viewBox": "0 0 27 32"
    }
  };
  _exports.default = _default;
});