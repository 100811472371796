define("@ss/ui/components/ui/actions-menu/icon-link", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Ui::IconLinkTo
    @route={{@route}}
    @model={{@model}}
    @models={{@models}}
    @query={{@query}}
    @icon={{@icon}}
    @modifier={{or @modifier "primary-square"}}
    @tooltip={{@label}}
    @tooltipSide={{@tooltipSide}}
    @iconStyle={{@iconStyle}}
    @svg={{@svg}}
    @size={{@size}}
    test-actions-icon-menu-link={{@label}}
    ...attributes
  />
  
  */
  {
    "id": "RxvwnFPL",
    "block": "[[[8,[39,0],[[16,\"test-actions-icon-menu-link\",[30,1]],[17,2]],[[\"@route\",\"@model\",\"@models\",\"@query\",\"@icon\",\"@modifier\",\"@tooltip\",\"@tooltipSide\",\"@iconStyle\",\"@svg\",\"@size\"],[[30,3],[30,4],[30,5],[30,6],[30,7],[28,[37,1],[[30,8],\"primary-square\"],null],[30,1],[30,9],[30,10],[30,11],[30,12]]],null],[1,\"\\n\"]],[\"@label\",\"&attrs\",\"@route\",\"@model\",\"@models\",\"@query\",\"@icon\",\"@modifier\",\"@tooltipSide\",\"@iconStyle\",\"@svg\",\"@size\"],false,[\"ui/icon-link-to\",\"or\"]]",
    "moduleName": "@ss/ui/components/ui/actions-menu/icon-link.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});