define("ember-svg-jar/inlined/simpleSweeps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>ss-sweeps</title><path d=\"M17.325 13.63c1.661-.149 3.34-.466 5.001-.392 1.474.075 3.023.354 4.366.933 1.474.634 2.052 2.052 1.847 3.694-.243 1.885-1.269 2.799-3.247 3.023-.205 1.008-.168 2.071-.634 2.873-.392.69-1.25 1.232-2.015 1.549-1.717.672-3.825-.205-4.702-2.183-.765-1.735-1.045-3.694-1.53-5.56-.093-.336-.093-.69-.187-1.399-.97 5.299 1.623 11.886 5.187 13.416-.336.597-.653 1.157-1.008 1.773-1.735-.504-2.706-1.717-3.433-3.135-1.287-2.482-1.623-5.187-1.717-7.911-.037-1.194 0-2.388-.187-3.601-.131 1.567-.224 3.153-.429 4.721-.224 1.605-.896 3.023-2.37 3.881-1.362.802-2.743.784-4.049-.093-1.232-.821-1.754-1.978-1.437-3.135-1.119-.653-2.351-1.045-3.097-1.885-1.717-1.959-.858-4.702 1.773-5.672 1.437-.541 3.06-.616 4.59-.84.672-.093 1.381-.075 2.351-.112-1.922-.317-3.545-.485-5.131-.877-1.717-.429-3.247-1.287-3.862-3.06-.989-2.836 1.287-5.598 3.769-4.889.373-.914.541-1.978 1.138-2.687 1.698-1.978 4.907-1.493 5.989.952.541 1.232.69 2.668.802 4.03.149 1.754.056 3.508.075 5.262 0 .187.056.354.075.541h.392c0-1.735-.019-3.489 0-5.224.019-1.101 0-2.22.168-3.303.336-2.202 1.978-3.694 3.862-3.676 2.034.019 3.265 1.287 3.62 3.508.989.28 2.015.336 2.762.84 2.239 1.493 2.295 4.665-.112 5.989-1.885 1.045-4.142 1.418-6.251 2.034-.784.224-1.623.261-2.426.373.037.075.037.149.056.243z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});