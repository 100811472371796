define("ember-svg-jar/inlined/dashboard-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#199CD0\" d=\"M464.4 336.1c24.4 14.5 53.2 40.6 86.2 78.3 33.3 37.7 55.7 61.6 67.5 71.7 16.3 13.8 31.8 20.4 47 20.4 13.8 0 24.6-4.7 33-14S711 471.6 711 458c0-15.5-3-149-9.4-258.9-58.1-37.2-127.3-58.6-201.7-58.6-203.7 0-369.2 161.1-369.2 359.6 0 68 19.5 131.5 53.2 185.7-4.4-62.1-6.7-114.5-6.7-138.4 0-67.5 18.5-123.2 55.4-167 36.7-43.8 85-65.8 144.1-65.8 34 .1 63.1 7.2 87.7 21.5z\"/><path fill=\"#81C347\" d=\"M837.1 353.3c5.2 67.7 7.6 129.8 7.6 155.4 0 62.6-17.2 113.1-51.2 151.2-33.7 38.4-79.3 57.6-137.4 57.6-39.9 0-75.4-9.9-106.9-30-21.2-13.8-46.1-36.9-75.1-70.2-34.5-39.4-57.4-63.8-67.2-73.2-15.5-13.1-30.3-19.5-44.6-19.5-15.5 0-28.3 5.4-38.4 16.3-10.1 10.8-15.3 23.6-15.3 38.9 0 17 6.9 135 20.9 238.9 51 26.1 109.1 40.6 170.4 40.6 203.9 0 369.5-161.1 369.5-359.4-.1-52-11.6-101.7-32.3-146.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 1000 1000"
    }
  };
  _exports.default = _default;
});