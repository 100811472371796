define("ember-svg-jar/inlined/votingBracket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>ss-voting-bracket</title><path d=\"M16 3.608a5.736 5.736 0 105.736 5.736v-.008A5.737 5.737 0 0016 3.608zm-1.28 8.936l-3.2-3.2.912-.904 2.304 2.296L19.6 5.864l.912.912zM22.064 21.896V18.6H16.72v-2.184h-1.2V18.6h-5.344v3.296H6.712v3.728h1.2v-2.528h5.248v2.528h1.2v-3.728h-2.984V19.8h9.488v2.096H17.64v3.728h1.2v-2.528h5.248v2.528h1.2v-3.728h-3.224z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});