define("ember-svg-jar/inlined/single-email-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M217.7 208.3c-5.2 4.4-11.6 6.5-18 6.5-6.3 0-12.6-2.1-17.8-6.4L56.8 105.6c-1.1 3.1-1.8 6.4-1.8 9.9v161.4c0 16 12.9 28.9 28.9 28.9h231.8c16 0 28.9-12.9 28.9-28.9V115.5c0-4.1-.8-7.9-2.4-11.4L217.7 208.3z\"/><path d=\"M196.8 190.3c1.7 1.4 4.2 1.4 5.8 0L324.8 88.1c-2.9-.9-5.9-1.5-9.1-1.5H83.9c-3.8 0-7.4.8-10.8 2.1l123.7 101.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 400 400"
    }
  };
  _exports.default = _default;
});