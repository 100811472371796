define("ember-svg-jar/inlined/simple-ballot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.cls-7{fill:#fff}.cls-5{fill:#ffcc80}.cls-6{fill:#ff9800}</style></defs><title>ballot-style-icons</title><path d=\"M213.63 104.53H3.43a3 3 0 01-3-3V3.43a3 3 0 013-3h210.2a3 3 0 013 3v98.1a3 3 0 01-3 3z\" stroke=\"#e6e6e6\" stroke-miterlimit=\"10\" stroke-width=\".86\" fill=\"#fff\"/><g style=\"isolation:isolate\" opacity=\".75\"><rect x=\"1.43\" y=\"1.43\" width=\"214.2\" height=\"102.1\" rx=\"2\" fill=\"#fff3e0\"/><path d=\"M213.63 2.43a.94.94 0 011 1v98.1a.94.94 0 01-1 1H3.43a.94.94 0 01-1-1V3.43a.94.94 0 011-1h210.2m0-2H3.43a3 3 0 00-3 3v98.1a3 3 0 003 3h210.2a3 3 0 003-3V3.43a3 3 0 00-3-3z\" fill=\"#ffb74d\"/></g><g id=\"Layer_3\" data-name=\"Layer 3\"><path class=\"cls-5\" d=\"M101.63 35.63h-48.8a5 5 0 01-5-5 5 5 0 015-5h48.8a5 5 0 015 5 4.89 4.89 0 01-5 5z\"/><circle class=\"cls-6\" cx=\"29.03\" cy=\"30.03\" r=\"8.1\"/><path class=\"cls-7\" d=\"M33.43 26.23l-.5.5-5.8 6.1-2.2-2.1-.4-.4-1 1 .5.5 2.7 2.6.5.5.5-.5 6.3-6.7.5-.5z\"/><path class=\"cls-5\" d=\"M101.63 57.13h-48.8a5 5 0 01-5-5 5 5 0 015-5h48.8a5 5 0 015 5 4.89 4.89 0 01-5 5z\"/><circle class=\"cls-6\" cx=\"29.03\" cy=\"51.53\" r=\"8.1\"/><path class=\"cls-7\" d=\"M33.43 47.73l-.5.5-5.8 6.1-2.2-2.1-.4-.4-1 1 .5.5 2.7 2.6.5.5.5-.5 6.3-6.7.5-.5z\"/><path class=\"cls-5\" d=\"M101.63 78.93h-48.8a5 5 0 01-5-5 5 5 0 015-5h48.8a5 5 0 015 5 5 5 0 01-5 5z\"/><circle class=\"cls-6\" cx=\"29.03\" cy=\"73.33\" r=\"8.1\"/><path class=\"cls-7\" d=\"M33.43 69.53l-.5.5-5.8 6.1-2.2-2.13-.5-.5-1 1 .5.5 2.7 2.6.5.5.5-.5 6.3-6.7.5-.5z\"/></g>",
    "attrs": {
      "id": "Layer_1",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 217.06 104.96"
    }
  };
  _exports.default = _default;
});