define("ember-svg-jar/inlined/subscription-audience", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Subscription_List_Icon</title><path d=\"M81.2 40.6L52.6 70 37.5 55.4l4.5-4.7 10.5 10.1 24-24.8z\"/><path d=\"M64 43.4L52.4 55.3l-10.5-10-9.2 9.5v-5.6c0-5.9 12-8.8 18-8.8 4.6 0 9.1 1 13.3 3zM32.6 40.4c-3 2.1-5.1 5-5.1 8.7v6.3H12.1v-6.3c0-5.9 12-8.8 18-8.8.8 0 1.6 0 2.5.1zM58.1 27.7c-.1 4.2-3.5 7.5-7.7 7.5-4.2-.1-7.5-3.4-7.5-7.6 0-4.2 3.5-7.6 7.7-7.6 4.2.1 7.6 3.6 7.5 7.7zM37.6 27.7c-.1 4.2-3.5 7.5-7.7 7.5-4.2-.1-7.5-3.4-7.5-7.6 0-4.2 3.5-7.6 7.7-7.6 4.2.1 7.5 3.6 7.5 7.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 88.8 88.8"
    }
  };
  _exports.default = _default;
});