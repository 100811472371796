define("boulevard/components/boulevard/tooltip", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _object, _component2, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- template-lint-disable no-nested-interactive--}}
  <div class="tooltip">
    <button type="button" {{on "click" this.toggleTooltip}}  aria-label={{@ariaLabel}} aria-describedby="tooltip-content" class="tooltip__button tooltip__button--trigger">
      <i class="ssIcon ssIcon-info-sign" aria-hidden="true"></i>
    </button>
    {{#if this.showTooltip}}
      <span class="ssHelpText tooltip__content {{if @isLayered "tooltip__content--layered"}}" id="tooltip-content" role="tooltip">
        {{#if @showClose}}
          <button class="tooltip__button tooltip__button--close" type="button"  aria-label="close" {{on "click" this.toggleTooltip}}>X</button>
        {{/if}}
        {{yield}}
      </span>
    {{/if}}
  </div>
  
  */
  {
    "id": "ANa8GfVw",
    "block": "[[[10,0],[14,0,\"tooltip\"],[12],[1,\"\\n  \"],[11,\"button\"],[16,\"aria-label\",[30,1]],[24,\"aria-describedby\",\"tooltip-content\"],[24,0,\"tooltip__button tooltip__button--trigger\"],[24,4,\"button\"],[4,[38,0],[\"click\",[30,0,[\"toggleTooltip\"]]],null],[12],[1,\"\\n    \"],[10,\"i\"],[14,0,\"ssIcon ssIcon-info-sign\"],[14,\"aria-hidden\",\"true\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"showTooltip\"]],[[[1,\"    \"],[10,1],[15,0,[29,[\"ssHelpText tooltip__content \",[52,[30,2],\"tooltip__content--layered\"]]]],[14,1,\"tooltip-content\"],[14,\"role\",\"tooltip\"],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"        \"],[11,\"button\"],[24,0,\"tooltip__button tooltip__button--close\"],[24,\"aria-label\",\"close\"],[24,4,\"button\"],[4,[38,0],[\"click\",[30,0,[\"toggleTooltip\"]]],null],[12],[1,\"X\"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[18,4,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@ariaLabel\",\"@isLayered\",\"@showClose\",\"&default\"],false,[\"on\",\"if\",\"yield\"]]",
    "moduleName": "boulevard/components/boulevard/tooltip.hbs",
    "isStrictMode": false
  });
  let TooltipComponent = (_class = class TooltipComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "showTooltip", _descriptor, this);
    }
    toggleTooltip() {
      this.showTooltip = !this.showTooltip;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showTooltip", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleTooltip", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleTooltip"), _class.prototype)), _class);
  _exports.default = TooltipComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TooltipComponent);
});