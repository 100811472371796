define("@ss/ui/components/ui/dropdown", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <BasicDropdown
    @disabled={{@disabled}}
    @horizontalPosition={{or @horizontalPosition "auto-right"}}
    @initiallyOpened={{@initiallyOpened}}
    @matchTriggerWidth={{@matchTriggerWidth}}
    @renderInPlace={{@renderInPlace}}
    @verticalPosition={{or @verticalPosition "auto"}}
    @onOpen={{@onOpen}}
    @onClose={{@onClose}}
    @calculatePosition={{if (eq @calculatePosition "standard") this.calculatePosition @calculatePosition}}
    ...attributes
    as |dd|
  >
    {{yield
      (hash
        actions=dd.actions
        disabled=dd.disabled
        isOpen=dd.isOpen
        uniqueId=dd.uniqueId
        trigger=(component dd.Trigger defaultClass="")
        content=(component
          dd.Content
          defaultClass="py-0.5 max-w-full rounded bg-white shadow-md border border-gray-50 ui-dropdown__transition"
        )
        item=(component "ui/dropdown/item")
        link=(component "ui/dropdown/link")
      )
    }}
  </BasicDropdown>
  */
  {
    "id": "ZD8Hz1Oc",
    "block": "[[[8,[39,0],[[17,1]],[[\"@disabled\",\"@horizontalPosition\",\"@initiallyOpened\",\"@matchTriggerWidth\",\"@renderInPlace\",\"@verticalPosition\",\"@onOpen\",\"@onClose\",\"@calculatePosition\"],[[30,2],[28,[37,1],[[30,3],\"auto-right\"],null],[30,4],[30,5],[30,6],[28,[37,1],[[30,7],\"auto\"],null],[30,8],[30,9],[52,[28,[37,3],[[30,10],\"standard\"],null],[30,0,[\"calculatePosition\"]],[30,10]]]],[[\"default\"],[[[[1,\"\\n  \"],[18,12,[[28,[37,5],null,[[\"actions\",\"disabled\",\"isOpen\",\"uniqueId\",\"trigger\",\"content\",\"item\",\"link\"],[[30,11,[\"actions\"]],[30,11,[\"disabled\"]],[30,11,[\"isOpen\"]],[30,11,[\"uniqueId\"]],[50,[30,11,[\"Trigger\"]],0,null,[[\"defaultClass\"],[\"\"]]],[50,[30,11,[\"Content\"]],0,null,[[\"defaultClass\"],[\"py-0.5 max-w-full rounded bg-white shadow-md border border-gray-50 ui-dropdown__transition\"]]],[50,\"ui/dropdown/item\",0,null,null],[50,\"ui/dropdown/link\",0,null,null]]]]]],[1,\"\\n\"]],[11]]]]]],[\"&attrs\",\"@disabled\",\"@horizontalPosition\",\"@initiallyOpened\",\"@matchTriggerWidth\",\"@renderInPlace\",\"@verticalPosition\",\"@onOpen\",\"@onClose\",\"@calculatePosition\",\"dd\",\"&default\"],false,[\"basic-dropdown\",\"or\",\"if\",\"eq\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@ss/ui/components/ui/dropdown.hbs",
    "isStrictMode": false
  });
  class UiDropdownComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "calculatePosition", (trigger, content) => {
        const {
          top,
          left,
          width,
          height
        } = trigger.getBoundingClientRect();
        const {
          height: contentHeight
        } = content.getBoundingClientRect();
        let style;
        if (window.innerHeight < top + contentHeight) {
          const bottom = top + height;
          style = {
            top: bottom - Math.min(bottom, contentHeight) + window.scrollY,
            left: left + width
          };
        } else {
          style = {
            left: left + width,
            top: top + window.pageYOffset + height / 2 - contentHeight / 2
          };
        }
        return {
          style
        };
      });
    }
  }
  _exports.default = UiDropdownComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiDropdownComponent);
});