define("ember-svg-jar/inlined/promotion-ugcstandard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M440.88 622.929l-75.6-76.592-24.868 24.37 99.965 101.462 202.92-198.445-24.37-24.868zM934.248 346.9c0-46.254-31.828-77.586-78.084-77.586h-204.41c-6.963 0-11.438-.498-13.428-8.951-8.951-33.821-34.815-55.206-69.63-56.198a3613.359 3613.359 0 00-146.718 0c-34.815.498-61.177 22.385-70.127 55.704-1.991 6.963-4.971 9.45-11.939 9.45a1692.93 1692.93 0 00-71.12 0c-7.463 0-10.943-1.492-11.438-9.95-.498-15.419-10.943-25.364-26.361-25.861a1114.137 1114.137 0 00-57.693 0c-14.422.498-25.861 10.444-26.361 24.868 0 9.95-3.977 11.438-12.434 11.438-47.248 0-78.084 30.832-78.084 78.084v377.487c0 9.45.993 18.9 3.977 27.85 10.444 33.323 37.799 51.228 76.592 51.228h717.185c49.24 0 80.571-30.337 80.571-78.084-.498-126.324-.498-252.656-.498-379.478zM493.6 733.838c-106.931 0-193.967-87.036-193.967-193.967S386.669 345.904 493.6 345.904c106.931 0 193.967 87.036 193.967 193.967S601.027 733.838 493.6 733.838z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 1024 1024"
    }
  };
  _exports.default = _default;
});