define("@ss/ui/components/ui/icon-link-to", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo
    @route={{@route}}
    @models={{if @model (array @model) (or @models (array))}}
    @query={{or @query (hash)}}
    @current-when={{@current-when}}
    class="icon-button {{if @modifier (concat 'icon-button--' @modifier)}} {{if @size (concat 'icon-button--' @size)}}"
    ...attributes
  >
    {{#if (has-block)}}
      {{yield}}
    {{else if @svg}}
      {{svg-jar @svg class="svg-icon"}}
    {{else}}
      <i class={{if (eq @iconStyle "outlined") "material-icons-outlined" "material-icons"}}>
        {{@icon}}
      </i>
    {{/if}}
  
    {{#if @tooltip}}
      <Ui::Tooltip @text={{@tooltip}} @side={{@tooltipSide}} />
    {{/if}}
  </LinkTo>
  */
  {
    "id": "YJB6WOiW",
    "block": "[[[8,[39,0],[[16,0,[29,[\"icon-button \",[52,[30,1],[28,[37,2],[\"icon-button--\",[30,1]],null]],\" \",[52,[30,2],[28,[37,2],[\"icon-button--\",[30,2]],null]]]]],[17,3]],[[\"@route\",\"@models\",\"@query\",\"@current-when\"],[[30,4],[52,[30,5],[28,[37,3],[[30,5]],null],[28,[37,4],[[30,6],[28,[37,3],null,null]],null]],[28,[37,4],[[30,7],[28,[37,5],null,null]],null],[30,8]]],[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,14]],[[[1,\"    \"],[18,14,null],[1,\"\\n\"]],[]],[[[41,[30,9],[[[1,\"    \"],[1,[28,[35,8],[[30,9]],[[\"class\"],[\"svg-icon\"]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"i\"],[15,0,[52,[28,[37,9],[[30,10],\"outlined\"],null],\"material-icons-outlined\",\"material-icons\"]],[12],[1,\"\\n      \"],[1,[30,11]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]],[]]],[1,\"\\n\"],[41,[30,12],[[[1,\"    \"],[8,[39,10],null,[[\"@text\",\"@side\"],[[30,12],[30,13]]],null],[1,\"\\n\"]],[]],null]],[]]]]]],[\"@modifier\",\"@size\",\"&attrs\",\"@route\",\"@model\",\"@models\",\"@query\",\"@current-when\",\"@svg\",\"@iconStyle\",\"@icon\",\"@tooltip\",\"@tooltipSide\",\"&default\"],false,[\"link-to\",\"if\",\"concat\",\"array\",\"or\",\"hash\",\"has-block\",\"yield\",\"svg-jar\",\"eq\",\"ui/tooltip\"]]",
    "moduleName": "@ss/ui/components/ui/icon-link-to.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});