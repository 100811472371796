define("ember-svg-jar/inlined/Right_Sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Right Sidebar</title><g opacity=\".9\"><rect class=\"57d12f62-332c-40b9-b4d3-376b2cbd2c9e\" x=\".3\" y=\".3\" width=\"74.91\" height=\"107\" rx=\"1.74\" ry=\"1.74\" stroke-width=\".6\" stroke=\"#fff\" stroke-miterlimit=\"10\" opacity=\".9\"/><rect class=\"556616ac-b0d6-4392-a89c-b7ca02a30299\" x=\"80.61\" y=\".3\" width=\"35.16\" height=\"107\" rx=\"1.74\" ry=\"1.74\" stroke-width=\".41\" stroke=\"#fff\" stroke-miterlimit=\"10\" opacity=\".9\"/></g>",
    "attrs": {
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 115.98 107.6"
    }
  };
  _exports.default = _default;
});