define("ember-svg-jar/inlined/gallery_justified", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.cls-1{fill:#ebebeb}</style></defs><rect class=\"cls-1\" x=\"22.01\" y=\"46.78\" width=\"173.47\" height=\"115.18\" rx=\"2\"/><rect class=\"cls-1\" x=\"205.24\" y=\"46.78\" width=\"62.75\" height=\"115.18\" rx=\"2\"/><rect class=\"cls-1\" x=\"94.52\" y=\"176.04\" width=\"173.47\" height=\"115.18\" rx=\"2\" transform=\"rotate(180 181.255 233.635)\"/><rect class=\"cls-1\" x=\"22.01\" y=\"176.04\" width=\"62.75\" height=\"115.18\" rx=\"2\" transform=\"rotate(180 53.385 233.635)\"/><path d=\"M282 8v322H8V8h274m8-8H0v338h290V0z\" fill=\"#bcbcbc\"/>",
    "attrs": {
      "id": "icons",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 290 338"
    }
  };
  _exports.default = _default;
});