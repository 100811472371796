define("ember-cli-ss-enums/utils/enum-data/poll-layouts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Big',
    value: '1',
    src: '/Images/poll_big.svg'
  }, {
    id: 2,
    name: 'Small',
    value: '2',
    src: '/Images/poll_small.svg'
  }];
  _exports.default = _default;
});