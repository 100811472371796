define("@ss/ui/components/ui/spinner", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <svg width={{or @size 24}} height={{or @size 24}} class="ui-spinner" viewBox="0 0 48 48" ...attributes>
    <circle class="ui-spinner__path" cx="24" cy="24" r="20" fill="none" stroke-width="6"></circle>
  </svg>
  
  */
  {
    "id": "G8DJSdbB",
    "block": "[[[11,\"svg\"],[16,\"width\",[28,[37,0],[[30,1],24],null]],[16,\"height\",[28,[37,0],[[30,1],24],null]],[24,0,\"ui-spinner\"],[24,\"viewBox\",\"0 0 48 48\"],[17,2],[12],[1,\"\\n  \"],[10,\"circle\"],[14,0,\"ui-spinner__path\"],[14,\"cx\",\"24\"],[14,\"cy\",\"24\"],[14,\"r\",\"20\"],[14,\"fill\",\"none\"],[14,\"stroke-width\",\"6\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@size\",\"&attrs\"],false,[\"or\"]]",
    "moduleName": "@ss/ui/components/ui/spinner.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});