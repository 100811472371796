define("ember-svg-jar/inlined/promotion-pickem-written-submission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M207.429 886.857l52-52-134.286-134.286-52 52v61.143h73.143v73.143h61.143zm298.857-530.285q0-12.571-12.571-12.571-5.714 0-9.714 4L174.287 657.715q-4 4-4 9.714 0 12.571 12.571 12.571 5.714 0 9.714-4l309.714-309.714q4-4 4-9.714zm-30.857-109.715l237.714 237.714L237.714 960H0V722.286zm390.285 54.857q0 30.286-21.143 51.429L749.714 448 512 210.286 606.857 116q20.571-21.714 51.429-21.714 30.286 0 52 21.714l134.286 133.714q21.143 22.286 21.143 52z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 878 1024"
    }
  };
  _exports.default = _default;
});