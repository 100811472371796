define("ember-svg-jar/inlined/photoSweeps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>ss-photo-sweeps</title><path d=\"M30.792 10.709c0-1.547-1.065-2.596-2.612-2.596h-6.839c-.233 0-.383-.017-.449-.3-.3-1.131-1.165-1.847-2.329-1.88a121.72 121.72 0 00-4.909 0c-1.165.017-2.047.749-2.346 1.864-.067.233-.166.316-.399.316a55.491 55.491 0 00-2.379 0c-.25 0-.366-.05-.383-.333-.017-.516-.366-.849-.882-.865a36.525 36.525 0 00-1.93 0c-.483.017-.865.349-.882.832 0 .333-.133.383-.416.383-1.581 0-2.612 1.032-2.612 2.612v12.629c0 .316.033.632.133.932.349 1.115 1.265 1.714 2.562 1.714h23.994c1.647 0 2.695-1.015 2.695-2.612-.017-4.226-.017-8.453-.017-12.696zm-8.369 7.87c-.116.915-.616 1.364-1.597 1.481-.1.499-.083 1.015-.3 1.398-.2.333-.616.599-.998.749-.832.333-1.88-.1-2.296-1.065-.366-.849-.516-1.814-.749-2.712-.05-.166-.05-.333-.1-.682-.483 2.596.799 5.824 2.529 6.572-.166.283-.316.566-.499.865-.849-.25-1.315-.832-1.681-1.531-.632-1.215-.799-2.529-.832-3.877-.017-.582 0-1.165-.083-1.764-.067.765-.1 1.547-.216 2.313-.1.782-.449 1.481-1.165 1.897-.666.399-1.348.383-1.98-.05-.599-.399-.865-.965-.699-1.531-.549-.316-1.148-.516-1.514-.915-.832-.965-.416-2.296.865-2.779.699-.266 1.498-.3 2.246-.416.333-.05.666-.033 1.148-.05-.932-.15-1.73-.233-2.513-.433-.832-.2-1.581-.632-1.897-1.498-.483-1.381.632-2.729 1.847-2.379.183-.449.266-.965.549-1.315.832-.965 2.396-.732 2.929.466.266.599.333 1.298.399 1.963.067.849.033 1.714.033 2.579 0 .083.017.183.033.266h.183c0-.849-.017-1.714 0-2.562 0-.532 0-1.082.083-1.614.166-1.082.965-1.814 1.897-1.797.998.017 1.597.632 1.78 1.714.483.133.998.166 1.348.416 1.098.732 1.115 2.28-.05 2.929-.915.516-2.03.699-3.062.998-.383.116-.799.133-1.181.183 0 .05.017.083.017.133.815-.067 1.631-.216 2.446-.183.715.033 1.481.166 2.146.449.749.283 1.032.982.932 1.78z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});