define("ember-svg-jar/inlined/setup-design-legacy-template-mock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<mask id=\"a\" fill=\"#fff\"><path d=\"M101.5 14h31a2 2 0 012 2v50a2 2 0 01-2 2h-31V14z\"/></mask><path d=\"M101.5 14h31a2 2 0 012 2v50a2 2 0 01-2 2h-31V14z\" fill=\"#FEF4E2\"/><path d=\"M101.5 13h31a3 3 0 013 3h-2a1 1 0 00-1-1h-31v-2zm34 53a3 3 0 01-3 3h-31v-2h31a1 1 0 001-1h2zm-34 2V14v54zm31-55a3 3 0 013 3v50a3 3 0 01-3 3v-2a1 1 0 001-1V16a1 1 0 00-1-1v-2z\" fill=\"#FCC069\" mask=\"url(#a)\"/><path d=\"M.5 4a4 4 0 014-4h93a4 4 0 014 4v134a4 4 0 01-4 4h-93a4 4 0 01-4-4V4z\" fill=\"#FCC069\"/><path d=\"M8.5 12a4 4 0 014-4h77a4 4 0 014 4v34a4 4 0 01-4 4h-77a4 4 0 01-4-4V12zM8.5 62a4 4 0 014-4h31a4 4 0 014 4v26a4 4 0 01-4 4h-31a4 4 0 01-4-4V62zM8.5 104a4 4 0 014-4h31a4 4 0 014 4v26a4 4 0 01-4 4h-31a4 4 0 01-4-4v-26zM54.5 62a4 4 0 014-4h31a4 4 0 014 4v26a4 4 0 01-4 4h-31a4 4 0 01-4-4V62zM54.5 104a4 4 0 014-4h31a4 4 0 014 4v26a4 4 0 01-4 4h-31a4 4 0 01-4-4v-26z\" fill=\"#F98300\"/><rect x=\"104.5\" y=\"20\" width=\"25\" height=\"4\" rx=\"2\" fill=\"#FCC069\"/><rect x=\"104.5\" y=\"20\" width=\"25\" height=\"4\" rx=\"2\" fill=\"#FCC069\"/><rect x=\"104.5\" y=\"27\" width=\"25\" height=\"4\" rx=\"2\" fill=\"#FCC069\"/><rect x=\"104.5\" y=\"34\" width=\"25\" height=\"4\" rx=\"2\" fill=\"#FCC069\"/><rect x=\"104.5\" y=\"41\" width=\"25\" height=\"4\" rx=\"2\" fill=\"#FCC069\"/><rect x=\"104.5\" y=\"48\" width=\"25\" height=\"4\" rx=\"2\" fill=\"#FCC069\"/>",
    "attrs": {
      "width": "135",
      "height": "142",
      "viewBox": "0 0 135 142",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});