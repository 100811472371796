define("ember-svg-jar/inlined/ballot-winner-trophy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M24 .703l4.829 2.728 5.528-.336 3.178 4.651 5.1 2.115.901 5.514 3.692 4.147-1.549 5.36 1.549 5.358-3.692 4.147-.902 5.514-1.58.655 5.144 17.32-11.27-3.57-7.477 9.234-2.746-9.247-2.94 9.305-7.312-9.363-11.33 3.372L8.328 41.13 5.365 39.9l-.902-5.514-3.69-4.147L2.32 24.88.772 19.523l3.691-4.149.902-5.513 5.099-2.115 3.179-4.651 5.527.336L24 .703zM10.183 41.898L6.166 54.615l9.024-2.686 5.813 7.443 2.686-8.501-.734-2.472-3.764-2.126-5.31.324-.01-.013-.218.013-3.181-4.579-.289-.12zm29.013-.572l-1.669.692-3.18 4.58-5.518-.338-.765.432-1.165.658-1.118 3.538 2.507 8.441 5.944-7.34 8.975 2.842-4.01-13.505zM24 3l-4.361 2.464-4.985-.304-2.873 4.206-4.622 1.916-.816 4.989L3 20.028l1.402 4.853L3 29.735l3.343 3.756.816 4.99 1.773.735.028-.084 3.048 1.263 2.863 4.124 4.768-.29.02.01.207-.011 4.246 2.399 4.248-2.4 4.985.305 2.874-4.137 4.262-1.767.36-.148.816-4.989L45 29.735l-1.402-4.854L45 20.028l-3.343-3.757-.817-4.989-4.621-1.916-2.873-4.206-4.985.304L24 3z\" fill=\"#FFF\" fill-rule=\"nonzero\"/><path d=\"M10.21 41.815l.49.203 3.18 4.58 5.518-.338 4.83 2.73.069-.04-3.294 10.422-5.813-7.443-9.024 2.686 4.044-12.8zm28.986-.49l4.011 13.506-8.975-2.843-5.944 7.341-3.246-10.93 3.787-2.139 5.518.337 3.18-4.579 1.67-.692zM24 3l4.36 2.464 4.986-.304 2.873 4.206 4.621 1.916.817 4.989L45 20.028l-1.402 4.853L45 29.735l-3.343 3.756-.817 4.99-4.621 1.915-2.873 4.136-4.985-.304L24 46.692l-4.361-2.464-4.985.304-2.873-4.136-4.622-1.916-.816-4.989L3 29.735l1.402-4.854L3 20.028l3.343-3.757.816-4.989 4.622-1.916 2.873-4.206 4.985.304L23.999 3z\" fill=\"#8F3E96\"/><path d=\"M31 19h-2v-2H19v2h-2c-1.1 0-2 .9-2 2v1c0 2.55 1.92 4.63 4.39 4.94A5.01 5.01 0 0023 29.9V33h-4v2h10v-2h-4v-3.1a5.01 5.01 0 003.61-2.96C31.08 26.63 33 24.55 33 22v-1c0-1.1-.9-2-2-2zm-14 3v-1h2v3.82c-1.16-.42-2-1.52-2-2.82zm14 0c0 1.3-.84 2.4-2 2.82V21h2v1z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "55",
      "height": "75",
      "viewBox": "0 0 48 64",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});