define("ember-svg-jar/inlined/left_menu_app_bar-01", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#5FBADE\" d=\"M0 0h394.9v166.8H0z\"/><image overflow=\"visible\" opacity=\".14\" width=\"1446\" height=\"729\" xlink:href=\"6B2E73128A23CFA5.png\" transform=\"matrix(.24 0 0 .24 -2.045 -2.045)\"/><path fill=\"#8DCEE7\" d=\"M338.8 89.5c0 27.5-5.9 53.7-16.4 77.3H0V0h316.4c14.3 26.6 22.4 57.1 22.4 89.5z\"/><image overflow=\"visible\" opacity=\".18\" width=\"986\" height=\"651\" xlink:href=\"6B2E73128A23CFA7.png\" transform=\"matrix(.24 0 0 .24 60.783 15.593)\"/><path fill=\"#1A8AC4\" d=\"M292.3 132.2c0 12-1.9 23.6-5.3 34.6H67.7c-2.7-8.5-4.4-17.5-5-26.8-.2-2.6-.3-5.2-.3-7.8C62.4 100 75.7 70.9 97 50c20.7-20.3 49.1-32.8 80.4-32.8 6.7 0 13.2.6 19.6 1.7 54.1 9.3 95.3 56.5 95.3 113.3z\"/><image overflow=\"visible\" opacity=\".29\" width=\"926\" height=\"756\" xlink:href=\"6B2E73128A23CFA2.png\" transform=\"matrix(.24 0 0 .24 -1.18 -13)\"/><path fill=\"#1E9CD0\" d=\"M204 55.2c0 51.5-40.5 93.6-91.5 96-1.5.1-3.1.1-4.7.1-16.4 0-31.8-4.1-45.2-11.3-30.3-16.2-50.9-48.1-50.9-84.8 0-10.7 1.8-21 5-30.6C19.8 15.7 24 7.5 29.2 0h157.4c4.1 5.8 7.6 12.2 10.3 18.9 4.6 11.2 7.1 23.4 7.1 36.3z\"/><g><image overflow=\"visible\" opacity=\".15\" width=\"606\" height=\"697\" xlink:href=\"6B2E73128A23CFA3.png\" transform=\"matrix(.24 0 0 .24 -9.12 13.91)\"/><path fill=\"#0D6FB4\" d=\"M121.9 111.6c0 14.2-3.3 27.7-9.3 39.5-2.8 5.6-6.1 10.8-10 15.6H0V29.5c5.4-2.2 11-3.8 16.8-4.9 5.4-1 10.9-1.5 16.5-1.5 25 0 47.6 10.4 63.7 27 15.4 15.9 24.9 37.6 24.9 61.5z\"/></g><path fill=\"none\" d=\"M0 0h394.9v166.8H0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 394.9 166.8"
    }
  };
  _exports.default = _default;
});