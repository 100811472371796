define("ember-svg-jar/inlined/create-ecommerce-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.cls-2{fill:#fff}.cls-1{fill:#43a047}</style><path class=\"cls-2\" d=\"M35.1 1.1H11.9C10.3 1.1 9 2.4 9 4v17.4c0 1.6 1.3 2.9 2.9 2.9h23.2c1.6 0 2.9-1.3 2.9-2.9V4c0-1.6-1.3-2.9-2.9-2.9z\"/><path class=\"cls-2\" d=\"M4.6 21.6c-.5 0-.9-.4-.9-.9v-2.1h-2c-.5 0-.9-.4-.9-.9v-2.2c0-.5.4-.9.9-.9h2v-2.1c0-.5.4-.9.8-.9h2.2c.5 0 .9.4.9.8v2.1h2c.5 0 .9.4.9.9v2.2c0 .5-.4.9-.8.9h-2v2.1c0 .5-.4.9-.9.9H4.6z\"/><path class=\"cls-1\" d=\"M6.8 12.5v3h2.9v2.2H6.8v3H4.6v-3H1.7v-2.3h2.9v-3l2.2.1m0-1.7H4.6c-1 0-1.7.8-1.7 1.7v1.3H1.7c-.9 0-1.7.8-1.7 1.7v2.2c0 .9.8 1.7 1.7 1.7h1.2v1.2c0 1 .8 1.7 1.7 1.7h2.2c.9 0 1.7-.8 1.7-1.7v-1.2h1.2c.9 0 1.7-.8 1.7-1.7v-2.2c0-.9-.8-1.7-1.7-1.7H8.5v-1.2c0-1-.8-1.8-1.7-1.8zM24.5 20.2v1.3c0 .6-.5 1.1-1.1 1.1h-1.2c-.6 0-1-.4-1-1v-1.4c-1.6-.2-3-1.3-3.7-2.8-.2-.5 0-1.1.5-1.3.2-.1.3-.1.5-.1h.3c.5 0 .9.3 1 .7.4.9 1.3 1.6 3.1 1.6 2.4 0 3-1.2 3-2 0-1-.5-2-3.3-2.6-3-.7-5.1-2-5.1-4.5 0-2.1 1.7-3.5 3.8-3.9V4c0-.6.5-1.1 1.1-1.1h1.1c.6 0 1 .4 1 1v1.4c1.5.3 2.7 1.4 3.2 2.8.2.5 0 1.1-.5 1.3-.1.1-.3.1-.5.1h-.3c-.4 0-.8-.3-.9-.8-.3-.9-1.1-1.5-2.6-1.5-1.8 0-2.9.8-2.9 2s.8 1.7 3.3 2.3 5.1 1.7 5.1 4.8c-.1 2.2-1.8 3.5-3.9 3.9z\"/>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 38 27.1",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});