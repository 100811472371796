define("ember-svg-jar/inlined/x_icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#FFF\" d=\"M13 1.7L1.7 13l5.7 5.7L52.7 64 7.3 109.3 1.7 115 13 126.3l5.7-5.7L64 75.3l45.3 45.3 5.7 5.7 11.3-11.3-5.7-5.7L75.3 64l45.3-45.3 5.7-5.7L115 1.7l-5.7 5.7L64 52.7 18.7 7.3 13 1.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "-26 -26 180 180"
    }
  };
  _exports.default = _default;
});