define("boulevard/components/boulevard/mapping-list-item/button", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button ...attributes type="button"
    class="mapping-list-item__button mdc-button mdc-button--primary {{if @outlined "mapping-list-item__button--outlined"}}" >
    {{yield}}
  </button>
  
  */
  {
    "id": "cuAUntxA",
    "block": "[[[11,\"button\"],[17,1],[24,4,\"button\"],[16,0,[29,[\"mapping-list-item__button mdc-button mdc-button--primary \",[52,[30,2],\"mapping-list-item__button--outlined\"]]]],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@outlined\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "boulevard/components/boulevard/mapping-list-item/button.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});