define("ember-svg-jar/inlined/videoSweeps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>ss-video-sweeps</title><path d=\"M24.085 13.632V7.979c0-.885-.725-1.611-1.611-1.611H3.082c-.885 0-1.611.725-1.611 1.611v16.16c0 .885.725 1.611 1.611 1.611h19.392c.885 0 1.611-.725 1.611-1.611v-5.653l6.464 6.464V7.169l-6.464 6.464zm-4.992 3.797c-.117.907-.608 1.344-1.568 1.461-.096.491-.085 1.003-.299 1.387-.192.331-.597.597-.971.747-.821.32-1.845-.096-2.261-1.056-.363-.843-.512-1.781-.736-2.677-.043-.16-.053-.331-.096-.672-.469 2.549.789 5.728 2.496 6.464-.16.288-.32.565-.48.853-.843-.245-1.301-.832-1.653-1.515-.619-1.195-.779-2.496-.821-3.819-.021-.576 0-1.152-.085-1.728-.064.757-.107 1.515-.213 2.272-.107.779-.437 1.451-1.141 1.877-.651.384-1.323.373-1.952-.043-.597-.395-.853-.949-.693-1.515-.533-.32-1.141-.501-1.493-.907-.821-.949-.416-2.261.853-2.741.693-.256 1.472-.288 2.219-.405.331-.053.661-.032 1.141-.053-.928-.149-1.707-.235-2.475-.427-.821-.203-1.568-.619-1.856-1.472-.469-1.355.629-2.688 1.813-2.347.181-.437.256-.96.544-1.291.821-.949 2.368-.725 2.88.459.256.587.331 1.28.384 1.941.064.843.032 1.685.043 2.539 0 .085.021.171.043.256h.192c0-.843-.011-1.685 0-2.517.011-.533 0-1.067.085-1.589.16-1.067.949-1.781 1.867-1.771.971.011 1.579.619 1.749 1.685.48.139.971.16 1.333.405 1.077.725 1.099 2.251-.053 2.88-.907.501-1.995.683-3.008.981-.373.107-.779.128-1.173.181 0 .043.011.085.011.128.8-.075 1.611-.224 2.411-.181.715.032 1.461.171 2.112.448.683.288.96.971.853 1.76z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});