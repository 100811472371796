define("ember-svg-jar/inlined/segmented-audience", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Segmented_List_Icon</title><path d=\"M76.6 38.9H65.8c-.8 0-1.6.3-2.2.9L49.8 53.9c-.6.6-.9 1.4-.9 2.2 0 .8.3 1.6.9 2.2l10.8 11c.6.6 1.3.9 2.2.9s1.6-.3 2.2-.9l13.9-14.2c.6-.6.9-1.4.9-2.2V42c-.1-1.7-1.5-3.1-3.2-3.1zm-2.3 7.8c-1.3 0-2.3-1.1-2.3-2.4 0-1.3 1-2.4 2.3-2.4 1.3 0 2.3 1.1 2.3 2.4 0 1.3-1 2.4-2.3 2.4zM56.3 40.9l-9.6 9.9c-1.2 1.2-2 2.9-2.1 4.6h-12v-6.3c0-5.9 12-8.8 18-8.8 1.9 0 3.8.2 5.7.6zM58.1 27.7c-.1 4.2-3.5 7.5-7.7 7.5-4.2-.1-7.5-3.4-7.5-7.6 0-4.2 3.5-7.6 7.7-7.6 4.2.1 7.6 3.6 7.5 7.7zM37.6 27.7c-.1 4.2-3.5 7.5-7.7 7.5-4.2-.1-7.5-3.4-7.5-7.6 0-4.2 3.5-7.6 7.7-7.6 4.2.1 7.5 3.6 7.5 7.7zM32.6 40.4c-3 2.1-5.1 5-5.1 8.7v6.3H12.1v-6.3c0-5.9 12-8.8 18-8.8.8 0 1.6 0 2.5.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 88.8 88.8"
    }
  };
  _exports.default = _default;
});