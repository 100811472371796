define("ember-svg-jar/inlined/horizontal-bar-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<filter id=\"a\"><feGaussianBlur in=\"SourceGraphic\" stdDeviation=\"3\"/></filter><g class=\"chart-viewport\"><path d=\"M58.884 10v230\"/><g class=\"bar bar1\"><path stroke-width=\"0\" fill=\"#f3f3f3\" d=\"M58.884 20h70v25h-70z\"/><text class=\"group\" filter=\"url(#a)\" x=\"-10\" y=\"12.5\" dy=\".35em\" text-anchor=\"end\" stroke-width=\"0\" transform=\"translate(58.884 20)\">13 to 18</text></g><g class=\"bar bar2\"><path stroke-width=\"0\" fill=\"#f3f3f3\" d=\"M58.884 51h80v25h-80z\"/><text class=\"group\" filter=\"url(#a)\" x=\"-10\" y=\"12.5\" dy=\".35em\" text-anchor=\"end\" stroke-width=\"0\" transform=\"translate(58.884 51)\">19 to 24</text></g><g class=\"bar bar3\"><path stroke-width=\"0\" fill=\"#f3f3f3\" d=\"M58.884 82h90v25h-90z\"/><text class=\"group\" filter=\"url(#a)\" x=\"-10\" y=\"12.5\" dy=\".35em\" text-anchor=\"end\" stroke-width=\"0\" transform=\"translate(58.884 82)\">25 to 32</text></g><g class=\"bar bar4\"><path stroke-width=\"0\" fill=\"#f3f3f3\" d=\"M58.884 113h100v25h-100z\"/><text class=\"group\" filter=\"url(#a)\" x=\"-10\" y=\"12.5\" dy=\".35em\" text-anchor=\"end\" stroke-width=\"0\" transform=\"translate(58.884 113)\">33 to 42</text></g><g class=\"bar bar5\"><path stroke-width=\"0\" fill=\"#f3f3f3\" d=\"M58.884 144h90v25h-90z\"/><text class=\"group\" filter=\"url(#a)\" x=\"-10\" y=\"12.5\" dy=\".35em\" text-anchor=\"end\" stroke-width=\"0\" transform=\"translate(58.884 144)\">43 to 52</text></g><g class=\"bar bar6\"><path stroke-width=\"0\" fill=\"#f3f3f3\" d=\"M58.884 175h80v25h-80z\"/><text class=\"group\" filter=\"url(#a)\" x=\"-10\" y=\"12.5\" dy=\".35em\" text-anchor=\"end\" stroke-width=\"0\" transform=\"translate(58.884 175)\">53 to 62</text></g><g class=\"bar bar7\"><path stroke-width=\"0\" fill=\"#f3f3f3\" d=\"M58.884 206h70v25h-70z\"/><text class=\"group\" filter=\"url(#a)\" x=\"-10\" y=\"12.5\" dy=\".35em\" text-anchor=\"end\" stroke-width=\"0\" transform=\"translate(58.884 206)\">63 to 119</text></g></g>",
    "attrs": {
      "width": "369.703",
      "height": "250"
    }
  };
  _exports.default = _default;
});