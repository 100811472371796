define("ember-svg-jar/inlined/message-campaign-birthday", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M512 301.714c47.36 0 85.333-38.4 85.333-85.333 0-16.213-4.267-31.147-12.373-43.947L512 45.714l-72.96 126.72c-8.107 12.8-12.373 27.733-12.373 43.947 0 46.933 38.4 85.333 85.333 85.333zm196.267 426.24l-45.653-45.653-46.08 45.653c-55.467 55.467-152.747 55.893-208.64 0l-45.653-45.653-46.507 45.653c-27.733 27.733-64.853 43.093-104.107 43.093-31.147 0-59.733-9.813-83.627-26.027v196.693c0 23.467 19.2 42.667 42.667 42.667h682.667c23.467 0 42.667-19.2 42.667-42.667V745.02c-23.893 16.213-52.48 26.027-83.627 26.027-39.253 0-76.373-15.36-104.107-43.093zM768 429.714H554.667v-85.333h-85.333v85.333H256.001c-70.827 0-128 57.173-128 128v65.707c0 46.08 37.547 83.627 83.627 83.627 22.187 0 43.52-8.533 58.88-24.32l91.307-90.88 90.88 90.88c31.573 31.573 86.613 31.573 118.187 0l91.307-90.88 90.88 90.88c15.787 15.787 36.693 24.32 58.88 24.32 46.08 0 83.627-37.547 83.627-83.627v-65.707c.427-70.827-56.747-128-127.573-128z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 1024 1024"
    }
  };
  _exports.default = _default;
});