define("boulevard/components/boulevard/mapping-list-item", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <li ...attributes class="mapping-list-item">
    <div class="mapping-list-item__text">
      <h3 class="mapping-list-item__header {{if @required "mapping-list-item__header--required"}}">
        {{@header}}
      </h3>
      <p class="mapping-list-item__details">
        {{yield (hash
          Description=(component "boulevard/mapping-list-item/description")
          Action=(component "boulevard/mapping-list-item/description-action")
        )}}
      </p>
    </div>
    <Boulevard::MappingListItem::Button @outlined={{@outlined}} {{on "click" @onButtonClick}}>
      {{@buttonText}}
    </Boulevard::MappingListItem::Button>
  </li>
  
  */
  {
    "id": "tgM5L1u7",
    "block": "[[[11,\"li\"],[17,1],[24,0,\"mapping-list-item\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"mapping-list-item__text\"],[12],[1,\"\\n    \"],[10,\"h3\"],[15,0,[29,[\"mapping-list-item__header \",[52,[30,2],\"mapping-list-item__header--required\"]]]],[12],[1,\"\\n      \"],[1,[30,3]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,2],[14,0,\"mapping-list-item__details\"],[12],[1,\"\\n      \"],[18,7,[[28,[37,2],null,[[\"Description\",\"Action\"],[[50,\"boulevard/mapping-list-item/description\",0,null,null],[50,\"boulevard/mapping-list-item/description-action\",0,null,null]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,4],[[4,[38,5],[\"click\",[30,5]],null]],[[\"@outlined\"],[[30,4]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,6]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@required\",\"@header\",\"@outlined\",\"@onButtonClick\",\"@buttonText\",\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\",\"boulevard/mapping-list-item/button\",\"on\"]]",
    "moduleName": "boulevard/components/boulevard/mapping-list-item.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});