define("ember-svg-jar/inlined/polls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>ss-poll</title><path d=\"M25.333 4H6.666a2.675 2.675 0 00-2.667 2.667v18.667c0 1.467 1.2 2.667 2.667 2.667h18.667c1.467 0 2.667-1.2 2.667-2.667V6.667C28 5.2 26.8 4 25.333 4zM12 22.667H9.333v-9.333H12v9.333zm5.333 0h-2.667V9.334h2.667v13.333zm5.334 0H20v-5.333h2.667v5.333z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});