define("@ss/ui/components/ui/data-table/loader", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="ui-data-table__loader" ...attributes>
    <div class="ui-data-table__loader-inner">
      <Ui::Spinner @size={{32}} />
    </div>
  </div>
  
  */
  {
    "id": "yvJauM1V",
    "block": "[[[11,0],[24,0,\"ui-data-table__loader\"],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"ui-data-table__loader-inner\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@size\"],[32]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\"],false,[\"ui/spinner\"]]",
    "moduleName": "@ss/ui/components/ui/data-table/loader.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});