define("ember-svg-jar/inlined/promotion-voting-bracket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style/></defs><title>bracket-icon</title><path class=\"cls-2\" d=\"M20 4.51a7.17 7.17 0 107.17 7.16A7.17 7.17 0 0020 4.51zm-1.6 11.17l-4-4 1.14-1.13 2.88 2.87 6.08-6.09 1.14 1.14zM27.58 27.37v-4.12H20.9v-2.73h-1.5v2.73h-6.68v4.12H8.39v4.66h1.5v-3.16h6.56v3.16h1.5v-4.66h-3.73v-2.62h11.86v2.62h-4.03v4.66h1.5v-3.16h6.56v3.16h1.5v-4.66h-4.03z\"/>",
    "attrs": {
      "id": "Layer_1",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 40 40"
    }
  };
  _exports.default = _default;
});