define("ember-svg-jar/inlined/facebook-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>facebook-sign</title><path d=\"M23.339 16.393l.411-3.911h-3.536v-1.946q0-.875.277-1.223t1.277-.348h1.964V5.054h-3.125q-2.714 0-3.893 1.286t-1.179 3.804v2.339h-2.339v3.911h2.339v11.339h4.679V16.394h3.125zm4.09-8.964v17.143q0 2.125-1.509 3.634t-3.634 1.509H5.143q-2.125 0-3.634-1.509T0 24.572V7.429q0-2.125 1.509-3.634t3.634-1.509h17.143q2.125 0 3.634 1.509t1.509 3.634z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "27",
      "height": "32",
      "viewBox": "0 0 27 32"
    }
  };
  _exports.default = _default;
});