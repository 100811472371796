define("ember-svg-jar/inlined/message-campaign-thanks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Thank You Email</title><path d=\"M56.7 13.6c-9.3 0-16.9 7.6-16.9 16.9s7.6 16.9 16.9 16.9 16.9-7.6 16.9-16.9c-.2-9.3-7.6-16.9-16.9-16.9zm0 31.9c-8.2 0-15-6.7-15-15 0-8.2 6.7-15 15-15s15 6.7 15 15c-.2 8.2-6.8 15-15 15zm8.8-22.8l-12.4 12-5.4-5.9-1.9 1.7 7.1 7.9L67 24.6l.2-.2-1.7-1.7zm-8.8-9.1c-9.3 0-16.9 7.6-16.9 16.9s7.6 16.9 16.9 16.9 16.9-7.6 16.9-16.9c-.2-9.3-7.6-16.9-16.9-16.9zm0 31.9c-8.2 0-15-6.7-15-15 0-8.2 6.7-15 15-15s15 6.7 15 15c-.2 8.2-6.8 15-15 15zm8.8-22.8l-12.4 12-5.4-5.9-1.9 1.7 7.1 7.9L67 24.6l.2-.2-1.7-1.7zm34.1 13v-29c0-3.5-2.7-6.2-6.2-6.2H19.9c-3.5 0-6.3 2.7-6.3 6.2v29.2L.9 46.5v55.1c0 6 4.9 10.9 10.9 10.9h89.3c6 0 10.9-4.9 10.9-10.8V46.3L99.6 35.7zm-63.8-12c.8-2.4-.2-5.5 1.3-7.6s4.8-2.1 6.7-3.5c1.9-1.4 3-4.6 5.4-5.4 2.4-.8 5.1 1.1 7.6 1.1s5.2-1.9 7.6-1.1c2.4.8 3.5 4 5.4 5.4s5.2 1.6 6.7 3.5.5 5.2 1.3 7.6 3.5 4.4 3.5 7-2.7 4.6-3.5 6.8c-.8 2.2.2 5.5-1.3 7.6-1.4 2.1-4.8 2.1-6.7 3.5-1.9 1.4-3 4.6-5.4 5.4-2.4.8-5.1-1.1-7.6-1.1s-5.2 1.9-7.6 1.1c-2.4-.8-3.5-4-5.4-5.4-1.9-1.4-5.2-1.6-6.7-3.5-1.4-1.9-.5-5.2-1.3-7.6-.8-2.4-3.3-4.4-3.3-6.8s2.5-4.8 3.3-7zm26.6 62.2c-3.3 2.7-8.1 2.7-11.4 0L6.8 50.1l6.8-5.7v2.7l40.9 33.6c1.1 1 2.7 1 3.8 0l41.2-33.4v-2.9l6.8 5.7-43.9 35.8zm-5.7-38.5c9.3 0 16.9-7.6 16.9-16.9S66 13.6 56.7 13.6s-16.9 7.6-16.9 16.9 7.4 16.9 16.9 16.9zm0-31.8c8.2 0 15 6.7 15 15s-6.8 14.9-15.2 14.9c-8.2 0-15-6.7-15-15 .1-8.2 6.8-14.9 15.2-14.9zm10.4 9l-1.6-1.9-12.4 12-5.4-5.9-1.9 1.7 7.1 7.9 14.2-13.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 113.1 113.1"
    }
  };
  _exports.default = _default;
});