(function() {
  function vendorModule() {
    'use strict';

    return {
      'default': self.emailAddresses,
      __esModule: true,
      parseAddressList: self.emailAddresses.parseAddressList,
      parseFrom: self.emailAddresses.parseFrom,
      parseOneAddress: self.emailAddresses.parseOneAddress,
      parseReplyTo: self.emailAddresses.parseReplyTo,
      parseSender: self.emailAddresses.parseSender,
    };
  }

  define('email-addresses', [], vendorModule);
})();
