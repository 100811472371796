define("@ss/ui/components/ui/modal/title", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <MdcLinearProgress @indeterminate={{@isLoading}} />
  
  <h2 class="px-6 pt-4 m-0 mb-6 text-xl text-theme font-semibold" ...attributes>
    {{@title}}
  </h2>
  
  */
  {
    "id": "8gQUv/fe",
    "block": "[[[8,[39,0],null,[[\"@indeterminate\"],[[30,1]]],null],[1,\"\\n\\n\"],[11,\"h2\"],[24,0,\"px-6 pt-4 m-0 mb-6 text-xl text-theme font-semibold\"],[17,2],[12],[1,\"\\n  \"],[1,[30,3]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@isLoading\",\"&attrs\",\"@title\"],false,[\"mdc-linear-progress\"]]",
    "moduleName": "@ss/ui/components/ui/modal/title.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});