define("ember-svg-jar/inlined/promotion-ugcbracket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M942.9 415.593H678.726c-19.678 0-35.779 16.102-35.779 35.779v59.63h-92.131v-213.49c0-5.069-4.176-9.243-9.243-9.243H417.534c-1.194 0-2.084.299-3.282.598v-59.036c0-19.678-16.102-35.779-35.779-35.779H114.299c-19.678 0-35.779 16.102-35.779 35.779v165.481c0 19.678 16.102 35.779 35.779 35.779h264.174c19.678 0 35.779-16.102 35.779-35.779v-60.228c.892.299 2.084.598 3.282.598h74.841c5.069 0 9.243 4.176 9.243 9.243v368.83c0 5.069-4.176 9.243-9.243 9.243h-74.841c-1.194 0-2.084.299-3.282.598v-62.018c0-19.678-16.102-35.779-35.779-35.779H114.299c-19.678 0-35.779 16.102-35.779 35.779v165.481c0 19.678 16.102 35.779 35.779 35.779h264.174c19.678 0 35.779-16.102 35.779-35.779v-56.952c.892.299 2.084.598 3.282.598h123.738c5.069 0 9.243-4.176 9.243-9.243V558.711h92.131v58.437c0 19.678 16.102 35.779 35.779 35.779h264.174c19.678 0 35.779-16.102 35.779-35.779V451.667c.299-19.975-15.803-36.077-35.485-36.077z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 1044 1024"
    }
  };
  _exports.default = _default;
});