define("ember-svg-jar/inlined/ballot_narrow_scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Narrow Scroll</title><g fill=\"none\" fill-rule=\"evenodd\"><path stroke=\"#B3B3B3\" stroke-width=\"3\" fill=\"#F2F2F2\" d=\"M1.5 1.5h152v167H1.5z\"/><path fill=\"#FFF\" d=\"M3 3h149v108H3zM3 113h149v54H3z\"/><path fill=\"#EBEBEB\" d=\"M49 127h47.5v28H49zM100.5 127H148v28h-47.5z\"/><path fill=\"#D8D8D8\" d=\"M49 119h37v4H49zM49 159h37v4H49zM3 3h42v164H3z\"/><path fill=\"#EBEBEB\" d=\"M49 23h47.5v28H49zM49 63h99v8H49zM49 75h99v8H49zM49 87h99v8H49zM49 99h99v8H49zM100.5 23H148v28h-47.5z\"/><path fill=\"#D8D8D8\" d=\"M49 15h37v4H49z\"/><path fill=\"#999\" d=\"M49 7h52v4H49z\"/><path fill=\"#FFF\" d=\"M5 7h37v3H5zM5 35h37v3H5z\"/><path fill=\"#999\" d=\"M10 14h32v3H10z\"/><path fill=\"#FFF\" d=\"M10 42h32v3H10zM10 21h32v3H10zM10 49h32v3H10zM10 28h32v3H10zM10 56h32v3H10zM10 63h32v3H10z\"/><path fill=\"#D8D8D8\" d=\"M49 55h37v4H49z\"/></g>",
    "attrs": {
      "width": "155",
      "height": "170",
      "viewBox": "0 0 155 170",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});