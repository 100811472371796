define("ember-svg-jar/inlined/photoContest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>ss-ugc-voting</title><path d=\"M13.777 19.181l-2.363-2.393-.777.762 3.124 3.171 6.341-6.201-.762-.777z\"/><path d=\"M29.195 10.555c0-1.445-.995-2.425-2.44-2.425h-6.388c-.218 0-.357-.016-.42-.28-.28-1.057-1.088-1.725-2.176-1.756a113.034 113.034 0 00-4.585 0c-1.088.016-1.912.7-2.191 1.741-.062.218-.155.295-.373.295a51.481 51.481 0 00-2.223 0c-.233 0-.342-.047-.357-.311-.016-.482-.342-.793-.824-.808a33.869 33.869 0 00-1.803 0c-.451.016-.808.326-.824.777 0 .311-.124.357-.389.357-1.476 0-2.44.963-2.44 2.44v11.796c0 .295.031.591.124.87.326 1.041 1.181 1.601 2.393 1.601h22.412c1.539 0 2.518-.948 2.518-2.44-.016-3.948-.016-7.896-.016-11.859zm-13.77 12.092c-3.342 0-6.061-2.72-6.061-6.061s2.72-6.061 6.061-6.061c3.342 0 6.061 2.72 6.061 6.061s-2.704 6.061-6.061 6.061z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});