define("@ss/helpers/helpers/join-string-array", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.joinStringArray = joinStringArray;
  function joinStringArray(_ref) {
    let [array = [], delimiter = ', ', lastDelimiter = ' and '] = _ref;
    if (array.length === 1) {
      return array[0];
    }
    if (array.length === 2) {
      return array.join(lastDelimiter);
    }
    const lastElement = array.pop();
    return array.join(delimiter) + lastDelimiter + lastElement;
  }
  var _default = (0, _helper.helper)(joinStringArray);
  _exports.default = _default;
});