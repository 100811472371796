define("ember-svg-jar/inlined/promotion-pickem-two-photo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M969.143 228.572q22.857 0 38.857 16t16 38.857v694.857q0 22.857-16 38.857t-38.857 16H420.572q-22.857 0-38.857-16t-16-38.857V813.715H54.858q-22.857 0-38.857-16t-16-38.857v-384q0-22.857 11.429-50.286t27.429-43.429L272.002 48q16-16 43.429-27.429t50.286-11.429h237.714q22.857 0 38.857 16t16 38.857v187.429q38.857-22.857 73.143-22.857h237.714zM658.286 350.286L487.429 521.143h170.857V350.286zM292.571 130.857L121.714 301.714h170.857V130.857zm112 369.715l180.571-180.571V82.287H365.713v237.714q0 22.857-16 38.857t-38.857 16H73.142v365.714h292.571V594.286q0-22.857 11.429-50.286t27.429-43.429zM950.857 960V301.714H731.428v237.714q0 22.857-16 38.857t-38.857 16H438.857v365.714h512z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 1024 1024"
    }
  };
  _exports.default = _default;
});