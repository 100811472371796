define("@ss/ui/components/ui/dropdown/item", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (element (or @as "button")) as |Element|}}
    <Element
      type="button"
      class="group/item w-full h-auto flex items-center gap-3 px-4 py-3 text-sm text-gray-600 bg-transparent transition-colors hover:bg-gray-50 disabled:opacity-60 disabled:cursor-not-allowed"
      test-dropdown-item
      ...attributes
    >
      {{#if @isLoading}}
        <Ui::Spinner @size={{18}} test-spinner />
      {{else if @icon}}
        <Ui::Icon
          @name={{@icon}}
          @style={{@iconStyle}}
          class="text-lg transition-colors text-gray-400 group-disabled/item:text-gray-400
            {{if (starts-with @modifier 'error') 'group-hover/item:text-red-500' 'group-hover/item:text-primary'}}
            "
        />
      {{else if @svg}}
        {{svg-jar @svg class="w-4.5 h-4.5"}}
      {{/if}}
  
      {{@label}}
    </Element>
  {{/let}}
  */
  {
    "id": "aqnmIV79",
    "block": "[[[44,[[28,[37,1],[[28,[37,2],[[30,1],\"button\"],null]],null]],[[[1,\"  \"],[8,[30,2],[[24,4,\"button\"],[24,0,\"group/item w-full h-auto flex items-center gap-3 px-4 py-3 text-sm text-gray-600 bg-transparent transition-colors hover:bg-gray-50 disabled:opacity-60 disabled:cursor-not-allowed\"],[24,\"test-dropdown-item\",\"\"],[17,3]],null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,4],[[[1,\"      \"],[8,[39,4],[[24,\"test-spinner\",\"\"]],[[\"@size\"],[18]],null],[1,\"\\n\"]],[]],[[[41,[30,5],[[[1,\"      \"],[8,[39,5],[[16,0,[29,[\"text-lg transition-colors text-gray-400 group-disabled/item:text-gray-400\\n          \",[52,[28,[37,6],[[30,6],\"error\"],null],\"group-hover/item:text-red-500\",\"group-hover/item:text-primary\"],\"\\n          \"]]]],[[\"@name\",\"@style\"],[[30,5],[30,7]]],null],[1,\"\\n\"]],[]],[[[41,[30,8],[[[1,\"      \"],[1,[28,[35,7],[[30,8]],[[\"class\"],[\"w-4.5 h-4.5\"]]]],[1,\"\\n    \"]],[]],null]],[]]]],[]]],[1,\"\\n    \"],[1,[30,9]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]],[\"@as\",\"Element\",\"&attrs\",\"@isLoading\",\"@icon\",\"@modifier\",\"@iconStyle\",\"@svg\",\"@label\"],false,[\"let\",\"element\",\"or\",\"if\",\"ui/spinner\",\"ui/icon\",\"starts-with\",\"svg-jar\"]]",
    "moduleName": "@ss/ui/components/ui/dropdown/item.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});