define("ember-svg-jar/inlined/message-campaign-newsletter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M188.611 948.043c1.353.338 3.044.676 4.397.676h673.163c11.163 0 20.635-9.133 20.635-20.635V167.309c0-1.692-.338-3.044-.676-4.736v-57.845c26.386 8.457 45.667 33.489 45.667 62.58v760.437c0 36.195-29.43 65.624-65.624 65.624H193.01c-29.43 0-54.124-19.281-62.58-45.667h58.183zm594.007-895.07H126.369c-25.032 0-44.991 20.297-44.991 44.991v743.862c0 25.032 20.297 44.991 44.991 44.991h656.249c25.032 0 44.991-20.297 44.991-44.991V97.964c.338-24.694-19.959-44.991-44.991-44.991zm-67.655 742.17H193.685c-12.178 0-21.988-9.81-21.988-21.988s9.81-21.988 21.988-21.988h521.278c12.178 0 21.988 9.81 21.988 21.988s-9.81 21.988-21.988 21.988zm-548.34-224.612V337.123c0-23.679 19.281-42.622 42.622-42.622h174.887c23.679 0 42.622 19.281 42.622 42.622v233.408c0 23.679-19.281 42.622-42.622 42.622H209.245c-23.679.338-42.622-18.943-42.622-42.622zm548.34 138.353H193.685c-12.178 0-21.988-9.81-21.988-21.988s9.81-21.988 21.988-21.988h521.278c12.178 0 21.988 9.81 21.988 21.988s-9.81 21.988-21.988 21.988zm3.045-98.775H484.6c-12.178 0-21.988-9.81-21.988-21.988s9.81-21.988 21.988-21.988h233.408c12.178 0 21.988 9.81 21.988 21.988s-9.81 21.988-21.988 21.988zm0-88.966H484.6c-12.178 0-21.988-9.81-21.988-21.988s9.81-21.988 21.988-21.988h233.408c12.178 0 21.988 9.81 21.988 21.988s-9.81 21.988-21.988 21.988zm0-86.259H484.6c-12.178 0-21.988-9.81-21.988-21.988s9.81-21.988 21.988-21.988h233.408c12.178 0 21.988 9.81 21.988 21.988s-9.81 21.988-21.988 21.988zm0-89.642H484.6c-12.178 0-21.988-9.81-21.988-21.988s9.81-21.988 21.988-21.988h233.408c12.178 0 21.988 9.81 21.988 21.988s-9.81 21.988-21.988 21.988zm27.062-121.779c0 10.148-8.119 17.929-17.929 17.929H181.169c-10.148 0-17.929-8.119-17.929-17.929v-57.507c0-10.148 8.119-17.929 17.929-17.929h545.634c10.148 0 17.929 8.119 17.929 17.929v57.507z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 1024 1024"
    }
  };
  _exports.default = _default;
});