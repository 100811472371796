define("ember-svg-jar/inlined/border-dashed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19 9V5h-4V3h6v6h-2zM3 21v-6h2v4h4v2H3zm0-8v-2h2v2H3zm0-4V7h2v2H3zm0-4V3h2v2H3zm4 0V3h2v2H7zm4 16v-2h2v2h-2zm0-16V3h2v2h-2zm4 16v-2h2v2h-2zm4 0v-2h2v2h-2zm0-4v-2h2v2h-2zm0-4v-2h2v2h-2z\" fill=\"#5F6368\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});