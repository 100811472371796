define("ember-svg-jar/inlined/ribbon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M45 22.688c0-1.82-1.083-3.275-2.647-4.003.722-.728 1.203-1.82 1.203-3.034 0-2.426-1.925-4.246-4.211-4.367.24-.486.36-1.092.36-1.578 0-2.426-1.924-4.368-4.33-4.368-.602 0-1.084.122-1.565.364 0-2.426-1.925-4.246-4.331-4.246-1.204 0-2.286.485-3.008 1.213C25.869 1.092 24.305 0 22.5 0c-1.805 0-3.249 1.092-3.97 2.67-.723-.729-1.805-1.214-3.009-1.214-2.406 0-4.21 1.941-4.331 4.246a3.68 3.68 0 00-1.564-.364c-2.407 0-4.332 1.942-4.332 4.368 0 .607.12 1.092.361 1.578-2.406 0-4.211 1.94-4.211 4.367 0 1.214.481 2.306 1.203 3.034-1.502.582-2.56 2.059-2.642 3.786L0 22.688c0 1.82 1.083 3.276 2.647 4.004-.722.728-1.203 1.82-1.203 3.033 0 2.427 1.925 4.247 4.211 4.368a3.762 3.762 0 00-.36 1.577c0 2.427 1.924 4.368 4.33 4.368.602 0 1.084-.121 1.565-.364a4.34 4.34 0 002.888 4.125L3.248 92.21l7.46-4.853L15.282 95l7.099-31.667L29.479 95l4.572-7.644 7.46 4.853L30.802 43.8a4.34 4.34 0 002.888-4.125 3.68 3.68 0 001.564.364c2.406 0 4.332-1.94 4.332-4.368 0-.606-.12-1.091-.361-1.577 2.406 0 4.21-1.941 4.21-4.368 0-1.213-.48-2.305-1.202-3.033 1.614-.697 2.676-2.064 2.761-3.778l.006-.226z\" fill=\"#0077BD\" fill-rule=\"nonzero\"/><path d=\"M22.5 8C30.508 8 37 14.492 37 22.5S30.508 37 22.5 37 8 30.508 8 22.5 14.492 8 22.5 8zm0 4.083l-2.927 6.907-7.49.635 5.688 4.927-1.709 7.323L22.5 27.99l6.438 3.885-1.709-7.323 5.688-4.927-7.49-.635-2.927-6.907z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "45",
      "height": "95",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});