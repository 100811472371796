define("ember-svg-jar/inlined/promotion-pickem-audio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M877.714 137.143v640q0 28.571-19.429 50.857t-49.143 34.571-59.143 18.286-55.143 6-55.143-6-59.143-18.286T531.427 828t-19.429-50.857 19.429-50.857 49.143-34.571 59.143-18.286 55.143-6q60 0 109.714 22.286V382.858L365.713 518.287V923.43q0 28.571-19.429 50.857t-49.143 34.571-59.143 18.286-55.143 6-55.143-6-59.143-18.286-49.143-34.571T-.003 923.43t19.429-50.857 49.143-34.571 59.143-18.286 55.143-6q60 0 109.714 22.286V283.431q0-17.714 10.857-32.286t28-20.286L806.855 84.573q6.857-2.286 16-2.286 22.857 0 38.857 16t16 38.857z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 878 1024"
    }
  };
  _exports.default = _default;
});