define("ember-svg-jar/inlined/ballot_category_pages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Category Pages</title><g fill=\"none\" fill-rule=\"evenodd\"><path stroke=\"#B3B3B3\" stroke-width=\"3\" fill=\"#F2F2F2\" d=\"M1.5 1.5h152v167H1.5z\"/><path fill=\"#FFF\" d=\"M3 3h149v164H3z\"/><path fill=\"#999\" d=\"M54 7h47v6H54z\"/><path fill=\"#EBEBEB\" d=\"M3 3h6v164H3zM16 30h63.333v38H16zM16 80h63.333v8H16zM16 92h63.333v8H16zM16 104h63.333v8H16zM16 128h63.333v8H16zM16 116h63.333v8H16zM16 140h63.333v8H16z\"/><path fill=\"#D8D8D8\" d=\"M16 156h24v8H16z\"/><path fill=\"#999\" fill-rule=\"nonzero\" d=\"M31 159.625h-4.564l2.097-2.096L28 157l-3 3 3 3 .529-.529-2.093-2.096H31z\"/><path fill=\"#D8D8D8\" d=\"M16 6h24v8H16z\"/><path fill=\"#EBEBEB\" d=\"M84.667 30H148v38H84.667zM84.667 80H148v8H84.667zM84.667 92H148v8H84.667zM84.667 104H148v8H84.667zM84.667 128H148v8H84.667zM84.667 116H148v8H84.667zM84.667 140H148v8H84.667z\"/><path fill=\"#D8D8D8\" d=\"M124 156h24v8h-24z\"/><path d=\"M136 157l-.529.529 2.093 2.096H133v.75h4.564l-2.093 2.096.529.529 3-3z\" fill=\"#999\" fill-rule=\"nonzero\"/><path fill=\"#D8D8D8\" d=\"M124 6h24v8h-24zM16 22h34.886v4H16zM5 6h8v8H5z\"/><path stroke=\"#999\" stroke-linecap=\"round\" d=\"M8 8l2 2-2 2\"/><path fill=\"#D8D8D8\" d=\"M16 72h34.886v4H16z\"/><path fill=\"#999\" fill-rule=\"nonzero\" d=\"M31 9.625h-4.564l2.096-2.096L28 7l-3 3 3 3 .529-.529-2.093-2.096H31z\"/><g fill=\"#999\" fill-rule=\"nonzero\"><path d=\"M136 7l-.529.529 2.093 2.096H133v.75h4.564l-2.093 2.096L136 13l3-3z\"/></g></g>",
    "attrs": {
      "width": "155",
      "height": "170",
      "viewBox": "0 0 155 170",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});