define("ember-svg-jar/inlined/promotion-creation-swoop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#E1F2F1\" d=\"M700.1 0C564.9 7.4 432.4 19.3 295.8 24.2c-98 3.5-198.2-.9-295.8-11.9V400h700V104.1h.1V0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 700 400",
      "preserveAspectRatio": "none"
    }
  };
  _exports.default = _default;
});