define("boulevard/components/boulevard/data-list-header", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _class2;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="blvd-data-list-header" ...attributes>
    <div class="blvd-data-list-header__text">{{@text}}</div>
    <div class="blvd-data-list-header__controls">
      {{#if @search}}
        <i class="blvd-data-list-header__search-icon material-icons">search</i>
        {{!-- template-lint-disable require-input-label --}}
        <input type="text" placeholder="Search" class="blvd-data-list-header__search-input" value={{@query}} oninput={{this._search}}>
      {{/if}}
      {{yield}}
    </div>
  </div>
  
  */
  {
    "id": "PTtpt6eD",
    "block": "[[[11,0],[24,0,\"blvd-data-list-header\"],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"blvd-data-list-header__text\"],[12],[1,[30,2]],[13],[1,\"\\n  \"],[10,0],[14,0,\"blvd-data-list-header__controls\"],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"      \"],[10,\"i\"],[14,0,\"blvd-data-list-header__search-icon material-icons\"],[12],[1,\"search\"],[13],[1,\"\\n\"],[1,\"      \"],[10,\"input\"],[14,\"placeholder\",\"Search\"],[14,0,\"blvd-data-list-header__search-input\"],[15,2,[30,4]],[15,\"oninput\",[30,0,[\"_search\"]]],[14,4,\"text\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[18,5,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@text\",\"@search\",\"@query\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "boulevard/components/boulevard/data-list-header.hbs",
    "isStrictMode": false
  });
  /* eslint-disable ember/no-classic-components */
  let DataListHeader = (_dec = (0, _component2.tagName)(''), _dec(_class = (_class2 = class DataListHeader extends _component.default {
    constructor() {
      super(...arguments);
      //region Arguments
      _defineProperty(this, "text", '');
      _defineProperty(this, "query", '');
      _defineProperty(this, "search", null);
    }
    //endregion

    _search(event) {
      this.search(event.target.value);
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "_search", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_search"), _class2.prototype)), _class2)) || _class);
  _exports.default = DataListHeader;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DataListHeader);
});