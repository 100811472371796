define("ember-svg-jar/inlined/undo-how-to-vote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M0 0h24v24H0z\"/><path d=\"M6.83 13.001l2 2H6.78l-1.78 2h14l-1.77-2h-1.91l1.253-1.252L21 18.176v1.825a2 2 0 01-1.851 1.995L19 22H4.99C3.89 22 3 21.1 3 20v-4l3-3h.83zm2.107-6.888l1.411 1.411L8.51 9.361l3.54 3.54 1.838-1.837 1.41 1.41-2.548 2.547a.996.996 0 01-1.316.083l-.094-.083-4.95-4.95a.996.996 0 010-1.41l2.547-2.548zm3.823-3.822a.975.975 0 011.4.01l4.95 4.95c.39.39.39 1.02 0 1.41l-2.398 2.398-1.41-1.41L17 7.951l-3.54-3.54-1.698 1.698-1.41-1.41z\" fill=\"#5E6978\" fill-rule=\"nonzero\"/><path fill=\"#6B7786\" fill-rule=\"nonzero\" d=\"M2.539 2.514L3.953 1.1l19.092 19.092-1.414 1.414z\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});