define("ember-svg-jar/inlined/main-app-bar-swoop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1955.5 0H0v166.6h1955.5v-.2c92.6.7 182.1-.2 263.5-3.5 175.7-7.1 345.6-24 521.7-30.3 107-3.8 218.3 1.1 324.9 13.2V0H1955.5z\" fill=\"#1A9CD0\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 3065.7 178.4"
    }
  };
  _exports.default = _default;
});