define("ember-svg-jar/inlined/promotion-photo-gallery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M898.043 359.13c0-40.383-27.884-68.267-68.267-68.267H649.975c-5.769 0-9.615-.962-11.538-7.692-7.692-29.807-30.768-49.037-61.536-49.037a2876.314 2876.314 0 00-128.841 0c-30.768.962-53.844 20.192-61.536 49.037-1.923 6.731-3.846 8.654-10.577 8.654h-62.498c-6.731 0-9.615-.962-10.577-8.654-.962-13.461-9.615-22.115-23.076-23.076-17.307-.962-33.653 0-50.96 0-12.5 0-23.076 9.615-23.076 22.115 0 8.654-3.846 9.615-10.577 9.615-41.345 0-68.267 26.922-68.267 68.267V691.81c0 7.692.962 16.346 3.846 24.038 9.615 28.845 33.653 45.191 67.305 45.191h630.746c43.268 0 71.151-26.922 71.151-68.267-1.923-111.534-1.923-223.069-1.923-333.641zM511.519 698.54c-94.227 0-170.186-75.959-170.186-170.186s75.959-170.186 170.186-170.186 170.186 75.959 170.186 170.186-76.92 170.186-170.186 170.186zm112.496-170.186c0 62.13-50.366 112.496-112.496 112.496s-112.496-50.366-112.496-112.496c0-62.13 50.366-112.496 112.496-112.496s112.496 50.366 112.496 112.496z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 1024 1024"
    }
  };
  _exports.default = _default;
});