define("boulevard/components/boulevard/list-material", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "@ember/object/computed"], function (_exports, _component, _templateFactory, _component2, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="list-material {{if this.isMedium "list-material--medium"}}" ...attributes>
    <MdcElevation class="list-material__elevation" @z={{7}}>
      <MdcLinearProgress class="list-material__progress" @indeterminate={{this.indeterminateLoading}} @progress={{this.progress}} @secondary={{this.useAccentColor}} />
      <div class="list-material__content">
        {{yield (hash
          filter-container=(component "boulevard/list-material/filter-container")
          FilterContainer=(component "boulevard/list-material/filter-container")
        )}}
      </div>
    </MdcElevation>
  </div>
  
  */
  {
    "id": "8PJPEUjK",
    "block": "[[[11,0],[16,0,[29,[\"list-material \",[52,[30,0,[\"isMedium\"]],\"list-material--medium\"]]]],[17,1],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"list-material__elevation\"]],[[\"@z\"],[7]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],[[24,0,\"list-material__progress\"]],[[\"@indeterminate\",\"@progress\",\"@secondary\"],[[30,0,[\"indeterminateLoading\"]],[30,0,[\"progress\"]],[30,0,[\"useAccentColor\"]]]],null],[1,\"\\n    \"],[10,0],[14,0,\"list-material__content\"],[12],[1,\"\\n      \"],[18,2,[[28,[37,4],null,[[\"filter-container\",\"FilterContainer\"],[[50,\"boulevard/list-material/filter-container\",0,null,null],[50,\"boulevard/list-material/filter-container\",0,null,null]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"if\",\"mdc-elevation\",\"mdc-linear-progress\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "boulevard/components/boulevard/list-material.hbs",
    "isStrictMode": false
  });
  /* eslint-disable ember/no-classic-components, ember/no-computed-properties-in-native-classes */
  /**
   * @typedef Option
   * @property {String} name
   * @property {Function} action
   * @property {String} [label]
   */
  let ListMaterial = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _computed.equal)('size', 'medium'), _dec(_class = (_class2 = class ListMaterial extends _component.default {
    constructor() {
      super(...arguments);
      //region Attributes
      /**
       * Should be passed as a decimal value between 0 and 1.
       * @type {Number}
       */
      _defineProperty(this, "progress", 1);
      /**
       * Must be either unspecified or `large`.
       * @type {?String}
       */
      _defineProperty(this, "size", null);
      /**
       * If set `true`, puts the linear progress indicator in an indeterminate state.
       * @type {Boolean}
       */
      _defineProperty(this, "indeterminateLoading", false);
      /**
       * @type {Boolean}
       */
      _defineProperty(this, "useAccentColor", false);
      //endregion
      //region Computed Properties
      /**
       * @returns {Boolean}
       */
      _initializerDefineProperty(this, "isMedium", _descriptor, this);
    } //endregion
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "isMedium", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = ListMaterial;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ListMaterial);
});