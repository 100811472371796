define("ember-svg-jar/inlined/create-interactive-content-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M351.8 134.4H175.9c-18.8 0-34.1 15.2-34.1 34.1v95.9h7.9c10 0 18.1 8.1 18.1 18.1v18.1c0 10-8.1 18.1-18.1 18.1h-.1c6.2 7.5 15.7 12.3 26.2 12.3h125l42.4 42.7c6.7 6.8 18.3 2 18.3-7.6v-36.5c14-4.2 24.3-17.2 24.3-32.6V168.4c.1-18.8-15.1-34-34-34zm-183 98.7l13-11.8 36.7 40.7 85.3-83.6 12.3 12.5-98.4 96.4-48.9-54.2zM357 293.4h-92.7c-4.2 0-7.6-3.4-7.6-7.6s3.4-7.6 7.6-7.6H357c4.2 0 7.6 3.4 7.6 7.6s-3.4 7.6-7.6 7.6zm0-33.4h-60.1c-4.2 0-7.6-3.4-7.6-7.6 0-4.2 3.4-7.6 7.6-7.6H357c4.2 0 7.6 3.4 7.6 7.6 0 4.2-3.4 7.6-7.6 7.6z\"/><path d=\"M149.8 282.4h-26.1v-27.3h-18.8v27.3H78.8v18.1h26.1V330h18.8v-29.5h26.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 500 500"
    }
  };
  _exports.default = _default;
});