define("ember-svg-jar/inlined/Articles_One_Column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style/></defs><title>Single Column</title><rect x=\".07\" y=\"62.5\" width=\"115.67\" height=\"8.39\" rx=\"4.2\" ry=\"4.2\" stroke-width=\".14\" opacity=\".7\" stroke=\"#fff\" stroke-miterlimit=\"10\"/><rect class=\"21a1f8b9-2dec-449a-978e-1677fa00f048\" x=\".07\" y=\"84.34\" width=\"115.67\" height=\"4.75\" rx=\"2.38\" ry=\"2.38\"/><rect class=\"21a1f8b9-2dec-449a-978e-1677fa00f048\" x=\".07\" y=\"76.74\" width=\"115.67\" height=\"4.75\" rx=\"2.38\" ry=\"2.38\"/><rect class=\"21a1f8b9-2dec-449a-978e-1677fa00f048\" x=\".07\" y=\"92.15\" width=\"115.67\" height=\"4.75\" rx=\"2.38\" ry=\"2.38\"/><rect x=\".07\" y=\"99.96\" width=\"84.14\" height=\"4.75\" rx=\"2.38\" ry=\"2.38\" stroke-width=\".09\" opacity=\".4\" stroke=\"#fff\" stroke-miterlimit=\"10\"/><rect class=\"0deff54e-f828-4961-9c00-9c36925f4d4a\" x=\".07\" width=\"115.67\" height=\"56.08\" rx=\"1.74\" ry=\"1.74\" opacity=\".8\"/>",
    "attrs": {
      "id": "1c6224a1-ea1c-47ad-af63-f18c47942769",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 115.81 104.75"
    }
  };
  _exports.default = _default;
});