define("@ss/ui/components/ui/data-table/cell", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="ui-data-table__cell" ...attributes>
    {{#if (not-eq @label false)}}
      <div class="text-xs leading-5 font-semibold text-gray-800 flex-shrink-0 w-1/3 md:hidden truncate">
        {{@label}}
      </div>
    {{/if}}
  
    <div class="flex-auto" test-value>
      {{#if (has-block)}}
        {{yield}}
      {{else}}
        {{@value}}
      {{/if}}
    </div>
  </div>
  
  */
  {
    "id": "QhfMAupp",
    "block": "[[[11,0],[24,0,\"ui-data-table__cell\"],[17,1],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,2],false],null],[[[1,\"    \"],[10,0],[14,0,\"text-xs leading-5 font-semibold text-gray-800 flex-shrink-0 w-1/3 md:hidden truncate\"],[12],[1,\"\\n      \"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[14,0,\"flex-auto\"],[14,\"test-value\",\"\"],[12],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"      \"],[18,4,null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,3]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@label\",\"@value\",\"&default\"],false,[\"if\",\"not-eq\",\"has-block\",\"yield\"]]",
    "moduleName": "@ss/ui/components/ui/data-table/cell.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});