define("ember-svg-jar/inlined/promotion-offers-ecommerce", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M365.714 886.857q0 30.286-21.429 51.714T292.571 960t-51.714-21.429-21.429-51.714 21.429-51.714 51.714-21.429 51.714 21.429 21.429 51.714zm512 0q0 30.286-21.429 51.714T804.571 960t-51.714-21.429-21.429-51.714 21.429-51.714 51.714-21.429 51.714 21.429 21.429 51.714zm73.143-621.714v292.571q0 13.714-9.143 24.286t-23.429 12.286L321.714 664q.571 4 2.571 12.286t3.429 15.143T329.143 704q0 9.143-13.714 36.571h525.714q14.857 0 25.714 10.857t10.857 25.714-10.857 25.714-25.714 10.857H256q-14.857 0-25.714-10.857t-10.857-25.714q0-8 6.286-22.571t16.857-34 11.714-21.714L153.143 228.571H36.572q-14.857 0-25.714-10.857T.001 192t10.857-25.714 25.714-10.857h146.286q9.143 0 16.286 3.714T210.573 168t7.429 14 4.286 15.143T225.431 214t2.571 14.571h686.286q14.857 0 25.714 10.857t10.857 25.714z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 951 1024"
    }
  };
  _exports.default = _default;
});