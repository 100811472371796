define("ember-svg-jar/inlined/ss-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<ellipse fill=\"#FFF\" cx=\"67\" cy=\"69.2\" rx=\"51.7\" ry=\"50.3\"/><path fill=\"#90C95F\" d=\"M112.3 70.4c0-3.4-.3-11.5-1-20.4 2.7 5.9 4.2 12.4 4.2 19.3 0 26.1-21.7 47.2-48.5 47.2-8.1 0-15.7-1.9-22.4-5.3-1.8-13.7-2.8-29.2-2.8-31.4 0-2 .7-3.7 2-5.1 1.3-1.4 3-2.1 5-2.1 1.9 0 3.8.8 5.9 2.6 1.3 1.2 4.3 4.4 8.8 9.6 3.8 4.4 7.1 7.4 9.9 9.2 4.1 2.7 8.8 3.9 14 3.9 7.6 0 13.6-2.5 18.1-7.6 4.5-5.1 6.8-11.7 6.8-19.9z\"/><path fill=\"#199DD0\" d=\"M18.4 69.2C18.4 43.2 40.2 22 67 22c9.8 0 18.9 2.8 26.5 7.7.8 14.4 1.2 32 1.2 34 0 1.8-.6 3.3-1.7 4.5-1.1 1.2-2.5 1.8-4.3 1.8-2 0-4-.9-6.2-2.7-1.6-1.3-4.5-4.5-8.9-9.4-4.3-5-8.1-8.4-11.3-10.3-3.2-1.9-7.1-2.8-11.5-2.8-7.8 0-14.1 2.9-18.9 8.6-4.9 5.8-7.3 13.1-7.3 21.9 0 3.1.3 10 .9 18.2-4.5-7-7.1-15.3-7.1-24.3z\"/>",
    "attrs": {
      "aria-label": "Second Street Logo",
      "class": "ss-svg-logo",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 133.3 134.6"
    }
  };
  _exports.default = _default;
});