define("@ss/ui/components/ui/dropdown/link", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo
    @route={{@route}}
    @models={{if @model (array @model) (or @models (array))}}
    @query={{or @query (hash)}}
    class="group/item w-full h-auto flex items-center gap-3 px-4 py-3 text-sm text-gray-600 bg-transparent transition-colors hover:bg-gray-50"
    test-dropdown-link
    ...attributes
  >
    {{#if @isLoading}}
      <Ui::Spinner @size={{18}} test-spinner />
    {{else if @icon}}
      <Ui::Icon
        @name={{@icon}}
        @style={{@iconStyle}}
        class="text-lg transition-colors text-gray-400
          {{if (starts-with @modifier 'error') 'group-hover/item:text-red-500' 'group-hover/item:text-primary'}}
          "
      />
    {{else if @svg}}
      {{svg-jar @svg class="w-4.5 h-4.5"}}
    {{/if}}
  
    {{@label}}
  </LinkTo>
  */
  {
    "id": "a3DcEu3P",
    "block": "[[[8,[39,0],[[24,0,\"group/item w-full h-auto flex items-center gap-3 px-4 py-3 text-sm text-gray-600 bg-transparent transition-colors hover:bg-gray-50\"],[24,\"test-dropdown-link\",\"\"],[17,1]],[[\"@route\",\"@models\",\"@query\"],[[30,2],[52,[30,3],[28,[37,2],[[30,3]],null],[28,[37,3],[[30,4],[28,[37,2],null,null]],null]],[28,[37,3],[[30,5],[28,[37,4],null,null]],null]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,6],[[[1,\"    \"],[8,[39,5],[[24,\"test-spinner\",\"\"]],[[\"@size\"],[18]],null],[1,\"\\n\"]],[]],[[[41,[30,7],[[[1,\"    \"],[8,[39,6],[[16,0,[29,[\"text-lg transition-colors text-gray-400\\n        \",[52,[28,[37,7],[[30,8],\"error\"],null],\"group-hover/item:text-red-500\",\"group-hover/item:text-primary\"],\"\\n        \"]]]],[[\"@name\",\"@style\"],[[30,7],[30,9]]],null],[1,\"\\n\"]],[]],[[[41,[30,10],[[[1,\"    \"],[1,[28,[35,8],[[30,10]],[[\"class\"],[\"w-4.5 h-4.5\"]]]],[1,\"\\n  \"]],[]],null]],[]]]],[]]],[1,\"\\n  \"],[1,[30,11]],[1,\"\\n\"]],[]]]]]],[\"&attrs\",\"@route\",\"@model\",\"@models\",\"@query\",\"@isLoading\",\"@icon\",\"@modifier\",\"@iconStyle\",\"@svg\",\"@label\"],false,[\"link-to\",\"if\",\"array\",\"or\",\"hash\",\"ui/spinner\",\"ui/icon\",\"starts-with\",\"svg-jar\"]]",
    "moduleName": "@ss/ui/components/ui/dropdown/link.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});