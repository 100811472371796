define("@ss/ui/components/ui/tooltip", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/application"], function (_exports, _component, _templateFactory, _component2, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <EmberTooltip
    @animationDuration={{0}}
    @effect="none"
    @popperContainer={{this.popoverContainer}}
    @side={{or @side "bottom"}}
    @spacing={{4}}
    @text={{@text}}
    @tooltipClass="ui-tooltip {{if @dynamic 'ui-tooltip--dynamic'}}"
    ...attributes
  />
  
  */
  {
    "id": "qRu6fXtu",
    "block": "[[[8,[39,0],[[17,1]],[[\"@animationDuration\",\"@effect\",\"@popperContainer\",\"@side\",\"@spacing\",\"@text\",\"@tooltipClass\"],[0,\"none\",[30,0,[\"popoverContainer\"]],[28,[37,1],[[30,2],\"bottom\"],null],4,[30,3],[29,[\"ui-tooltip \",[52,[30,4],\"ui-tooltip--dynamic\"]]]]],null],[1,\"\\n\"]],[\"&attrs\",\"@side\",\"@text\",\"@dynamic\"],false,[\"ember-tooltip\",\"or\",\"if\"]]",
    "moduleName": "@ss/ui/components/ui/tooltip.hbs",
    "isStrictMode": false
  });
  class UiTooltipComponent extends _component2.default {
    get popoverContainer() {
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return config.environment === 'test' ? '#ember-testing' : 'body';
    }
  }
  _exports.default = UiTooltipComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiTooltipComponent);
});