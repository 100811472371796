define("ember-svg-jar/inlined/promotion-ballot-nomination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.2 38.5c1.6.4 3.2.9 4.8 1.3.2.1.4-.1.3-.3-.4-1.6-.9-3.2-1.3-4.8-.1-.2-.3-.3-.5-.1-1.2 1.2-2.4 2.4-3.5 3.6-.1 0 0 .2.2.3zM13 37l3.6-3.6c.2-.2.2-.6 0-.8-3.6-3.5-7.1-7-10.6-10.5l-.6-.6c-.7-.7-1.6-.6-2.2 0-.7.7-1.4 1.4-2.1 2.2-.6.7-.6 1.5 0 2.2l1.4 1.4c3.3 3.3 6.6 6.5 9.8 9.8.1.1.5.1.7-.1z\"/><path d=\"M26.3 5.3H7.5c-.8 0-1.4.6-1.4 1.4v11.9c3.5 3.5 7 6.9 10.5 10.4l2.6 3.4c.1.1.4.7.4.8.6 2.4 1.1 4.4 1.7 6.6.3 1.2-.8 2.4-2 2.1-2.2-.5-4.1-.9-6.4-1.5-.1 0-.9-.5-1-.6l-3.5-2.6L6.2 35v7.5c0 .8.6 1.4 1.4 1.4h18.9c.8 0 1.4-.6 1.4-1.4v-1.9H27c-.8 0-1.4-.6-1.4-1.4V10.5c0-.8.6-1.4 1.4-1.4h.9V6.7c-.2-.8-.8-1.4-1.6-1.4zm-4.7 14.1H10.7c-.9 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.6h10.9c.9 0 1.6.7 1.6 1.6 0 .8-.7 1.6-1.6 1.6zm0-7.4H10.7c-.9 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.6h10.9c.9 0 1.6.7 1.6 1.6 0 .9-.7 1.6-1.6 1.6zM48.4 12H29c-.6 0-1.1.5-1.1 1.1v23.5c0 .6.5 1.1 1.1 1.1h19.4c.6 0 1.1-.5 1.1-1.1V13.1c0-.6-.5-1.1-1.1-1.1zm-15.7 6.9l3.6 4 8.4-8.2 1.5 1.5-10 9.8-5.1-5.6 1.6-1.5zM44.9 34H32.5c-.9 0-1.6-.7-1.6-1.6 0-.9.7-1.6 1.6-1.6h12.3c.9 0 1.6.7 1.6 1.6.1.9-.7 1.6-1.5 1.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});