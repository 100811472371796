define("@ss/ui/components/ui/empty-message", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flex items-center justify-center py-8 text-sm text-gray-500" ...attributes>
    {{@message}}
  </div>
  
  */
  {
    "id": "KcyqoCPr",
    "block": "[[[11,0],[24,0,\"flex items-center justify-center py-8 text-sm text-gray-500\"],[17,1],[12],[1,\"\\n  \"],[1,[30,2]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@message\"],false,[]]",
    "moduleName": "@ss/ui/components/ui/empty-message.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});