define("ember-svg-jar/inlined/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#fff}</style><g id=\"Page-1\"><g id=\"Events-Teaser-Tab\" transform=\"translate(-491 -598)\"><g id=\"Group-6\" transform=\"translate(491 598)\"><g id=\"Group-3\"><defs><filter id=\"Adobe_OpacityMaskFilter\" filterUnits=\"userSpaceOnUse\" x=\"0\" y=\".2\" width=\"50.8\" height=\"50.8\"><feColorMatrix values=\"1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0\"/></filter></defs><mask maskUnits=\"userSpaceOnUse\" x=\"0\" y=\".2\" width=\"50.8\" height=\"50.8\" id=\"mask-2_1_\"><g filter=\"url(#Adobe_OpacityMaskFilter)\"><path id=\"path-1_1_\" class=\"st1\" d=\"M0 .2h50.8V51H0z\"/></g></mask><path id=\"Fill-1\" d=\"M25.4.2c14 0 25.4 11.4 25.4 25.4 0 14-11.4 25.4-25.4 25.4S0 39.6 0 25.6C0 11.6 11.4.2 25.4.2\" mask=\"url(#mask-2_1_)\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#0557c1\"/></g><defs><filter id=\"Adobe_OpacityMaskFilter_1_\" filterUnits=\"userSpaceOnUse\" x=\"11\" y=\"9\" width=\"29\" height=\"32\"><feColorMatrix values=\"1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0\"/></filter></defs><mask maskUnits=\"userSpaceOnUse\" x=\"11\" y=\"9\" width=\"29\" height=\"32\" id=\"mask-4_1_\"><g filter=\"url(#Adobe_OpacityMaskFilter_1_)\"><path id=\"path-3_1_\" class=\"st1\" d=\"M0 51h51V0H0z\"/></g></mask><path id=\"Fill-4\" d=\"M14.2 37.8h22.6V20.2H14.2v17.6zM31.9 9v3.2H19V9h-3.2v3.2h-1.6c-1.8 0-3.2 1.4-3.2 3.2v22.4c0 1.8 1.4 3.2 3.2 3.2h22.6c1.8 0 3.2-1.4 3.2-3.2V15.4c0-1.8-1.5-3.2-3.2-3.2h-1.6V9h-3.3zm-6.4 25.6h8v-8h-8v8z\" mask=\"url(#mask-4_1_)\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#fff\"/></g></g></g>",
    "attrs": {
      "aria-hidden": "true",
      "width": "40",
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 51 51",
      "xml:space": "preserve",
      "enable-background": "new 0 0 51 51"
    }
  };
  _exports.default = _default;
});