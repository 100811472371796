define("@ss/ui/components/ui/modal/actions", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flex flex-row-reverse gap-2 px-6 py-4 w-full bg-white" ...attributes>
    {{#let (or @isDisabled @isSaving (not @isDirty)) as |isDisabled|}}
      <button
        type={{if @onSave "button" "submit"}}
        class="ssButton ssPullRight {{if isDisabled 'ssButtonDisabled' (unless @avoidPrimaryColor 'ssButtonPrimary')}}"
        {{on "click" (optional @onSave)}}
        disabled={{isDisabled}}
        test-save
      >
        {{#if @isSaving}}
          {{or @labels.saving "Saving..."}}
        {{else if @isDirty}}
          {{or @labels.save "Save"}}
        {{else}}
          {{or @labels.saved "Saved"}}
        {{/if}}
      </button>
    {{/let}}
  
    {{#if @onCancel}}
      <button type="button" class="ssButton ssButtonFlat ssPullRight" {{on "click" @onCancel}} test-cancel>
        {{if @isDirty "Cancel" "Close"}}
      </button>
    {{/if}}
  
    {{yield}}
  </div>
  */
  {
    "id": "oyfKf0DK",
    "block": "[[[11,0],[24,0,\"flex flex-row-reverse gap-2 px-6 py-4 w-full bg-white\"],[17,1],[12],[1,\"\\n\"],[44,[[28,[37,1],[[30,2],[30,3],[28,[37,2],[[30,4]],null]],null]],[[[1,\"    \"],[11,\"button\"],[16,0,[29,[\"ssButton ssPullRight \",[52,[30,5],\"ssButtonDisabled\",[52,[51,[30,7]],\"ssButtonPrimary\"]]]]],[16,\"disabled\",[30,5]],[24,\"test-save\",\"\"],[16,4,[52,[30,6],\"button\",\"submit\"]],[4,[38,5],[\"click\",[28,[37,6],[[30,6]],null]],null],[12],[1,\"\\n\"],[41,[30,3],[[[1,\"        \"],[1,[28,[35,1],[[30,8,[\"saving\"]],\"Saving...\"],null]],[1,\"\\n\"]],[]],[[[41,[30,4],[[[1,\"        \"],[1,[28,[35,1],[[30,8,[\"save\"]],\"Save\"],null]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,1],[[30,8,[\"saved\"]],\"Saved\"],null]],[1,\"\\n      \"]],[]]]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[5]]],[1,\"\\n\"],[41,[30,9],[[[1,\"    \"],[11,\"button\"],[24,0,\"ssButton ssButtonFlat ssPullRight\"],[24,\"test-cancel\",\"\"],[24,4,\"button\"],[4,[38,5],[\"click\",[30,9]],null],[12],[1,\"\\n      \"],[1,[52,[30,4],\"Cancel\",\"Close\"]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[18,10,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@isDisabled\",\"@isSaving\",\"@isDirty\",\"isDisabled\",\"@onSave\",\"@avoidPrimaryColor\",\"@labels\",\"@onCancel\",\"&default\"],false,[\"let\",\"or\",\"not\",\"if\",\"unless\",\"on\",\"optional\",\"yield\"]]",
    "moduleName": "@ss/ui/components/ui/modal/actions.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});