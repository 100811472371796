define("@ss/ui/components/ui/checkbox", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
    type="button"
    class="ui-checkbox ui-checkbox--{{this.checkedState}}"
    aria-selected={{eq this.checkedState "checked"}}
    ...attributes
  >
    <i class="material-icons {{unless @primary 'ui-checkbox__icon' 'ui-checkbox__icon__primary'}}">
      {{this.icon}}
    </i>
  </button>
  */
  {
    "id": "ho56Rwt5",
    "block": "[[[11,\"button\"],[24,4,\"button\"],[16,0,[29,[\"ui-checkbox ui-checkbox--\",[30,0,[\"checkedState\"]]]]],[16,\"aria-selected\",[28,[37,0],[[30,0,[\"checkedState\"]],\"checked\"],null]],[17,1],[12],[1,\"\\n  \"],[10,\"i\"],[15,0,[29,[\"material-icons \",[52,[51,[30,2]],\"ui-checkbox__icon\",\"ui-checkbox__icon__primary\"]]]],[12],[1,\"\\n    \"],[1,[30,0,[\"icon\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@primary\"],false,[\"eq\",\"unless\"]]",
    "moduleName": "@ss/ui/components/ui/checkbox.hbs",
    "isStrictMode": false
  });
  const ICONS = {
    indeterminate: 'indeterminate_check_box',
    checked: 'check_box',
    unchecked: 'check_box_outline_blank'
  };
  class UiDataTableCheckboxComponent extends _component2.default {
    get checkedState() {
      return this.args.checked === 'indeterminate' ? 'indeterminate' : this.args.checked ? 'checked' : 'unchecked';
    }
    get icon() {
      return ICONS[this.checkedState];
    }
  }
  _exports.default = UiDataTableCheckboxComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiDataTableCheckboxComponent);
});