define("ember-svg-jar/inlined/promotion-ballot-seeded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M25.2 28.2c-.4 1.4-.8 2.8-1.2 4.3 0 .2.1.3.3.3 1.4-.4 2.8-.8 4.3-1.1.2 0 .2-.3.1-.4-1-1.1-2.1-2.1-3.1-3.1-.1-.3-.4-.2-.4 0zM36.5 17.1c-.2-.2-.5-.2-.7 0-3.1 3.1-6.2 6.2-9.4 9.3-.2.2-.2.5 0 .7l3.2 3.2c.2.2.5.2.7 0 3.1-3.1 6.2-6.2 9.4-9.3.2-.2.2-.5 0-.7l-3.2-3.2zM42.2 16.6l-1.9-1.9c-.6-.6-1.4-.6-2 0-.4.4-.8.8-1.2 1.1 0 0 0 .1-.1.1 1.3 1.3 2.6 2.6 3.8 3.9l1.2-1.2c.8-.7.8-1.4.2-2z\"/><path d=\"M32.2 31.4l-2.4 1.8h-.1L23.1 35c-.2.1-.5.1-.7.1H16c-.6 0-1-.5-1-1 0-.6.5-1 1-1H22.1l1.5-5.6c.1-.2.1-.4.2-.6.1-.2.2-.4.4-.6l1.8-2.2c0-.1.1-.1.1-.2l7.6-7.9v-4.4c0-.5-.4-.9-.9-.9H13.6c-.5 0-.9.4-.9.9v25.9c0 .5.4.9.9.9h19.3c.5 0 .9-.4.9-.9V30l-1.2 1.2c-.2.1-.3.2-.4.2zm-12-.3h-4.3c-.6 0-1-.5-1-1 0-.6.5-1 1-1h4.3c.6 0 1 .5 1 1s-.5 1-1 1zm-4.1-13.4l3.8 4.2 8.9-8.7 1.5 1.5L19.8 25l-5.3-5.9 1.6-1.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});