define("boulevard/components/boulevard/modal-dialog", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "@ember/debug", "@ember/object", "@ember/object/computed"], function (_exports, _component, _templateFactory, _component2, _debug, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    <div role="dialog" class="ssModal ssModalShow {{if this.hidden "visibility-hidden"}} {{this.modalClasses}}">
      <div class="ssModalContent">
        {{yield this.changeVisibility}}
      </div>
    </div>
    <div role="button" class="ssModalOverlay {{if this.hidden "visibility-hidden"}}" {{on "click" this.close}}></div>
  </div>
  
  */
  {
    "id": "HY55HEvC",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,0],[14,\"role\",\"dialog\"],[15,0,[29,[\"ssModal ssModalShow \",[52,[30,0,[\"hidden\"]],\"visibility-hidden\"],\" \",[30,0,[\"modalClasses\"]]]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"ssModalContent\"],[12],[1,\"\\n      \"],[18,2,[[30,0,[\"changeVisibility\"]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[11,0],[24,\"role\",\"button\"],[16,0,[29,[\"ssModalOverlay \",[52,[30,0,[\"hidden\"]],\"visibility-hidden\"]]]],[4,[38,2],[\"click\",[30,0,[\"close\"]]],null],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"if\",\"yield\",\"on\"]]",
    "moduleName": "boulevard/components/boulevard/modal-dialog.hbs",
    "isStrictMode": false
  });
  /* eslint-disable ember/no-classic-components, ember/no-computed-properties-in-native-classes */
  /**
   * @property {String} name
   * @property {Function} action
   * @property {String} [label]
   */
  let ModalDialog = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _computed.not)('visibility'), _dec(_class = (_class2 = class ModalDialog extends _component.default {
    constructor() {
      super(...arguments);
      /**
       * Allows action bubbling from 'close()'
       * @type {Boolean}
       */
      _defineProperty(this, "isCloseable", true);
      /**
       * Sets the visibility class to 'visibility-hidden'
       * @type {Boolean}
       */
      _defineProperty(this, "visibility", true);
      _initializerDefineProperty(this, "hidden", _descriptor, this);
    }
    close() {
      if (this.isCloseable) {
        this.action();
      }
    }
    changeVisibility(visibility) {
      (0, _object.set)(this, 'visibility', visibility);
      (false && !(false) && (0, _debug.deprecate)('You called changeVisibility, but changeVisibility is now deprecated. Please use template logic to render the modal block instead.', false, {
        id: 'boulevard.modal-dialog.change-visibility',
        until: '4.0.0'
      }));
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "hidden", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "close"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "changeVisibility", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "changeVisibility"), _class2.prototype)), _class2)) || _class);
  _exports.default = ModalDialog;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ModalDialog);
});