define("ember-svg-jar/inlined/ballot_wide_scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Wide Scroll</title><g fill=\"none\" fill-rule=\"evenodd\"><path stroke=\"#B3B3B3\" stroke-width=\"3\" fill=\"#F2F2F2\" d=\"M1.5 1.5h152v167H1.5z\"/><path fill=\"#FFF\" d=\"M3 3h149v100H3zM3 105h149v62H3z\"/><path fill=\"#EBEBEB\" d=\"M3 3h6v164H3zM16 15h63.333v38H16zM16 119h63.333v38H16zM16 65h63.333v8H16zM16 77h63.333v8H16zM16 89h63.333v8H16zM84.667 15H148v38H84.667zM84.667 119H148v38H84.667zM84.667 65H148v8H84.667zM84.667 77H148v8H84.667zM84.667 89H148v8H84.667z\"/><path fill=\"#D8D8D8\" d=\"M16 7h34.886v4H16zM5 5h8v8H5z\"/><path stroke=\"#999\" stroke-linecap=\"round\" d=\"M8 7l2 2-2 2\"/><path fill=\"#D8D8D8\" d=\"M16 111h34.886v4H16zM16 57h34.886v4H16zM16 161h34.886v4H16z\"/></g>",
    "attrs": {
      "width": "155",
      "height": "170",
      "viewBox": "0 0 155 170",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});