define("ember-svg-jar/inlined/survey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>ss-survey</title><path d=\"M15.736 6.948H9.119c-.086 0-.172 0-.258-.006-.563-.04-.959-.35-1.08-.833-.115-.482.109-1.057.569-1.298a2.45 2.45 0 01.862-.235c.976-.109 1.964-.172 2.941-.281.609-.069.965-.517.988-1.126a5.34 5.34 0 01.121-1.011c.172-.741.62-1.131 1.373-1.166s1.505-.034 2.257 0c.804.04 1.292.551 1.407 1.413.034.258.052.523.057.787.011.569.402 1.04.971 1.103.85.092 1.7.155 2.55.235.276.023.557.04.833.092.505.103.856.402.994.902.218.793-.31 1.419-1.195 1.419-1.631.006-3.262 0-4.894 0h-1.89c.011 0 .011.006.011.006zm.074-5.037a.887.887 0 00-.902.873c0 .477.385.879.867.89a.899.899 0 00.902-.879.893.893 0 00-.867-.885z\"/><path d=\"M25.195 6.925h-.459c-.011.683-.563 1.183-1.396 1.183-1.832.006-3.659 0-5.491 0H15.73v.006H8.309c-.098 0-.19 0-.287-.006-.632-.046-1.08-.391-1.212-.936a1.714 1.714 0 01-.034-.253h-.54a.441.441 0 00-.448.431v22.647c0 .235.201.431.448.431H25.19a.441.441 0 00.448-.431V7.356a.433.433 0 00-.442-.431zM8.235 28.13c.27-1.545.534-3.067.798-4.618 1.2 1.005 2.389 1.999 3.595 3.004-1.47.54-2.918 1.074-4.394 1.614zm5.209-2.446a1710.198 1710.198 0 01-3.728-3.113l8.07-9.672c1.241 1.04 2.481 2.073 3.733 3.113-2.699 3.234-5.382 6.45-8.075 9.672zm9.793-11.74c-.327.391-.649.781-.982 1.177a2041.366 2041.366 0 01-3.722-3.107c.023-.029.034-.052.057-.075.31-.368.615-.741.925-1.109.471-.563 1.183-.615 1.746-.126.603.523 1.229 1.017 1.838 1.534.546.465.603 1.154.138 1.706z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});