define("@ss/ui/components/ui/actions-menu/icon-button", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Ui::IconButton
    @icon={{@icon}}
    @modifier={{or @modifier "primary-square"}}
    @svg={{@svg}}
    @size={{@size}}
    @tooltip={{@label}}
    @tooltipSide={{@tooltipSide}}
    @iconStyle={{@iconStyle}}
    @isLoading={{@isLoading}}
    test-actions-icon-menu-item={{@label}}
    ...attributes
  />
  */
  {
    "id": "MIXqNGj+",
    "block": "[[[8,[39,0],[[16,\"test-actions-icon-menu-item\",[30,1]],[17,2]],[[\"@icon\",\"@modifier\",\"@svg\",\"@size\",\"@tooltip\",\"@tooltipSide\",\"@iconStyle\",\"@isLoading\"],[[30,3],[28,[37,1],[[30,4],\"primary-square\"],null],[30,5],[30,6],[30,1],[30,7],[30,8],[30,9]]],null]],[\"@label\",\"&attrs\",\"@icon\",\"@modifier\",\"@svg\",\"@size\",\"@tooltipSide\",\"@iconStyle\",\"@isLoading\"],false,[\"ui/icon-button\",\"or\"]]",
    "moduleName": "@ss/ui/components/ui/actions-menu/icon-button.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});