define("boulevard/components/boulevard/form-material", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "@ember/object", "@ember/object/computed"], function (_exports, _component, _templateFactory, _component2, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <form ...attributes onsubmit={{this.submitted}}>
    <div class="form-material {{if this.isSmall "form-material--small"}} {{if this.isMedium "form-material--medium"}} {{if this.isLarge "form-material--large"}}">
      <MdcLinearProgress class="form-material__progress" @indeterminate={{this.indeterminateLoading}} @progress={{this.progress}} @secondary={{this.useAccentColor}} />
      <MdcElevation class="form-material_elevation" @z={{7}}>
        <div class="form-material__content">
          {{yield (hash
            Title=(component "boulevard/form-material/title")
            Description=(component "boulevard/form-material/description")
            Message=(component "boulevard/form-material/message")
          )}}
        </div>
        <MdcFab class="fab" @disabled={{this.disabled}} @type={{"submit"}}>
          <div class="fab-inner-circle"></div>
          <i class="material-icons">{{this.fabIcon}}</i>
        </MdcFab>
      </MdcElevation>
    </div>
  </form>
  
  */
  {
    "id": "zf+SASMM",
    "block": "[[[11,\"form\"],[17,1],[16,\"onsubmit\",[30,0,[\"submitted\"]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"form-material \",[52,[30,0,[\"isSmall\"]],\"form-material--small\"],\" \",[52,[30,0,[\"isMedium\"]],\"form-material--medium\"],\" \",[52,[30,0,[\"isLarge\"]],\"form-material--large\"]]]],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"form-material__progress\"]],[[\"@indeterminate\",\"@progress\",\"@secondary\"],[[30,0,[\"indeterminateLoading\"]],[30,0,[\"progress\"]],[30,0,[\"useAccentColor\"]]]],null],[1,\"\\n    \"],[8,[39,2],[[24,0,\"form-material_elevation\"]],[[\"@z\"],[7]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"form-material__content\"],[12],[1,\"\\n        \"],[18,2,[[28,[37,4],null,[[\"Title\",\"Description\",\"Message\"],[[50,\"boulevard/form-material/title\",0,null,null],[50,\"boulevard/form-material/description\",0,null,null],[50,\"boulevard/form-material/message\",0,null,null]]]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,6],[[24,0,\"fab\"]],[[\"@disabled\",\"@type\"],[[30,0,[\"disabled\"]],\"submit\"]],[[\"default\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"fab-inner-circle\"],[12],[13],[1,\"\\n        \"],[10,\"i\"],[14,0,\"material-icons\"],[12],[1,[30,0,[\"fabIcon\"]]],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"if\",\"mdc-linear-progress\",\"mdc-elevation\",\"yield\",\"hash\",\"component\",\"mdc-fab\"]]",
    "moduleName": "boulevard/components/boulevard/form-material.hbs",
    "isStrictMode": false
  });
  /* eslint-disable ember/no-classic-components, ember/no-computed-properties-in-native-classes */
  /**
   * The piece of material within which lives a form
   * This component includes an elevated piece of material, a progress bar, and an action FAB.
   */
  let FormMaterial = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _computed.equal)('size', 'small'), _dec3 = (0, _computed.equal)('size', 'medium'), _dec4 = (0, _computed.equal)('size', 'large'), _dec(_class = (_class2 = class FormMaterial extends _component.default {
    constructor() {
      super(...arguments);
      //region Arguments
      /**
       * @type {String}
       */
      _defineProperty(this, "fabIcon", 'arrow_forward');
      /**
       * If specified, must be one of: `small`, `medium`, or `large`.
       * @type {?String}
       */
      _defineProperty(this, "size", null);
      /**
       * @type {Boolean}
       */
      _defineProperty(this, "disabled", false);
      /**
       * @type {Boolean}
       */
      _defineProperty(this, "indeterminateLoading", false);
      /**
       * @type {Boolean}
       */
      _defineProperty(this, "useAccentColor", false);
      /**
       * Should be passed as a decimal value between 0 and 1.
       * @type {Number}
       */
      _defineProperty(this, "progress", 1);
      //endregion
      //region Computed Properties
      /**
       * @returns {Boolean}
       */
      _initializerDefineProperty(this, "isSmall", _descriptor, this);
      /**
       * @returns {Boolean}
       */
      _initializerDefineProperty(this, "isMedium", _descriptor2, this);
      /**
       * @returns {Boolean}
       */
      _initializerDefineProperty(this, "isLarge", _descriptor3, this);
    }
    /**
     * When the form is submitted via the button or keybord shortcuts,
     * this action will be sent.
     * @type {Function}
     */
    onSubmit() {}
    //endregion

    //region Actions
    /**
     * Prevent Default and return false to ensure that even if the passed action does not,
     * the browser won't hard-submit the form and reload the page.
     * @returns {Boolean}
     */
    submitted(event) {
      event.preventDefault();
      for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        rest[_key - 1] = arguments[_key];
      }
      this.onSubmit(event, ...rest);
      return false;
    }
    //endregion
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "isSmall", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "isMedium", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "isLarge", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "submitted", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "submitted"), _class2.prototype)), _class2)) || _class);
  _exports.default = FormMaterial;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormMaterial);
});