define("boulevard/components/boulevard/app-bar", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-inline-styles }}
  <div ...attributes class="blvd-app-bar" style={{if @color (html-safe (concat "--color-app-bar: " @color))}}>
    {{! template-lint-enable no-inline-styles }}
    <div class="blvd-app-bar__background">
      <div class="blvd-app-bar__background-top{{if @isBig " blvd-app-bar__background-top--big"}}"></div>
      {{#if @isBig}}
        <div class="blvd-app-bar__background-middle"></div>
        <div class="blvd-app-bar__background-bottom"></div>
      {{/if}}
    </div>
    <div class="blvd-app-bar__contents">
      {{#if @back}}
        <LinkTo class="material-icons blvd-app-bar__back-link" @route={{@back}}>arrow_back</LinkTo>
      {{/if}}
      <div class="blvd-app-bar__title-container">
        <div class="blvd-app-bar__title" title={{@title}}>{{@title}}</div>
        {{#if @subTitle}}
          <div class="blvd-app-bar__sub-title" title={{@subTitle}}>{{@subTitle}}</div>
        {{/if}}
      </div>
      <div class="blvd-app-bar__actions">
        {{yield}}
      </div>
    </div>
  </div>
  
  */
  {
    "id": "jY9sA3wA",
    "block": "[[[11,0],[17,1],[24,0,\"blvd-app-bar\"],[16,5,[52,[30,2],[28,[37,1],[[28,[37,2],[\"--color-app-bar: \",[30,2]],null]],null]]],[12],[1,\"\\n\"],[1,\"  \"],[10,0],[14,0,\"blvd-app-bar__background\"],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"blvd-app-bar__background-top\",[52,[30,3],\" blvd-app-bar__background-top--big\"]]]],[12],[13],[1,\"\\n\"],[41,[30,3],[[[1,\"      \"],[10,0],[14,0,\"blvd-app-bar__background-middle\"],[12],[13],[1,\"\\n      \"],[10,0],[14,0,\"blvd-app-bar__background-bottom\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"blvd-app-bar__contents\"],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"      \"],[8,[39,3],[[24,0,\"material-icons blvd-app-bar__back-link\"]],[[\"@route\"],[[30,4]]],[[\"default\"],[[[[1,\"arrow_back\"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,0],[14,0,\"blvd-app-bar__title-container\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"blvd-app-bar__title\"],[15,\"title\",[30,5]],[12],[1,[30,5]],[13],[1,\"\\n\"],[41,[30,6],[[[1,\"        \"],[10,0],[14,0,\"blvd-app-bar__sub-title\"],[15,\"title\",[30,6]],[12],[1,[30,6]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"blvd-app-bar__actions\"],[12],[1,\"\\n      \"],[18,7,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@color\",\"@isBig\",\"@back\",\"@title\",\"@subTitle\",\"&default\"],false,[\"if\",\"html-safe\",\"concat\",\"link-to\",\"yield\"]]",
    "moduleName": "boulevard/components/boulevard/app-bar.hbs",
    "isStrictMode": false
  });
  /* eslint-disable ember/no-classic-components */
  let AppBar = (_dec = (0, _component2.tagName)(''), _dec(_class = class AppBar extends _component.default {}) || _class);
  _exports.default = AppBar;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AppBar);
});