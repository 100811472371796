define("@ss/ui/components/ui/skeleton-content", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span
    class="skeleton-content
      {{if this.isLight 'skeleton-content--light'}}
      {{if this.isMedium 'skeleton-content--medium'}}
      {{if this.isDark 'skeleton-content--dark'}}
      "
    aria-hidden="true"
    ...attributes
  >
    {{this.characterArray}}
  </span>
  */
  {
    "id": "4wodugzn",
    "block": "[[[11,1],[16,0,[29,[\"skeleton-content\\n    \",[52,[30,0,[\"isLight\"]],\"skeleton-content--light\"],\"\\n    \",[52,[30,0,[\"isMedium\"]],\"skeleton-content--medium\"],\"\\n    \",[52,[30,0,[\"isDark\"]],\"skeleton-content--dark\"],\"\\n    \"]]],[24,\"aria-hidden\",\"true\"],[17,1],[12],[1,\"\\n  \"],[1,[30,0,[\"characterArray\"]]],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"if\"]]",
    "moduleName": "@ss/ui/components/ui/skeleton-content.hbs",
    "isStrictMode": false
  });
  class UiSkeletonContentComponent extends _component2.default {
    get minimum() {
      return this.args.min || 5;
    }
    get maximum() {
      return this.args.max || 80;
    }
    get flux() {
      return this.args.lux || 'medium';
    }
    get length() {
      return Math.ceil(Math.random() * (this.maximum - this.minimum)) + this.minimum;
    }
    get characterArray() {
      return 'X'.repeat(this.length);
    }
    get isLight() {
      return this.flux.toLowerCase() === 'light';
    }
    get isMedium() {
      return this.flux.toLowerCase() === 'medium';
    }
    get isDark() {
      return this.flux.toLowerCase() === 'dark';
    }
  }
  _exports.default = UiSkeletonContentComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiSkeletonContentComponent);
});