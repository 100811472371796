define("ember-svg-jar/inlined/dashboard_winner_empty_state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#E0E0E0\" d=\"M348.8 350H3c-1.6 0-3-1.3-3-3V3c0-1.6 1.3-3 3-3h345.9v350z\"/><path fill=\"#CDCCCC\" d=\"M18.1 18h310.4v311.1H18.1z\"/><path opacity=\".07\" fill=\"#989898\" d=\"M188.5 209.1L308 328.7h-41.5l-96.4-96.4zM188.1 191.7l137.6 137.6 2.8-.2-.4-87.4-112.6-112.6-17.4 5.9-12.2 38.6z\"/><image overflow=\"visible\" width=\"574\" height=\"874\" xlink:href=\"9282A92F21C3E4EA.png\" transform=\"matrix(.24 0 0 .24 -29.264 -28.878)\"/><circle fill=\"#FFF\" cx=\"37.3\" cy=\"37.6\" r=\"21.5\"/><path fill=\"#B4B4B4\" stroke=\"#CDCCCC\" stroke-width=\".741\" stroke-miterlimit=\"10\" d=\"M70.2 37.8c0-2.6-1.6-4.8-3.8-5.8 1.1-1.1 1.8-2.7 1.8-4.4 0-3.4-2.8-6.2-6.2-6.3.3-.7.5-1.5.5-2.4 0-3.5-2.8-6.3-6.3-6.3-.8 0-1.6.2-2.3.5 0-3.4-2.8-6.2-6.3-6.2-1.7 0-3.3.7-4.4 1.8-1-2.2-3.2-3.8-5.8-3.8s-4.8 1.6-5.8 3.8c-1.1-1.1-2.7-1.8-4.4-1.8-3.4 0-6.2 2.8-6.3 6.2-.7-.3-1.5-.5-2.3-.5-3.5 0-6.3 2.8-6.3 6.3 0 .8.2 1.6.5 2.4-3.4 0-6.2 2.8-6.2 6.3 0 1.7.7 3.3 1.8 4.4-2.2 1-3.8 3.2-3.8 5.8v0s0 0 0 0 0 0 0 0v0c0 2.6 1.6 4.8 3.8 5.8-1.1 1.1-1.8 2.7-1.8 4.4 0 3.4 2.8 6.2 6.2 6.3-.3.7-.5 1.5-.5 2.4 0 3.5 2.8 6.3 6.3 6.3.8 0 1.6-.2 2.3-.5 0 2.8 1.8 5.1 4.3 5.9L9.5 138.6l10.8-7.1 6.8 11.1 10.3-45.9 10.3 45.9 6.8-11.1 10.8 7.1-15.8-70.3c2.5-.8 4.3-3.1 4.3-5.9.7.3 1.5.5 2.3.5 3.5 0 6.3-2.8 6.3-6.3 0-.8-.2-1.6-.5-2.4 3.4 0 6.2-2.8 6.2-6.3 0-1.7-.7-3.3-1.8-4.4 2.3-.9 3.9-3.1 3.9-5.7zM37.3 58.2c-11.3 0-20.4-9.1-20.4-20.4 0-11.3 9.1-20.4 20.4-20.4 11.3 0 20.4 9.1 20.4 20.4 0 11.2-9.1 20.4-20.4 20.4z\"/><path fill=\"#B4B4B4\" d=\"M38 24.6l2.9 8.8c.1.3.4.5.6.5h9.3c.7 0 .9.8.4 1.2l-7.5 5.5c-.2.2-.3.5-.2.8l2.9 8.8c.2.6-.5 1.2-1 .8l-7.5-5.5c-.2-.2-.6-.2-.8 0L29.6 51c-.5.4-1.3-.1-1-.8l2.9-8.8c.1-.3 0-.6-.2-.8l-7.5-5.5c-.5-.4-.3-1.2.4-1.2h9.3c.3 0 .6-.2.6-.5l2.9-8.8c-.1-.6.8-.6 1 0z\"/><path fill=\"none\" d=\"M18.1 101h310.4v189.6H18.1z\"/><text transform=\"translate(124.881 232.535)\" fill=\"#B4B4B4\" font-family=\"&apos;Avenir-Black&apos;\" font-size=\"174.027\">?</text>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 348.8 350"
    }
  };
  _exports.default = _default;
});