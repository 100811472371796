define("ember-svg-jar/inlined/pie-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<filter id=\"a\"><feGaussianBlur in=\"SourceGraphic\" stdDeviation=\"3\"/></filter><g class=\"chart-viewport\"><g class=\"arc\"><path class=\"slice\" filter=\"url(#a)\" d=\"M14.413 114.093a115 115 0 01-93.136-30.262L0 0z\" fill=\"#F3F3F3\" stroke=\"#b1ddef\" transform=\"translate(184.851 125)\"/></g><g class=\"arc\"><path class=\"slice\" filter=\"url(#a)\" d=\"M-78.723 83.831A115 115 0 010-115V0z\" fill=\"#F3F3F3\" stroke=\"#65bde0\" transform=\"translate(184.851 125)\"/></g><g class=\"arc\"><path class=\"slice\" filter=\"url(#a)\" d=\"M0-115a115 115 0 0114.413 229.093L0 0z\" fill=\"#F3F3F3\" stroke=\"#199cd0\" transform=\"translate(184.851 125)\"/></g></g>",
    "attrs": {
      "width": "369.703",
      "height": "250"
    }
  };
  _exports.default = _default;
});