define("boulevard/components/boulevard/skeleton-content", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "@ember/object", "@ember/object/computed", "lodash/random", "lodash/times"], function (_exports, _component, _templateFactory, _component2, _object, _computed, _random, _times) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span
    class="skeleton-content {{if this.isLight "skeleton-content--light"}} {{if this.isMedium "skeleton-content--medium"}} {{if this.isDark "skeleton-content--dark"}}"
    aria-hidden="true"
    ...attributes
  >
    {{#each this.characters as |c|}}{{c}}{{/each}}
  </span>
  
  */
  {
    "id": "RDmhSKmQ",
    "block": "[[[11,1],[16,0,[29,[\"skeleton-content \",[52,[30,0,[\"isLight\"]],\"skeleton-content--light\"],\" \",[52,[30,0,[\"isMedium\"]],\"skeleton-content--medium\"],\" \",[52,[30,0,[\"isDark\"]],\"skeleton-content--dark\"]]]],[24,\"aria-hidden\",\"true\"],[17,1],[12],[1,\"\\n  \"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"characters\"]]],null]],null],null,[[[1,[30,2]]],[2]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"c\"],false,[\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "boulevard/components/boulevard/skeleton-content.hbs",
    "isStrictMode": false
  });
  /* eslint-disable ember/no-classic-components, ember/no-computed-properties-in-native-classes */
  let SkeletonContent = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _object.computed)('min', 'max'), _dec3 = (0, _computed.equal)('lux', 'light'), _dec4 = (0, _computed.equal)('lux', 'medium'), _dec5 = (0, _computed.equal)('lux', 'dark'), _dec(_class = (_class2 = class SkeletonContent extends _component.default {
    constructor() {
      super(...arguments);
      //region Arguments
      /**
       * @type {?Integer}
       */
      _defineProperty(this, "min", 5);
      /**
       * @type {?Integer}
       */
      _defineProperty(this, "max", 80);
      /**
       * @type {?String}
       */
      _defineProperty(this, "lux", 'medium');
      _initializerDefineProperty(this, "isLight", _descriptor, this);
      _initializerDefineProperty(this, "isMedium", _descriptor2, this);
      _initializerDefineProperty(this, "isDark", _descriptor3, this);
    }
    //endregion
    //region Computed Properties
    get characters() {
      const length = (0, _random.default)(this.min, this.max);
      return (0, _times.default)(length, () => 'X');
    }

    //endregion
  }, (_applyDecoratedDescriptor(_class2.prototype, "characters", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "characters"), _class2.prototype), _descriptor = _applyDecoratedDescriptor(_class2.prototype, "isLight", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "isMedium", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "isDark", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class2)) || _class);
  _exports.default = SkeletonContent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SkeletonContent);
});