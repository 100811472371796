define("ember-svg-jar/inlined/donations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Layer 2\"><path d=\"M18.33 19a.47.47 0 000-.17.4.4 0 00-.1-.15.47.47 0 00-.64 0 .31.31 0 00-.1.15.47.47 0 000 .17.46.46 0 00.46.45.45.45 0 00.38-.45zm-1.48 0a.46.46 0 00-.13-.32H16.57a.16.16 0 00-.09 0 .46.46 0 00-.45.17.44.44 0 000 .47.46.46 0 00.41.25h.25l.07-.05a.48.48 0 00.09-.52zm-1.48 0a.33.33 0 000-.17.38.38 0 00-.09-.15.48.48 0 00-.65 0 .38.38 0 00-.09.15.33.33 0 000 .17.45.45 0 00.45.45.46.46 0 00.38-.45zM19 17.83V21H1.05v-3.17zM6 15h4.52c-.43-.25-.85-.52-1.25-.8H3.72l-2.19 2.74h17l-2.19-2.76h-1.75a14.36 14.36 0 01-1.24.78H14a.45.45 0 01.45.45.45.45 0 01-.45.45H6a.45.45 0 01-.45-.45A.45.45 0 016 15zm2-6.67c.53.27 1.14.6 1.64.91a1.17 1.17 0 01-.29 2.13 1.34 1.34 0 01-.35.05 1.19 1.19 0 01-.58-.15c-.3-.16-.59-.31-.89-.45C8 11.8 9 13.24 12 14.68c2.86-1.4 4.5-3.13 4.5-4.78a2 2 0 00-.54-1.52 2 2 0 00-1.48-.67 2.68 2.68 0 00-2.11 1 .45.45 0 01-.37.19.44.44 0 01-.37-.19 2.68 2.68 0 00-2.11-1 1.89 1.89 0 00-1.44.58zM3 7.5c.45.76.81 1.37 1.64 1.5a12.85 12.85 0 012.43.66 13.75 13.75 0 011.78.83.3.3 0 00.39-.1.29.29 0 000-.39C8.17 9.3 6.52 8.5 6.5 8.49a.44.44 0 01-.26-.37.46.46 0 01.2-.4.47.47 0 01.46 0l.35.17a2.82 2.82 0 012.23-1.08 3.65 3.65 0 011.88.52c-.31-.83-1.13-1.17-2.46-1.65l-.26-.09A37.91 37.91 0 004 4.47l-1.54 2a8.3 8.3 0 01.63.99zm.29-3.73l.38-.5L0 .46V6l1.08.7zm9.91.6a.46.46 0 00.46.45h1a.45.45 0 100-.89h-1a.45.45 0 00-.37.4zm-.44-1.5a.46.46 0 00.3-.11l.91-.82a.46.46 0 00.14-.43.45.45 0 00-.31-.34.49.49 0 00-.45.11l-.9.81a.45.45 0 00-.11.5.44.44 0 00.51.24zm-2.45-1.3V.45a.45.45 0 01.45-.45.46.46 0 01.46.45v1.12a.46.46 0 01-.46.45.45.45 0 01-.45-.45z\" fill=\"#43a047\" data-name=\"Layer 1\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 19 21"
    }
  };
  _exports.default = _default;
});