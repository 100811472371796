define("ember-svg-jar/inlined/promotion-survey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M503.545 231.482H291.813c-2.757 0-5.514 0-8.271-.184-18.012-1.287-30.693-11.212-34.553-26.651-3.676-15.439 3.492-33.819 18.196-41.538 8.271-4.227 18.196-6.617 27.569-7.535 31.245-3.492 62.858-5.514 94.103-9.006 19.483-2.206 30.878-16.542 31.613-36.024.367-10.844 1.47-21.871 3.86-32.348 5.514-23.71 19.85-36.207 43.927-37.31s48.155-1.103 72.231 0c25.731 1.287 41.354 17.644 45.029 45.214 1.103 8.271 1.654 16.726 1.838 25.18.367 18.196 12.866 33.267 31.062 35.289 27.202 2.941 54.403 4.962 81.605 7.535 8.822.736 17.828 1.287 26.651 2.941 16.174 3.308 27.385 12.866 31.796 28.856 6.984 25.364-9.925 45.398-38.23 45.398-52.197.184-104.396 0-156.593 0h-60.468c.367 0 .367.184.367.184zm2.39-161.187c-15.806-.184-28.672 12.315-28.856 27.936 0 15.255 12.315 28.121 27.753 28.488 15.255.367 28.856-12.682 28.856-28.121 0-15.255-12.682-28.121-27.753-28.305zm300.32 160.452h-14.703c-.367 21.871-18.012 37.861-44.662 37.861-58.631.184-117.078 0-175.708 0h-67.82v.184H265.899c-3.124 0-6.065 0-9.189-.184-20.217-1.47-34.553-12.498-38.781-29.959-.551-2.573-.919-5.33-1.103-8.087h-17.277c-7.904 0-14.336 6.249-14.336 13.785v724.704c0 7.535 6.432 13.785 14.336 13.785h606.523c7.904 0 14.336-6.249 14.336-13.785V244.532c.184-7.535-6.249-13.785-14.152-13.785zM263.51 909.318c8.638-49.44 17.093-98.146 25.548-147.771a63511.358 63511.358 0 00115.055 96.125c-47.052 17.277-93.368 34.37-140.603 51.646zm166.701-78.297c-39.7-33.083-79.399-66.166-119.283-99.616A2313792.707 2313792.707 0 01569.16 421.894c39.7 33.267 79.399 66.35 119.466 99.616-86.383 103.476-172.216 206.402-258.415 309.511zm313.371-375.677c-10.476 12.498-20.768 24.996-31.429 37.678-39.7-33.083-79.399-66.166-119.099-99.433.736-.919 1.103-1.654 1.838-2.39 9.925-11.763 19.666-23.71 29.591-35.473 15.072-18.012 37.861-19.666 55.874-4.044 19.298 16.726 39.332 32.532 58.814 49.073 17.46 14.887 19.298 36.943 4.411 54.587z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 1024 1024"
    }
  };
  _exports.default = _default;
});