define("boulevard/components/boulevard/paging", ["exports", "@ember/component", "@ember/template-factory", "@ember-decorators/component", "@ember/object", "@ember/object/computed", "lodash"], function (_exports, _component, _templateFactory, _component2, _object, _computed, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showPaging}}
    <div
      class="blvd-paging"
      ...attributes
    >
      {{#unless @hideLabel}}
        {{#if @isSkeleton}}
          <span class="blvd-paging__label">XX - XX of XX</span>
        {{else}}
          <span class="blvd-paging__label">{{this.startIndex}} - {{this.endIndex}} of {{@paging.totalRecords}}</span>
        {{/if}}
      {{/unless}}
      {{~#each this.links as |link|~}}
        {{~#if (or link.disabled @isSkeleton)~}}
          <span class="blvd-paging__link blvd-paging__link--disabled {{link.cssClass}}">{{link.text}}</span>
        {{~else~}}
          <span role="button" class="blvd-paging__link {{link.cssClass}}" {{on "click" (fn this.goToPage link.number)}}>{{link.text}}</span>
        {{~/if~}}
      {{~/each~}}
    </div>
  {{/if}}
  
  */
  {
    "id": "JMc5dVoz",
    "block": "[[[41,[30,0,[\"showPaging\"]],[[[1,\"  \"],[11,0],[24,0,\"blvd-paging\"],[17,1],[12],[1,\"\\n\"],[41,[51,[30,2]],[[[41,[30,3],[[[1,\"        \"],[10,1],[14,0,\"blvd-paging__label\"],[12],[1,\"XX - XX of XX\"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[14,0,\"blvd-paging__label\"],[12],[1,[30,0,[\"startIndex\"]]],[1,\" - \"],[1,[30,0,[\"endIndex\"]]],[1,\" of \"],[1,[30,4,[\"totalRecords\"]]],[13],[1,\"\\n\"]],[]]]],[]],null],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"links\"]]],null]],null],null,[[[41,[28,[37,4],[[30,5,[\"disabled\"]],[30,3]],null],[[[10,1],[15,0,[29,[\"blvd-paging__link blvd-paging__link--disabled \",[30,5,[\"cssClass\"]]]]],[12],[1,[30,5,[\"text\"]]],[13]],[]],[[[11,1],[24,\"role\",\"button\"],[16,0,[29,[\"blvd-paging__link \",[30,5,[\"cssClass\"]]]]],[4,[38,5],[\"click\",[28,[37,6],[[30,0,[\"goToPage\"]],[30,5,[\"number\"]]],null]],null],[12],[1,[30,5,[\"text\"]]],[13]],[]]]],[5]],null],[13],[1,\"\\n\"]],[]],null]],[\"&attrs\",\"@hideLabel\",\"@isSkeleton\",\"@paging\",\"link\"],false,[\"if\",\"unless\",\"each\",\"-track-array\",\"or\",\"on\",\"fn\"]]",
    "moduleName": "boulevard/components/boulevard/paging.hbs",
    "isStrictMode": false
  });
  /* eslint-disable ember/no-classic-components, ember/no-computed-properties-in-native-classes */

  const isBetweenOneAnd = x => y => y > 0 && y <= x;
  const PAGE_NUMBER_BUFFER = 3;

  /**
   * @typedef {Object} SsPagingLink
   * @property {Number} number
   * @property {String} text
   * @property {?String} cssClass
   */

  /**
   * @typedef {Object} SsPagingData
   * @property {Number} pageSize - Number of items to display per page
   * @property {Number} pageIndex - Current page, ONE-INDEXED (does not start at 0)
   * @property {Number} totalRecords - Total number of records to be paged over.
   */

  /**
   * @example first prev 1, [2], 3 next last
   * @example 1 - 10 of 42 < >
   * @type SsPagingComponent
   */
  let Paging = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _computed.alias)('paging.pageIndex'), _dec3 = (0, _object.computed)('paging.{pageSize,totalRecords}'), _dec4 = (0, _computed.gt)('pageCount', 1), _dec5 = (0, _object.computed)('pageIndex', 'paging.pageSize'), _dec6 = (0, _object.computed)('startIndex', 'paging.{pageSize,totalRecords}'), _dec7 = (0, _object.computed)('pageCount', 'pageIndex'), _dec8 = (0, _object.computed)('showNumberLinks', 'pageIndex', 'pageCount', 'linkBuffers'), _dec(_class = (_class2 = class Paging extends _component.default {
    constructor() {
      super(...arguments);
      //region Arguments
      /**
       * Most likely this paging object was passed in as a meta object in a EmberData payload.
       * @property {SsPagingData}
       */
      _defineProperty(this, "paging", null);
      /**
       * Whether to hide the current page's start/end index and total record count.
       * @property {Boolean}
       */
      _defineProperty(this, "hideLabel", false);
      /**
       * @property {Boolean}
       */
      _defineProperty(this, "showNumberLinks", false);
      /**
       * @property {Boolean}
       */
      _defineProperty(this, "isSkeleton", false);
      //endregion
      //region Computed Properties
      _initializerDefineProperty(this, "pageIndex", _descriptor, this);
      _initializerDefineProperty(this, "showPaging", _descriptor2, this);
    }
    /**
     * An action fired when a user clicks any pagination destination
     * @property {function(destination: number)}
     */
    changePage() {}
    get pageCount() {
      if (!this.paging) {
        return 0;
      }
      return Math.ceil(this.paging.totalRecords / this.paging.pageSize);
    }
    /**
     * Returns the start index of the CURRENT page
     * @returns {number}
     */
    get startIndex() {
      if (!this.paging) {
        return 0;
      }
      const {
        pageSize
      } = this.paging;
      return pageSize * (this.pageIndex - 1) + 1;
    }

    /**
     * Returns the end index of the CURRENT page
     * @returns {Number}
     */
    get endIndex() {
      if (!this.paging) {
        return 0;
      }
      const endIndex = this.startIndex + this.paging.pageSize - 1;
      const {
        totalRecords
      } = this.paging;
      return endIndex > totalRecords ? totalRecords : endIndex;
    }

    /**
     * @param {Number} pageCount
     * @param {Number} pageIndex
     * @returns {Number[]}
     * @private
     */
    get linkBuffers() {
      const {
        pageCount,
        pageIndex
      } = this;
      const buffer = PAGE_NUMBER_BUFFER;
      const bufferEnd = buffer + pageIndex > pageCount ? pageCount : buffer + pageIndex;
      const bufferStart = pageIndex - buffer > 0 ? pageIndex - buffer : 1;

      // this logic is to make sure we always show a constant number of page links.
      // handles the cases when on a page near the start or end
      const pagesToShow = buffer * 2;
      const difference = pagesToShow - (bufferEnd - bufferStart);
      if (difference) {
        if (bufferEnd < pageCount) {
          return [bufferStart, bufferEnd + difference];
        }
        return [bufferStart - difference, bufferEnd];
      }
      return [bufferStart, bufferEnd];
    }

    /**
     * Builds array of page objects that represent the
     * page links. Example: <first prev 1, 2 ,3 next last>
     *
     * @param {Number} pageIndex
     * @param {Number} pageCount
     * @param {Array<Number,Number>} <bufferStart, bufferEnd>
     * @returns {SsPagingLink[]}
     */
    get links() {
      const {
        showNumberLinks,
        pageCount,
        pageIndex
      } = this;
      const [bufferStart, bufferEnd] = this.linkBuffers;
      const links = [];
      links.push({
        number: pageIndex - 1,
        text: '‹',
        cssClass: 'blvd-paging__link--arrow',
        disabled: pageIndex === 1
      });
      if (showNumberLinks) {
        links.push(...(0, _lodash.range)(bufferStart, bufferEnd + 1).filter(isBetweenOneAnd(pageCount)).map(number => ({
          number,
          text: `${number}`,
          cssClass: `blvd-paging__link--number ${pageIndex === number ? 'blvd-paging__link--current' : ''}`
        })));
      }
      links.push({
        number: pageIndex + 1,
        text: '›',
        cssClass: 'blvd-paging__link--arrow',
        disabled: pageIndex === pageCount
      });
      return links;
    }
    //endregion

    //region Actions
    goToPage(pageNumber) {
      this.changePage(pageNumber);
    }
    //endregion
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "pageIndex", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "pageCount", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "pageCount"), _class2.prototype), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "showPaging", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "startIndex", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "startIndex"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "endIndex", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "endIndex"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "linkBuffers", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "linkBuffers"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "links", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "links"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "goToPage", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "goToPage"), _class2.prototype)), _class2)) || _class);
  _exports.default = Paging;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Paging);
});