define("ember-svg-jar/inlined/message-campaign-welcome", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M174.8 63.5V12.7c0-6.1-4.9-10.9-10.9-10.9H35.3c-6 0-10.9 4.8-10.9 10.8v50.7L2.3 82v96.4c0 10.5 8.5 19 19 19h156.4c10.5 0 19-8.5 19-19V82l-21.9-18.5zM117 76.7l6.1 18.9c1.2 3.5-2.8 6.4-5.8 4.2l-16.1-11.6c-1.3-1-3.2-1-4.5 0l-16 11.6c-3 2.2-7-.7-5.9-4.2L81 76.7c.5-1.6-.1-3.3-1.3-4.3L63.5 60.8c-2.9-2.2-1.4-6.9 2.2-6.9h19.9c1.6 0 3.1-1 3.6-2.6l6.2-18.9c1.1-3.5 6.1-3.5 7.2 0l6.2 18.9c.5 1.6 1.9 2.6 3.6 2.6h19.9c3.7 0 5.2 4.7 2.2 6.9l-16.1 11.6c-1.4 1-2 2.7-1.4 4.3zm-7.5 74.7c-5.8 4.7-14.2 4.7-20 0L12.4 88.7l12-10.1v4.9L96 142.3c2 1.7 4.8 1.6 6.8-.1l72-58.4v-5.1l11.9 10-77.2 62.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 200 200"
    }
  };
  _exports.default = _default;
});