define("ember-svg-jar/inlined/promotion-ugcsweepstakes-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M770.731 445.367V264.46c0-28.331-23.211-51.541-51.541-51.541H98.646c-28.331 0-51.541 23.211-51.541 51.541v517.12c0 28.331 23.211 51.541 51.541 51.541H719.19c28.331 0 51.541-23.211 51.541-51.541V600.673l206.848 206.848V238.518L770.731 445.366zM610.987 566.882c-3.755 29.013-19.456 43.008-50.176 46.763-3.072 15.701-2.731 32.085-9.557 44.373-6.144 10.581-19.115 19.115-31.061 23.893-26.283 10.24-59.051-3.072-72.363-33.792-11.605-26.965-16.384-57.003-23.552-85.675-1.365-5.12-1.707-10.581-3.072-21.504-15.019 81.579 25.259 183.296 79.872 206.848-5.12 9.216-10.24 18.091-15.36 27.307-26.965-7.851-41.643-26.624-52.907-48.469-19.797-38.229-24.917-79.872-26.283-122.197-.683-18.432 0-36.864-2.731-55.296-2.048 24.235-3.413 48.469-6.827 72.704-3.413 24.917-13.995 46.421-36.523 60.075-20.821 12.288-42.325 11.947-62.464-1.365-19.115-12.629-27.307-30.379-22.187-48.469-17.067-10.24-36.523-16.043-47.787-29.013-26.283-30.379-13.312-72.363 27.307-87.723 22.187-8.192 47.104-9.216 70.997-12.971 10.581-1.707 21.163-1.024 36.523-1.707-29.696-4.779-54.613-7.509-79.189-13.653-26.283-6.485-50.176-19.797-59.392-47.104-15.019-43.349 20.139-86.016 58.027-75.093 5.803-13.995 8.192-30.72 17.408-41.301 26.283-30.379 75.776-23.211 92.16 14.677 8.192 18.773 10.581 40.96 12.288 62.123 2.048 26.965 1.024 53.931 1.365 81.237 0 2.731.683 5.461 1.365 8.192h6.144c0-26.965-.341-53.931 0-80.555.341-17.067 0-34.133 2.731-50.859 5.12-34.133 30.379-57.003 59.733-56.661 31.061.341 50.517 19.797 55.979 53.931 15.36 4.437 31.061 5.12 42.667 12.971 34.475 23.211 35.157 72.021-1.707 92.16-29.013 16.043-63.829 21.845-96.256 31.403-11.947 3.413-24.917 4.096-37.547 5.803 0 1.365.341 2.731.341 4.096 25.6-2.389 51.541-7.168 77.141-5.803 22.869 1.024 46.763 5.461 67.584 14.336 21.845 9.216 30.72 31.061 27.307 56.32z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 1024 1024"
    }
  };
  _exports.default = _default;
});