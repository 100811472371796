define("ember-svg-jar/inlined/lock-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Lock-icon</title><g transform=\"translate(2 2)\" fill=\"none\" fill-rule=\"evenodd\"><circle fill=\"#C9C9C9\" cx=\"38.5\" cy=\"38.5\" r=\"38.5\"/><path d=\"M54.317 34.544H51.8a.648.648 0 01-.645-.647v-5.951c0-6.857-5.295-12.679-12.14-12.938-7.166-.258-13.107 5.499-13.107 12.679v6.274a.648.648 0 01-.645.647h-2.519c-.968 0-1.743.776-1.743 1.747v22.898c0 .97.775 1.747 1.743 1.747h31.51c.968 0 1.743-.776 1.743-1.747V36.29c.065-.97-.71-1.746-1.679-1.746zm-24.471-6.857c0-4.981 4.132-8.992 9.169-8.733 4.713.259 8.329 4.27 8.329 8.992v6.015a.648.648 0 01-.646.647H30.427a.648.648 0 01-.646-.647v-6.274h.065z\" fill=\"#FFF\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "tabindex": "-1",
      "aria-hidden": "true",
      "width": "80",
      "height": "80",
      "viewBox": "0 0 80 80",
      "xmlns": "http://www.w3.org/2000/svg",
      "data-ember-extension": "1"
    }
  };
  _exports.default = _default;
});