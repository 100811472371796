define("ember-svg-jar/inlined/gallery_card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.cls-2{fill:#ebebeb}</style></defs><path d=\"M282 8v322H8V8h274m8-8H0v338h290V0z\" fill=\"#bcbcbc\"/><rect class=\"cls-2\" x=\"165.71\" y=\"57.76\" width=\"89.39\" height=\"68.18\" rx=\"2\"/><path class=\"cls-2\" d=\"M265 162.31H155.8a3 3 0 01-3-3v-109a3 3 0 013-3H265a3 3 0 013 3v109a3 3 0 01-3 3zm-109.2-113a1 1 0 00-1 1v109a1 1 0 001 1H265a1 1 0 001-1v-109a1 1 0 00-1-1z\"/><rect class=\"cls-2\" x=\"165.71\" y=\"137.23\" width=\"28.34\" height=\"4.14\" rx=\"2.07\"/><rect class=\"cls-2\" x=\"165.71\" y=\"145.9\" width=\"82.88\" height=\"4.14\" rx=\"2.07\"/><rect class=\"cls-2\" x=\"34.9\" y=\"57.76\" width=\"89.39\" height=\"68.18\" rx=\"2\"/><path class=\"cls-2\" d=\"M134.2 162.31H25a3 3 0 01-3-3v-109a3 3 0 013-3h109.2a3 3 0 013 3v109a3 3 0 01-3 3zM25 49.34a1 1 0 00-1 1v109a1 1 0 001 1h109.2a1 1 0 001-1v-109a1 1 0 00-1-1z\"/><rect class=\"cls-2\" x=\"34.9\" y=\"137.23\" width=\"28.34\" height=\"4.14\" rx=\"2.07\"/><rect class=\"cls-2\" x=\"34.9\" y=\"145.9\" width=\"82.88\" height=\"4.14\" rx=\"2.07\"/><rect class=\"cls-2\" x=\"34.9\" y=\"186.57\" width=\"89.39\" height=\"68.18\" rx=\"2\"/><path class=\"cls-2\" d=\"M134.2 291.12H25a3 3 0 01-3-3v-109a3 3 0 013-3h109.2a3 3 0 013 3v109a3 3 0 01-3 3zM25 178.15a1 1 0 00-1 1v109a1 1 0 001 1h109.2a1 1 0 001-1v-109a1 1 0 00-1-1z\"/><rect class=\"cls-2\" x=\"34.9\" y=\"266.04\" width=\"28.34\" height=\"4.14\" rx=\"2.07\"/><rect class=\"cls-2\" x=\"34.9\" y=\"274.7\" width=\"82.88\" height=\"4.14\" rx=\"2.07\"/><rect class=\"cls-2\" x=\"165.71\" y=\"186.57\" width=\"89.39\" height=\"68.18\" rx=\"2\"/><path class=\"cls-2\" d=\"M265 291.12H155.8a3 3 0 01-3-3v-109a3 3 0 013-3H265a3 3 0 013 3v109a3 3 0 01-3 3zm-109.2-113a1 1 0 00-1 1v109a1 1 0 001 1H265a1 1 0 001-1v-109a1 1 0 00-1-1z\"/><rect class=\"cls-2\" x=\"165.71\" y=\"266.04\" width=\"28.34\" height=\"4.14\" rx=\"2.07\"/><rect class=\"cls-2\" x=\"165.71\" y=\"274.7\" width=\"82.88\" height=\"4.14\" rx=\"2.07\"/>",
    "attrs": {
      "id": "icons",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 290 338"
    }
  };
  _exports.default = _default;
});